import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import BrushIcon from "@mui/icons-material/Brush";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ClearIcon from "@mui/icons-material/Clear";
import "./DrawingOptions.css"; // Import the CSS file

const DrawingOptions = ({
  selectedColor,
  setSelectedColor,
  penStyle,
  setPenStyle,
  strokeSize,
  setStrokeSize,
  text,
  setText,
  setIsTextMode,
  setIsEraserMode,
  clearCanvas,
}) => {
  return (
    <div className="drawing-options">
      <Tooltip title="Select Color">
        <input
          type="color"
          value={selectedColor}
          onChange={(e) => setSelectedColor(e.target.value)}
          className="color-picker"
        />
      </Tooltip>
      <Tooltip title="Pen Style">
        <select
          value={penStyle}
          onChange={(e) => setPenStyle(e.target.value)}
          className="pen-style"
        >
          <option value="solid">Solid</option>
          <option value="dashed">Dashed</option>
          <option value="dotted">Dotted</option>
        </select>
      </Tooltip>
      <Tooltip title="Stroke Size">
        <input
          type="number"
          value={strokeSize}
          onChange={(e) => setStrokeSize(e.target.value)}
          min="1"
          max="10"
          className="stroke-size"
        />
      </Tooltip>
      <Tooltip title="Enter Text">
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text"
          className="text-input"
        />
      </Tooltip>
      <Tooltip title="Add Text">
        <IconButton onClick={() => setIsTextMode(true)} className="option-button">
          <TextFieldsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Eraser">
        <IconButton onClick={() => setIsEraserMode(true)} className="option-button">
          <FormatColorFillIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Pen">
        <IconButton onClick={() => setIsEraserMode(false)} className="option-button">
          <BrushIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Clear Canvas">
        <IconButton onClick={clearCanvas} className="option-button">
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DrawingOptions;