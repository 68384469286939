import React, { useState, useRef, useEffect } from "react";
import { initializeCanvas } from "./CanvasUtils";
import { initializeWebSocket, closeWebSocket } from "./WebsocketUtils";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import DrawingOptions from "./DrawingOptions";
import ParticipantsList from "./ParticipantsList";
import Canvas from "./Canvas";
import Spinner from "../../Spinner";
import "./Drawing.css";
import { Box, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Drawing = ({ courseId, chapterNumber, isDemoCourse }) => {
  const [sessionId, setSessionId] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [penStyle, setPenStyle] = useState("solid");
  const [strokeSize, setStrokeSize] = useState(2);
  const [text, setText] = useState("");
  const [isTextMode, setIsTextMode] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const canvasRef = useRef(null);
  const socketRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth - 40);
  const websocketUrl = `wss://77g0u0607h.execute-api.us-east-2.amazonaws.com/production/?courseId=${courseId}&chapterNumber=${chapterNumber}`;
  const userId = parseInt(localStorage.getItem("user_id"), 10);
  const [isLoading, setIsLoading] = useState(false);
  const [drawingData, setDrawingData] = useState(null);
  const [isMaximized, setIsMaximized] = useState(false);

  useEffect(() => {
    const handleResize = () => setCanvasWidth(window.innerWidth - 40);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (drawingData && canvasRef.current) {
      initializeCanvas(drawingData, canvasRef);
    }
  }, [drawingData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      disconnectWebSocket();
    };

    return () => {
      handleVisibilityChange(); // Call handleVisibilityChange when the component unmounts
    };
  }, []);

  const startSession = async () => {
    try {
      if (isDemoCourse) {
        toast.info("Drawing is not available for demo courses");
        return;
      }
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        course_id: courseId,
        chapter_number: chapterNumber,
        host_id: userId,
      };

      const response = await fetch(`${baseUrl}/api/create-drawing-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      const { session_id: newSessionId, host_name } = await response.json();

      setUsers([
        { user_id: userId, name: host_name, is_drawing_enabled: true },
      ]);

      setSessionId(newSessionId);
      setIsHost(true);
      setIsDrawingEnabled(true);
      initializeWebSocket(
        newSessionId,
        websocketUrl,
        socketRef,
        userId,
        true,
        setUsers,
        canvasRef,
        setIsDrawingEnabled
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const joinSession = async (sessionIdToJoin) => {
    if (!sessionIdToJoin) return;
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        course_id: courseId,
        chapter_number: chapterNumber,
        drawing_session_id: sessionIdToJoin,
        user_id: userId,
      };

      const response = await fetch(`${baseUrl}/api/join-drawing-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.status !== 200) {
        alert("No such session exists");
        setIsLoading(false);
        return;
      }
      const currentDrawingCanvas = await response.json();

      setUsers(currentDrawingCanvas.participants);

      setSessionId(sessionIdToJoin);
      setDrawingData(currentDrawingCanvas.drawing_data);
      initializeWebSocket(
        sessionIdToJoin,
        websocketUrl,
        socketRef,
        userId,
        isHost,
        setUsers,
        canvasRef,
        setIsDrawingEnabled
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const clearMine = () => {
    clearCanvas();
    socketRef.current.send(JSON.stringify({ type: "clear", userId: userId }));
  };

  const clearAll = () => {
    if (isHost) {
      clearCanvas();
      socketRef.current.send(JSON.stringify({ type: "clear", userId: "all" }));
    }
  };

  const disconnectWebSocket = () => {
    ("disconnecting websocket");
    if (socketRef.current) {
      closeWebSocket(socketRef);
      socketRef.current = null;
      setIsDrawingEnabled(false);
    }
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const quizContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: isMaximized ? "fixed" : "relative",
    top: isMaximized ? 0 : "auto",
    left: isMaximized ? 0 : "auto",
    width: isMaximized ? "100vw" : "auto",
    height: isMaximized ? "100vh" : "auto",
    backgroundColor: isMaximized ? "white" : "transparent",
    zIndex: isMaximized ? 1000 : "auto",
    overflowY: isMaximized ? "auto" : "visible",
    padding: isMaximized ? "10px" : "0",
  };

  return (
    <Box sx={quizContainerStyle}>
      {
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: 1,
          }}
        >
          <Box sx={{ position: "fixed", top: 10, right: 10, zIndex: 1100 }}>
            <IconButton onClick={toggleMaximize}>
              {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
      }
      <Box
        sx={{
          width: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <>
          <div>
            {sessionId ? (
              <div>
                <h3>Session ID: {sessionId}</h3>
                <ParticipantsList
                  users={users}
                  isHost={isHost}
                  userId={userId}
                  socketRef={socketRef}
                  sessionId={sessionId}
                  courseId={courseId}
                  chapterNumber={chapterNumber}
                />
                <DrawingOptions
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  penStyle={penStyle}
                  setPenStyle={setPenStyle}
                  strokeSize={strokeSize}
                  setStrokeSize={setStrokeSize}
                  text={text}
                  setText={setText}
                  setIsTextMode={setIsTextMode}
                  setIsEraserMode={setIsEraserMode}
                  clearCanvas={clearCanvas}
                />
                <div className="canvas-container">
                  <Canvas
                    canvasRef={canvasRef}
                    canvasWidth={canvasWidth}
                    isDrawingEnabled={isDrawingEnabled}
                    selectedColor={selectedColor}
                    penStyle={penStyle}
                    strokeSize={strokeSize}
                    text={text}
                    isTextMode={isTextMode}
                    setIsTextMode={setIsTextMode}
                    isEraserMode={isEraserMode}
                    setIsEraserMode={setIsEraserMode}
                    socketRef={socketRef}
                    courseId={courseId}
                    chapterNumber={chapterNumber}
                    sessionId={sessionId}
                    userId={userId}
                    isHost={isHost}
                  />
                </div>
              </div>
            ) : (
              <div className="center-container">
                <h3>Start or Join a Drawing Session</h3>
                <div className="button-container">
                  <button className="styled-button" onClick={startSession}>
                    Start New Session
                  </button>
                  <button
                    className="styled-button"
                    onClick={() => {
                      if (isDemoCourse) {
                        toast.info("Drawing is not available for demo courses");
                        return;
                      }
                      const sessionIdToJoin = prompt("Enter Session ID:");
                      if (sessionIdToJoin) joinSession(sessionIdToJoin);
                    }}
                  >
                    Join Existing Session
                  </button>
                </div>
              </div>
            )}
            {isLoading && <Spinner />}
          </div>
        </>
      </Box>
    </Box>
  );
};

export default Drawing;
