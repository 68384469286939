import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField, IconButton, Select, MenuItem, InputLabel, FormControl, Box, CircularProgress, Pagination, Grid } from '@mui/material';
import { Search as SearchIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

import { fetchProducts } from "../../services/shelf/actions";
import { updateFilters } from "../../services/filters/actions";
import { updateFiltersCategory } from "../../services/filtersCategory/actions";
import { updateNewFilters } from "../../services/newFilters/actions";

import Spinner from "../Spinner";
import ShelfHeader from "./ShelfHeader";
import ProductList from "./ProductList";
import Filter from "./Filter";
import "./style.css";

const ShelfCollection = ({
  fetchProducts,
  products,
  total,
  filters,
  filtersCategory,
  sort,
  newFilters,
  history,
  categoryName,
  updateFilters,
  updateFiltersCategory,
  updateNewFilters,
  currency,
  currentPage,
  totalPages,
  searchText, // Add searchText prop
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchText || ''); // Initialize with searchText if provided
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    if (filters && filters.category && filters.category.length > 0) {
      handleFetchProducts(filters, filtersCategory, sort, newFilters, 1, pageSize, searchTerm);
    }
  }, [filters, filtersCategory, sort, newFilters, pageSize, searchTerm]);

  const handleFetchProducts = async (
    filtersParam = filters,
    filtersCategoryParam = filtersCategory,
    sortParam = sort,
    newFiltersParam = newFilters,
    page = currentPage,
    pageSizeParam = pageSize,
    searchParam = searchTerm
  ) => {
    setIsLoading(true);
    await fetchProducts(
      filtersParam,
      filtersCategoryParam,
      sortParam,
      newFiltersParam,
      () => {
        setIsLoading(false);
      },
      page,
      pageSizeParam,
      searchParam
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  return (
    <React.Fragment>
      {isLoading && <CircularProgress />}
      <Box display="flex" justifyContent="center" flexWrap="wrap" p={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search online classes and tutors"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Page Size</InputLabel>
              <Select value={pageSize} onChange={handlePageSizeChange} label="Page Size">
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4} container justifyContent="center">
            <IconButton
              disabled={currentPage === 1}
              onClick={() => {
                handleFetchProducts(undefined, undefined, undefined, undefined, currentPage - 1);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => {
                handleFetchProducts(undefined, undefined, undefined, undefined, value);
              }}
            />
            <IconButton
              disabled={currentPage === totalPages}
              onClick={() => {
                handleFetchProducts(undefined, undefined, undefined, undefined, currentPage + 1);
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Filter />
        <ShelfHeader productsLength={total ?? 0} />
        <ProductList products={products} history={history} currency={currency} />
      </Box>
    </React.Fragment>
  );
};

ShelfCollection.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  filters: PropTypes.array,
  filtersCategory: PropTypes.array,
  sort: PropTypes.string,
  newFilters: PropTypes.array,
  history: PropTypes.object.isRequired,
  categoryName: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateFiltersCategory: PropTypes.func.isRequired,
  updateNewFilters: PropTypes.func.isRequired,
  currency: PropTypes.object,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  searchText: PropTypes.string, // Add searchText prop type
};

const mapStateToProps = (state) => ({
  products: state.shelf.products,
  total: state.shelf.total,
  filters: state.filters.items,
  filtersCategory: state.filtersCategory.items,
  sort: state.sort.type,
  newFilters: state.newFilters.items,
  currency: state.currency.data,
  currentPage: state.shelf.currentPage,
  totalPages: state.shelf.totalPages,
});

export default connect(mapStateToProps, {
  fetchProducts,
  updateFilters,
  updateFiltersCategory,
  updateNewFilters,
})(ShelfCollection);