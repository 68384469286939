import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from '../Utils/getBaseUrl';
import { Box, Typography, CircularProgress, Container, Grid, TextField, Button, Chip } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserInfo } from '../Utils/userServices';

const UserInfo = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [editedUserInfo, setEditedUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [errors, setErrors] = useState({});
    const email = localStorage.getItem("email");

    const setUserInfoCloud = async (email) => {
        try {
            setIsLoading(true);
            const baseUrl = getBaseUrl();
            const dataCheckSubscriptionStatus = {
                email: email,
                ...editedUserInfo
            };

            const userResponse = await fetch(`${baseUrl}/api/user-info/update`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataCheckSubscriptionStatus),
            });
            if (!userResponse.ok) {
                throw new Error('Error setting user info');
            }
            const responseJson = await userResponse.json();
            if (responseJson.message === 'User info updated successfully') {
                toast.success('User info updated successfully');
            } else {
                toast.error('Error updating user info');
            }
            setIsLoading(false);
        } catch (e) {
            console.error('Error setting user info details:', e);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedUserInfo({
            ...editedUserInfo,
            [name]: value,
        });
        setIsEdited(true);

        // Clear errors for first_name and last_name when they are not empty
        if (name === 'first_name' && value.trim()) {
            setErrors((prevErrors) => {
                const { first_name, ...rest } = prevErrors;
                return rest;
            });
        }
        if (name === 'last_name' && value.trim()) {
            setErrors((prevErrors) => {
                const { last_name, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleSave = async () => {
        const newErrors = {};
        if (!editedUserInfo.first_name || !editedUserInfo.first_name.trim()) {
            newErrors.first_name = 'First name cannot be empty';
        }
        if (!editedUserInfo.last_name || !editedUserInfo.last_name.trim()) {
            newErrors.last_name = 'Last name cannot be empty';
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (Object.values(editedUserInfo).some(value => value.length > 100)) {
            alert('Please enter a shorter value');
            return;
        }
        await setUserInfoCloud(email);
        setIsEdited(false);
    };

    const handleSendVerificationEmail = async () => {
        try {
            setIsLoading(true);
            const baseUrl = getBaseUrl();
            const response = await fetch(`${baseUrl}/api/send-email-verification-link`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                toast.success('Verification email sent successfully');
            } else {
                toast.error('Error sending verification email');
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error sending verification email:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                if (email !== "") {
                    const userData = await getUserInfo(email);
                    setUserInfo(userData);
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setEditedUserInfo(userInfo);
    }, [userInfo]);

    return (
        <div>
            {isLoading && <CircularProgress />}
            <Container>
                <Grid container justifyContent="center">
                    {userInfo && email && (
                        <Box
                            sx={{
                                backgroundColor: 'background.paper',
                                p: 2,
                                borderRadius: 2,
                                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
                                border: '1px solid #ddd',
                                mt: 2,
                                width: '100%',
                                maxWidth: 500
                            }}
                        >
                            <Typography variant="h5" component="h2" gutterBottom>
                                Profile
                            </Typography>
                            <Typography color="textSecondary" gutterBottom>Email: {email}</Typography>
                            <Chip
                                label={userInfo.email_verified === 1 ? 'VERIFIED' : 'NOT VERIFIED'}
                                color={userInfo.email_verified === 1 ? 'success' : 'error'}
                                sx={{ my: 1 }}
                            />
                            {userInfo.email_verified !== 1 && (
                                <Button variant="contained" color="secondary" onClick={handleSendVerificationEmail} sx={{ my: 1 }} disabled={isLoading}>
                                    Send Verification Email Again
                                </Button>
                            )}
                            <TextField
                                label="First Name"
                                name="first_name"
                                value={editedUserInfo.first_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                                error={!!errors.first_name}
                                helperText={errors.first_name}
                            />
                            <TextField
                                label="Last Name"
                                name="last_name"
                                value={editedUserInfo.last_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                                error={!!errors.last_name}
                                helperText={errors.last_name}
                            />
                            <TextField
                                label="Phone"
                                name="phone_number"
                                value={editedUserInfo.phone_number || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                label="Address"
                                name="street_address"
                                value={editedUserInfo.street_address || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                label="City"
                                name="city"
                                value={editedUserInfo.city || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                label="State"
                                name="state"
                                value={editedUserInfo.state || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                label="Country"
                                name="country"
                                value={editedUserInfo.country || ''}
                                onChange={handleInputChange}
                                fullWidth
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 50 }}
                            />
                            <TextField
                                label="Zip"
                                name="postal_code"
                                value={editedUserInfo.postal_code || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="number"
                                sx={{ my: 1 }}
                                InputLabelProps={{ style: { fontWeight: 'bold', backgroundColor: 'white' } }}
                                inputProps={{ maxLength: 10 }}
                            />
                            <Button variant="contained" color="primary" disabled={!isEdited || isLoading} onClick={handleSave} sx={{ my: 1 }}>Save</Button>
                        </Box>
                    )}
                </Grid>
            </Container>
        </div>
    );
};

export default UserInfo;