import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = process.env.REACT_APP_NOT_SECRET_CODE
  ? loadStripe("pk_test_4hmpKDMcjCeCwm8cvzr4ZYrv")
  : loadStripe("pk_live_zed2dWneRMXvc1gfUmwFU99t");

let elementsInstance;
const PayTeacher = ({ profile, totalPrice, newBookingRequest }) => {
  if (!newBookingRequest || newBookingRequest.length === 0) {
    return null;
  }
  useEffect(() => {
    async function initStripe() {
      const stripe = await stripePromise;
      elementsInstance = stripe.elements();

      if (elementsInstance) {
        elementsInstance.on("ready", () => {});
      }
    }

    initStripe();
  }, [stripePromise]);
  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          profile={profile}
          totalPrice={totalPrice}
          newBookingRequest={newBookingRequest}
        />
      </Elements>
    </div>
  );
};

export default PayTeacher;
