import React from 'react';
import { Card, CardContent, Typography, TextField } from '@mui/material';

const StudentNotes = ({ selectedStudent, notes, addNote }) => {
  return (
    <Card style={{ backgroundColor: '#fff3e0' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ fontFamily: 'Times New Roman' }}>{selectedStudent.name}'s Notes</Typography>
        <TextField
          fullWidth
          label="Add note"
          value={notes[selectedStudent.name] || ""}
          onChange={(e) => addNote(selectedStudent, e.target.value)}
          style={{ fontFamily: 'Times New Roman' }}
          multiline
          rows={4}
        />
      </CardContent>
    </Card>
  );
};

export default StudentNotes;