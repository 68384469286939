import React, { useState, useEffect } from "react";
import McqQuestionCard from "./McqQuestionCard";
import MatchQuestionCard from "./MatchQuestionCard";
import DragDropQuestionCard from "./DragDropQuestionCard";
import QuizResult from "./QuizResult";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend, TouchTransition } from 'react-dnd-multi-backend';

const QuizBody = ({
  isSubmitted,
  question,
  quizData,
  currentQuestion,
  shuffledOptions,
  selectedOption,
  handleSpeak,
  handleOptionChange,
  isDesktopOrLarger,
  score,
  course_language,
  userAnswers,
  handleRestartQuiz,
  handleUploadScore,
  isBookmarkedTab,
  isLoading,
  quizType,
}) => {
  const [selectedQuizType, setSelectedQuizType] = useState(quizType);

  useEffect(() => {
    if (quizType === "Random") {
      const quizTypes = ["MCQs", "Match", "Drag/Drop"];
      setSelectedQuizType(quizTypes[Math.floor(Math.random() * quizTypes.length)]);
    } else {
      setSelectedQuizType(quizType);
    }
  }, [currentQuestion, quizType]);

  const renderQuestionCard = () => {
    let heading;
    let questionCard;

    switch (selectedQuizType) {
      case "Match":
        heading = 'Click on the correct option to match it with the question.';
        questionCard = (
          <MatchQuestionCard
            question={question}
            quizData={quizData}
            currentQuestion={currentQuestion}
            shuffledOptions={shuffledOptions}
            selectedOption={selectedOption}
            handleSpeak={handleSpeak}
            handleOptionChange={handleOptionChange}
            isDesktopOrLarger={isDesktopOrLarger}
            course_language={course_language}
          />
        );
        break;
      case "Drag/Drop":
        heading = 'Drag the correct option and drop it on the question.';
        questionCard = (
          <DragDropQuestionCard
            question={question}
            quizData={quizData}
            currentQuestion={currentQuestion}
            shuffledOptions={shuffledOptions}
            selectedOption={selectedOption}
            handleSpeak={handleSpeak}
            handleOptionChange={handleOptionChange}
            isDesktopOrLarger={isDesktopOrLarger}
            course_language={course_language}
          />
        );
        break;
      default:
        heading = 'Click on the correct answer.';
        questionCard = (
          <McqQuestionCard
            question={question}
            quizData={quizData}
            currentQuestion={currentQuestion}
            shuffledOptions={shuffledOptions}
            selectedOption={selectedOption}
            handleSpeak={handleSpeak}
            handleOptionChange={handleOptionChange}
            isDesktopOrLarger={isDesktopOrLarger}
            course_language={course_language}
          />
        );
        break;
    }

    return (
      <div>
        <h2 style={{ textAlign: 'center', fontFamily: 'Times New Roman', marginLeft: '10px', marginRight: '10px' }}>{heading}</h2>
        {questionCard}
      </div>
    );
  };

  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
      },
      {
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <div style={{ marginTop: "10px" }}>
        {isSubmitted ? (
          <QuizResult
            score={score}
            quizData={quizData}
            userAnswers={userAnswers}
            handleRestartQuiz={handleRestartQuiz}
            handleUploadScore={handleUploadScore}
            isBookmarkedTab={isBookmarkedTab}
            isLoading={isLoading}
          />
        ) : (
          renderQuestionCard()
        )}
      </div>
    </DndProvider>
  );
};

export default QuizBody;