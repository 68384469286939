import React from "react";
import TeacherProfileData from "./TeacherProfileData";
import TeacherCalendar from "./TeacherCalendar";

const TeacherProfileTabPanel = () => {
  return (
    <div>
      <TeacherProfileData />
      <TeacherCalendar />
    </div>
  );
};

export default TeacherProfileTabPanel;