import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useDrag, useDrop } from 'react-dnd';
import CustomDragLayer from './CustomDragLayer';

const useStyles = makeStyles({
    card: {
        margin: '20px auto',
        padding: '20px',
        width: '100%',
        maxWidth: '600px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    questionContainer: {
        border: '2px dashed #3f51b5',
        padding: '20px',
        marginBottom: '20px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: (props) => (props.isOver ? '#e3f2fd' : 'white'),
        transition: 'background-color 0.3s',
        borderRadius: '10px',
        wordWrap: 'break-word',
    },
    optionsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',
    },
    option: {
        border: '1px solid #3f51b5',
        borderRadius: '10px',
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: (props) => (props.isDragging ? '#bbdefb' : 'white'),
        opacity: (props) => (props.isDragging ? 0.5 : 1),
        transition: 'background-color 0.3s, opacity 0.3s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100px',
        maxWidth: '200px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        wordWrap: 'break-word',
    },
    selectedOption: {
        borderColor: '#3f51b5',
        backgroundColor: '#e3f2fd',
    },
    icon: {
        marginRight: '10px',
        cursor: 'pointer',
        color: '#3f51b5',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    optionText: {
        marginLeft: '10px',
        fontWeight: 'bold',
        color: '#3f51b5',
    },
    '@media (max-width: 600px)': {
        card: {
            padding: '10px',
        },
        questionContainer: {
            padding: '10px',
        },
        option: {
            padding: '5px',
            minWidth: '80px',
            maxWidth: '150px',
        },
    },
});

const DragDropQuestionCard = ({
    question,
    quizData,
    currentQuestion,
    shuffledOptions,
    selectedOption,
    handleSpeak,
    handleOptionChange,
    isDesktopOrLarger,
    course_language
}) => {
    const classes = useStyles({ isDesktopOrLarger });
    const [droppedOption, setDroppedOption] = useState(null);

    useEffect(() => {
        if (selectedOption) {
            const initialOption = shuffledOptions.find(option => option.text === selectedOption);
            setDroppedOption(initialOption);
        } else {
            setDroppedOption(null);
        }
    }, [selectedOption, shuffledOptions]);

    const [{ isOver }, drop] = useDrop({
        accept: 'option',
        drop: (item) => {
            handleOptionChange(item.option);
            setDroppedOption(item.option);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.container}>
                    <div ref={drop} className={classes.questionContainer} style={{ backgroundColor: isOver ? '#e3f2fd' : 'white' }}>
                        <FontAwesomeIcon
                            icon={faVolumeUp}
                            size="1x"
                            className={classes.icon}
                            onClick={() =>
                                handleSpeak(
                                    quizData[currentQuestion]?.question,
                                    true,
                                    quizData[currentQuestion]?.question_language,
                                    quizData,
                                    currentQuestion,
                                    course_language
                                )
                            }
                        />
                        <p
                            id="question-0"
                            dangerouslySetInnerHTML={{ __html: question }}
                        ></p>
                        {droppedOption && (
                            <div className={classes.option} style={{ backgroundColor: '#bbdefb' }}>
                                <FontAwesomeIcon
                                    icon={faVolumeUp}
                                    size="1x"
                                    className={classes.icon}
                                    onClick={() => handleSpeak(droppedOption.text, false, droppedOption.language, quizData, currentQuestion, course_language)}
                                />
                                <label className={classes.label}>
                                    <span className={classes.optionText} dangerouslySetInnerHTML={{ __html: droppedOption.text }}></span>
                                </label>
                            </div>
                        )}
                    </div>
                    <div className={classes.optionsContainer}>
                        {shuffledOptions.map((option, index) => (
                            !droppedOption || droppedOption.text !== option.text ? (
                                <DraggableOption
                                    key={index}
                                    option={option}
                                    index={index}
                                    selectedOption={selectedOption}
                                    handleSpeak={handleSpeak}
                                    quizData={quizData}
                                    currentQuestion={currentQuestion}
                                    course_language={course_language}
                                    handleOptionChange={handleOptionChange}
                                    classes={classes}
                                />
                            ) : null
                        ))}
                    </div>
                </div>
            </CardContent>
            <CustomDragLayer />
        </Card>
    );
};

const DraggableOption = ({
    option,
    index,
    selectedOption,
    handleSpeak,
    quizData,
    currentQuestion,
    course_language,
    handleOptionChange,
    classes
}) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'option',
        item: { option },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            id={`option-${index}`}
            className={`${classes.option} ${selectedOption === option.text ? classes.selectedOption : ''}`}
            style={{ opacity: isDragging ? 0.5 : 1, backgroundColor: isDragging ? '#bbdefb' : 'white' }}
        >
            <FontAwesomeIcon
                icon={faVolumeUp}
                size="1x"
                className={classes.icon}
                onClick={() => handleSpeak(option.text, false, option.language, quizData, currentQuestion, course_language)}
            />
            <label htmlFor={`option-${index}`} className={classes.label}>
                <input
                    type="radio"
                    id={`option-${index}`}
                    name="options"
                    value={option.text}
                    checked={selectedOption === option.text}
                    onChange={() => handleOptionChange(option)}
                    style={{ display: 'none' }}
                />
                <span className={classes.optionText} dangerouslySetInnerHTML={{ __html: option.text }}></span>
            </label>
        </div>
    );
};

export default DragDropQuestionCard;