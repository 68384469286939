import React, { useState, useEffect, useRef } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import ClearIcon from '@mui/icons-material/Clear';
import penCursor from './pen.png'; // Adjust the path as necessary

const QuizFooter = ({
  isSubmitted,
  score,
  quizData,
  userAnswers,
  handleRestartQuiz,
  handleUploadScore,
  isBookmarkedTab,
  isLoading,
}) => {
  const [isPenSelected, setIsPenSelected] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [lines, setLines] = useState([]);
  const svgRef = useRef(null);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!isPenSelected) return;
      setDrawing(true);
      const svg = svgRef.current;
      const rect = svg.getBoundingClientRect();
      const newLine = { points: [{ x: e.clientX - rect.left, y: e.clientY - rect.top }] };
      setLines((prevLines) => [...prevLines, newLine]);
    };

    const handleMouseMove = (e) => {
      if (!drawing) return;
      const svg = svgRef.current;
      const rect = svg.getBoundingClientRect();
      const newPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };
      setLines((prevLines) => {
        const lastLine = prevLines[prevLines.length - 1];
        lastLine.points.push(newPoint);
        return [...prevLines.slice(0, -1), lastLine];
      });
    };

    const handleMouseUp = () => {
      setDrawing(false);
    };

    const handleTouchStart = (e) => {
      if (!isPenSelected) return;
      setDrawing(true);
      const svg = svgRef.current;
      const rect = svg.getBoundingClientRect();
      const touch = e.touches[0];
      const newLine = { points: [{ x: touch.clientX - rect.left, y: touch.clientY - rect.top }] };
      setLines((prevLines) => [...prevLines, newLine]);
    };

    const handleTouchMove = (e) => {
      if (!drawing) return;
      const svg = svgRef.current;
      const rect = svg.getBoundingClientRect();
      const touch = e.touches[0];
      const newPoint = { x: touch.clientX - rect.left, y: touch.clientY - rect.top };
      setLines((prevLines) => {
        const lastLine = prevLines[prevLines.length - 1];
        lastLine.points.push(newPoint);
        return [...prevLines.slice(0, -1), lastLine];
      });
    };

    const handleTouchEnd = () => {
      setDrawing(false);
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isPenSelected, drawing]);

  const togglePen = () => {
    setIsPenSelected((prev) => {
      if (prev) {
        setLines([]);
      }
      return !prev;
    });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
      {isSubmitted && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRestartQuiz}
            sx={{ marginRight: 2 }}
          >
            Restart Quiz
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUploadScore}
            disabled={isLoading}
          >
            {isLoading ? "Uploading..." : "Upload Score"}
          </Button>
        </Box>
      )}
      <Box display="flex" alignItems="center" sx={{ marginLeft: 2, zIndex: 1001 }}>
        <IconButton onClick={togglePen} color={isPenSelected ? "success" : "default"}>
          {isPenSelected ? <ClearIcon /> : <CreateIcon />}
        </IconButton>
        <Typography variant="body1">
          {isPenSelected ? "Pen Selected" : "Select Pen"}
        </Typography>
      </Box>
      <svg
        ref={svgRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: isPenSelected ? 1000 : -1,
          pointerEvents: isPenSelected ? "auto" : "none",
          cursor: isPenSelected ? `url(${penCursor}) 0 0, auto` : 'default',
        }}
      >
        {lines.map((line, index) => (
          <polyline
            key={index}
            points={line.points.map((p) => `${p.x},${p.y}`).join(" ")}
            style={{ fill: "none", stroke: "black", strokeWidth: 5 }}
          />
        ))}
      </svg>
    </Box>
  );
};

export default QuizFooter;