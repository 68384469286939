import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  IconButton,
  Button,
  TextField,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import QuizCard from "./QuizCard/QuizCard";
import { getBaseUrl } from "../Utils/getBaseUrl";
import "./style.css";
import { toast } from 'react-toastify';
import FileUpload from "./FileUpload";
import ChapterForm from "./ChapterForm";

const AddChapter = ({ courseData }) => {
  const navigate = useNavigate();
  const [quizFileData, setQuizFileData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");
  const [chapterVideo, setChapterVideo] = useState("");
  const [chapterText, setChapterText] = useState("");
  const [uploadedPdf, setUploadedPdf] = useState(null);
  const [metadataChapterChanged, setMetadataChapterChanged] = useState(false);
  const [metadataFileChanged, setMetadataFileChanged] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [attachedFile, setAttachedFile] = useState(null);
  const [attachedFileContent, setAttachedFileContent] = useState("");
  const [creationMethod, setCreationMethod] = useState("ai");

  const handleInputChange = (index, field, value) => {
    setQuizFileData((prevQuizFileData) => {
      const updatedQuizData = [...prevQuizFileData];
      updatedQuizData[index][field] = value;
      updatedQuizData[index].updated = true;
      return updatedQuizData;
    });
    setIsUpdateDisabled(false);
  };

  const handleDeleteQuestion = (index) => {
    setQuizFileData((prevQuizFileData) => {
      const updatedQuizData = [...prevQuizFileData];
      updatedQuizData[index].deleted = true;
      updatedQuizData[index].updated = true;
      return updatedQuizData;
    });
    setIsUpdateDisabled(false);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      course_id: courseData.id,
      question: "",
      question_language: "english",
      answer: "",
      answer_language: "english",
      option1: "",
      option1_language: "english",
      option2: "",
      option2_language: "english",
      option3: "",
      option3_language: "english",
      id: null,
      slideAttachments: [],
      slideAudioAttachments: [],
      course_type: courseData?.type,
      deleted: false,
      updated: false,
    };

    setQuizFileData((prevQuizFileData) => [...prevQuizFileData, newQuestion]);
    setIsUpdateDisabled(false);
  };

  const handleAddChapter = async () => {
    try {
      setIsLoading(true);
      setIsUpdateDisabled(true);
      const baseUrl = getBaseUrl();
      const dataRequest = new FormData();
      dataRequest.append("user_email", localStorage.getItem("email"));
      dataRequest.append("course_id", courseData.id);
      dataRequest.append("course_url", courseData.app_data_url);
      dataRequest.append("quizFileData", JSON.stringify(quizFileData));
  
      if (metadataChapterChanged) {
        dataRequest.append("metadata_chapter_changed", true);
        dataRequest.append("chapter_new_name", chapterName);
        dataRequest.append("chapter_new_description", chapterDescription);
      }
  
      if (metadataFileChanged) {
        dataRequest.append("metadata_file_changed", true);
        dataRequest.append("chapter_new_video", JSON.stringify({
          [`chapterVideo`]: chapterVideo,
        }));
        if (uploadedPdf) {
          dataRequest.append("chapter_new_pdf", uploadedPdf);
        }
      }
  
      const addChapterResponse = await fetch(
        `${baseUrl}/api/add-chapter`,
        {
          method: "POST",
          body: dataRequest,
        }
      );
  
      if (addChapterResponse.ok) {
        toast.success('Chapter is added.');
        navigate(`/Courses/${courseData.id}`);
      } else {
        toast.error('Failed to add chapter.');
      }
    } catch (error) {
      console.error("Error adding the chapter:", error);
      toast.error('Error adding the chapter.');
    } finally {
      setIsLoading(false);
      setIsUpdateDisabled(false);
    }
  };
  
  const validateForm = () => {
    if (!chapterName || !chapterDescription) {
      return false;
    }
    for (const quiz of quizFileData) {
      if (!quiz.question || !quiz.answer || !quiz.option1 || !quiz.option2 || !quiz.option3) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setIsUpdateDisabled(!validateForm());
  }, [chapterName, chapterDescription, quizFileData]);

  useEffect(() => {
    setIsUpdateDisabled(!validateForm());
  }, []);

  const handleCreateChapterUsingAI = async () => {
    if (!attachedFile) {
      toast.error("Please attach a PDF or text file of size 2.5 MB or less.");
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const dataRequest = new FormData();
      dataRequest.append("file", attachedFile);
      dataRequest.append("user_email", localStorage.getItem("email"));
      dataRequest.append("course_id", courseData.id);
      dataRequest.append("course_url", courseData.app_data_url);

      const response = await fetch(`${baseUrl}/api/add-chapter-using-ai`, {
        method: "POST",
        body: dataRequest,
      });

      if (response.ok) {
        const generatedQuestions = await response.json();
        setQuizFileData(generatedQuestions);
        toast.success("Chapter created successfully.");
        navigate(`/Courses/${courseData.id}`);
      } else {
        toast.error("Failed to create Chapter.");
      }
    } catch (error) {
      console.error("Error creating Chapter:", error);
      toast.error("Error creating Chapter.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 2.5 * 1024 * 1024) {
      setUploadedPdf(file);
      setChapterText(file.name);
      setMetadataFileChanged(true);
      setIsUpdateDisabled(false);
    } else {
      toast.error("Please upload a valid PDF file not greater than 2.5 MB.");
    }
  };

  const handleCreationMethodChange = (event) => {
    setCreationMethod(event.target.value);
  };

  return (
    <div>
      {isLoading && (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      )}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }} disabled={isLoading}>
          <InputLabel id="creation-method-label">Creation Method</InputLabel>
          <Select
            labelId="creation-method-label"
            value={creationMethod}
            onChange={handleCreationMethodChange}
            label="Creation Method"
          >
            <MenuItem value="ai">Using AI</MenuItem>
            <MenuItem value="manual">Manually</MenuItem>
          </Select>
        </FormControl>
      </div>
      {creationMethod === "ai" ? (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <FileUpload
            attachedFile={attachedFile}
            setAttachedFile={setAttachedFile}
            setAttachedFileContent={setAttachedFileContent}
            handleCreateChapterUsingAI={handleCreateChapterUsingAI}
            disabled={isLoading}
          />
          <div className="update-button">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/Courses/${courseData.id}`)}
              style={{ marginLeft: "10px" }}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Divider />
          <ChapterForm
            chapterName={chapterName}
            setChapterName={setChapterName}
            chapterDescription={chapterDescription}
            setChapterDescription={setChapterDescription}
            chapterVideo={chapterVideo}
            setChapterVideo={setChapterVideo}
            chapterText={chapterText}
            setChapterText={setChapterText}
            uploadedPdf={uploadedPdf}
            setUploadedPdf={setUploadedPdf}
            metadataChapterChanged={metadataChapterChanged}
            setMetadataChapterChanged={setMetadataChapterChanged}
            metadataFileChanged={metadataFileChanged}
            setMetadataFileChanged={setMetadataFileChanged}
            isUpdateDisabled={isUpdateDisabled}
            setIsUpdateDisabled={setIsUpdateDisabled}
            handlePdfUpload={handlePdfUpload}
            disabled={isLoading}
          />
          {isLoading ? (
            <div className="loading-spinner">
              <CircularProgress />
            </div>
          ) : (
            <div className="quiz-content">
              <Grid container spacing={2}>
                {quizFileData.map((quiz, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <QuizCard
                      quiz={quiz}
                      index={index} // Pass the actual index
                      handleInputChange={handleInputChange}
                      handleDeleteQuestion={handleDeleteQuestion}
                      disabled={isLoading}
                    />
                  </Grid>
                ))}
              </Grid>
              <div className="add-question-button">
                <IconButton color="primary" onClick={handleAddQuestion} disabled={isLoading}>
                  <AddIcon />
                </IconButton>
              </div>
              <div className="update-button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddChapter}
                  disabled={isLoading || isUpdateDisabled}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(`/Courses/${courseData.id}`)}
                  style={{ marginLeft: "10px" }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddChapter;