import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FreeDemo1WithooutBackground from './FreeDemo1WithoutBackground.png';
import FreeDemo2WithooutBackground from './FreeDemo2WithoutBackground.png';
import FreeDemo3WithooutBackground from './FreeDemo3WithoutBackground.png';
import FreeDemo4WithooutBackground from './FreeDemo4WithoutBackground.png';
import FreeDemo5WithooutBackground from './FreeDemo5WithoutBackground.png';
import SchoolIcon from '@mui/icons-material/School';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ChatIcon from '@mui/icons-material/Chat';
import QuizIcon from '@mui/icons-material/Quiz';

const messages = [
  { text: "Interactive Learning Tools", color: "orange" },
  { text: "Engaging Video Tutorials", color: "green" },
  { text: "Messaging & Teamwork", color: "#0f9ed5" },
  { text: "Smart Drawing Tools", color: "purple" },
  { text: "Fun Quizzes & Activities", color: "red" }
];

const images = [
  FreeDemo1WithooutBackground,
  FreeDemo2WithooutBackground,
  FreeDemo3WithooutBackground,
  FreeDemo4WithooutBackground,
  FreeDemo5WithooutBackground
];

const FreeDemoCourseAd = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    navigate('/Courses/278');
  };

  return (
    <Link onClick={handleClick} style={{ textDecoration: 'none', cursor: 'pointer', width: '100%' }}>
      <Box display="flex" flexDirection="column" alignItems="center" p={4} style={{ width: '100%', borderRadius: '15px', margin: 'auto', overflow: 'hidden', position: 'relative', background: 'linear-gradient(to bottom, white, gray)' }}>
        {!isMobile && (
          <>
            <SchoolIcon style={{ position: 'absolute', top: 10, left: 10, fontSize: 80, color: '#ff7e5f' }} />
            <VideoLibraryIcon style={{ position: 'absolute', top: 10, right: 10, fontSize: 80, color: '#feb47b' }} />
            <ChatIcon style={{ position: 'absolute', bottom: 10, left: 10, fontSize: 80, color: '#0f9ed5' }} />
            <QuizIcon style={{ position: 'absolute', bottom: 10, right: 10, fontSize: 80, color: '#ff7e5f' }} />
          </>
        )}
        <Typography variant="h4" style={{ fontFamily: 'Times New Roman', color: '#333', textAlign: 'center', marginBottom: '20px' }}>
          Wondering What Tools We Use To Teach At Ghumman Tech?
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom style={{ textAlign: 'center', marginBottom: '30px' }}>
          <span style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'none', fontFamily: 'Times New Roman' }}>
            Try Our <span style={{ fontSize: '2.5em', color: 'red', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', textDecoration: 'underline', textDecorationColor: 'red' }}>FREE DEMO</span> Course
          </span>
        </Typography>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} width="100%" mt={4} style={{ paddingTop: '5px', position: 'relative' }}>
          <Box display="flex" alignItems="center" justifyContent="center" style={{ flexBasis: isMobile ? '100%' : '16.67%', padding: isMobile ? '10px 0' : '0 20px', zIndex: 1 }}>
            <Typography variant="h5" textAlign="center" style={{ fontSize: '1.5em', color: '#555', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '10px', borderRadius: '10px', fontFamily: 'Times New Roman' }}>
              Experience <span style={{ color: messages[currentIndex].color, fontWeight: 'bold', padding: '0 5px', borderRadius: '5px', fontSize: '2em', textDecoration: 'underline', display: 'inline-block', animation: 'flipDown 3s infinite' }}>{messages[currentIndex].text}</span> with Ghumman Tech's Free Demo Course!
            </Typography>
          </Box>
          <Box flex={1} display="flex" alignItems="center" justifyContent="center" style={{ flexBasis: isMobile ? '100%' : '83.33%' }}>
            <img src={images[currentIndex]} alt="Free Demo" style={{ width: '100%', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s', transform: 'scale(1)', ':hover': { transform: 'scale(1.05)' } }} />
          </Box>
        </Box>
        <style>
          {`
            @keyframes flipDown {
              0% {
                opacity: 0;
                transform: rotateX(-90deg);
              }
              50% {
                opacity: 1;
                transform: rotateX(0deg);
              }
              100% {
                opacity: 0;
                transform: rotateX(90deg);
              }
            }
          `}
        </style>
      </Box>
    </Link>
  );
};

export default FreeDemoCourseAd;