import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const CourseDescription = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const processDescription = (description) => {
    return description.replace(
      /\*\*(.*?)\*\*/g,
      '<b style="color: #ff0000;">$1</b>'
    );
  };

  const truncatedDescription = (description) => {
    const maxLength = 200; // Maximum number of characters to display initially
    if (description.length > maxLength) {
      return description.substring(0, maxLength);
    }
    return description;
  };

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "400",
        margin: "2em",
        lineHeight: "1.4",
        fontFamily: "auto",
        position: "relative",
      }}
    >
      <div
        style={{
          maxHeight: "100px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: processDescription(truncatedDescription(item.description)),
          }}
        />
        {item.description.length > 200 && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50px",
              background: "linear-gradient(to top, white, rgba(255, 255, 255, 0))",
            }}
          />
        )}
      </div>
      {item.description.length > 200 && (
        <div style={{ textAlign: "center", color: "gray", marginTop: "10px" }}>
          <Button variant="outlined" onClick={handleOpen}>
            SHOW ALL
          </Button>
        </div>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: "80vh", // Set max height for the modal
            overflowY: "auto", // Enable vertical scrolling
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: processDescription(item.description),
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CourseDescription;