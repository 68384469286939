import React from 'react';
import { Box, Grid, Typography, Chip, Avatar, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import capitalizeName from '../../Utils/capitalizeName';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      paper: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Times New Roman',
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '20px',
  textAlign: 'center',
  background: 'linear-gradient(135deg, #f5f5f5 30%, #e0e0e0 90%)',
  boxShadow: theme.shadows[5],
  transition: 'transform 0.3s',
  backgroundImage: 'url(/path/to/background-image.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 150,
  height: 150,
  margin: 'auto',
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary.main}`,
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'rotate(360deg)',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: '1rem',
  padding: '0.5rem 1rem',
  borderRadius: '10px',
  fontFamily: 'Times New Roman',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  color: 'white',
  transition: 'background 0.3s',
  '&:hover': {
    background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.main} 90%)`,
  },
}));

const TeacherProfile = ({ profile }) => {
  if (!profile) {
    return null;
  }

  const { picture_url, name, tagline, interests, experience, rate_per_half_hour } = profile;

  return (
    <ThemeProvider theme={theme}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <StyledPaper>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4}>
              <StyledAvatar src={picture_url} alt={name} />
              <Typography variant="h6" component="div" sx={{ mt: 2, fontFamily: 'Georgia' }}>
                {capitalizeName(name)}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontFamily: 'Arial' }}>
                {tagline}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" component="div" sx={{ mb: 2, fontFamily: 'Verdana' }}>
                <strong>Interests:</strong> {interests}
              </Typography>
              <Typography variant="body1" component="div" sx={{ mb: 2, fontFamily: 'Verdana' }}>
                <strong>Experience:</strong> {experience}
              </Typography>
              <StyledChip label={`$${rate_per_half_hour} USD`} />
              <Typography variant="body2" component="div" sx={{ mt: 1, color: 'black', fontFamily: 'Courier New' }}>
                for 30 minutes
              </Typography>
            </Grid>
          </Grid>
        </StyledPaper>
      </motion.div>
    </ThemeProvider>
  );
};

export default TeacherProfile;