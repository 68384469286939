import React, { useEffect, useState } from "react";

import { ZoomMtg } from "@zoom/meetingsdk";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Box, Typography, Link, Button } from "@mui/material";

const MeetingDetails = ({ details }) => {
  if (!details) {
    return null;
  }

  const {
    timing,
    instructor,
    zoomLink,
    enableZoomMeetingFromWebsite,
    zoomMeetingNumber,
    zoomPassWord,
    zoomHostEmail,
  } = details;
  const baseUrl = getBaseUrl();
  const authEndpoint = `${baseUrl}/api/zoom-generate-signature`;
  const sdkKey = "1jQw6_WgSyGemw_4609imA";
  const meetingNumber = zoomMeetingNumber || "72342134204";
  const passWord = zoomPassWord || "x2YNbyNXJXVLqW00vpka0n8dhMdnBm.1";
  const registrantToken = "";
  const zakToken = "";
  const leaveUrl = window.location.href;
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const email = localStorage.getItem("email");

  const hostEmail = zoomHostEmail || "ghummanlinux@gmail.com";

  const isHost = localStorage.getItem("email") === hostEmail;

  const getUserInfo = async () => {
    try {
      const baseUrl = getBaseUrl();
      const dataCheckSubscriptionStatus = {
        email: email,
      };

      const userResponse = await fetch(`${baseUrl}/api/user-info`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataCheckSubscriptionStatus),
      });
      if (!userResponse.ok) {
        throw new Error("Error getting user info");
      }
      const userData = await userResponse.json();
      setUserInfo(userData);
    } catch (e) {
      console.error("Error getting user info details:", e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (email !== "") {
          await getUserInfo();
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const getSignature = async (role, e) => {
    e.preventDefault();

    try {
      const response = await fetch(authEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });

      const data = await response.json();
      await startMeeting(data.signature);
    } catch (error) {
      console.error(error);
    }
  };

  const startMeeting = async (signature) => {
    try {
      await ZoomMtg.init({
        leaveUrl: leaveUrl,
        patchJsMedia: true,
        leaveOnPageUnload: true,
      });

      await ZoomMtg.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: meetingNumber,
        passWord: passWord,
        userName: userInfo.first_name + " " + userInfo.last_name,
        userEmail: email,
        tk: registrantToken,
        zak: zakToken,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;  
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        p: 2,
        borderRadius: 2,
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        border: "1px solid #ddd",
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Meeting Details
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        <strong>Timing:</strong> {timing}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        <strong>Instructor:</strong> {instructor}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
    <strong>Class Link:</strong>{" "}
    {isValidUrl(zoomLink) ? (
      <Link href={zoomLink} target="_blank" rel="noopener noreferrer">
        {zoomLink}
      </Link>
    ) : (
      zoomLink
    )}
  </Typography>
      {enableZoomMeetingFromWebsite && (
        <>
          {isLoading && <Spinner />}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              onClick={(e) => getSignature(isHost ? 1 : 0, e)}
              sx={{
                mx: 2,
                border: "1px solid",
                backgroundColor: "primary.main",
                color: "white",
              }}
            >
              {isHost ? "Join Meeting as Host" : "Join Meeting"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MeetingDetails;
