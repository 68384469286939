import React, { useRef, useEffect } from "react";

const Canvas = ({
  canvasRef,
  canvasWidth,
  isDrawingEnabled,
  selectedColor,
  penStyle,
  strokeSize,
  text,
  isTextMode,
  setIsTextMode,
  isEraserMode,
  setIsEraserMode,
  socketRef,
  courseId,
  chapterNumber,
  sessionId,
  userId,
  isHost,
}) => {
  const lastSendTimeRef = useRef(0);
  const sendInterval = 2000; // Adjust this value to control the sending interval (in milliseconds)
  const drawingDataRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (isEraserMode) {
      canvas.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewport="0 0 16 16" style="fill:black;"><rect width="16" height="16" style="fill:white;stroke:black;stroke-width:1;" /></svg>') 8 8, auto`;
    } else {
      canvas.style.cursor = 'crosshair';
    }
  }, [isEraserMode]);

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    const canvas = canvasRef.current;
    canvas.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      canvas.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  const handleMouseDown = (e) => {
    if (!isDrawingEnabled) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.strokeStyle = isEraserMode ? "#FFFFFF" : selectedColor;
    context.lineWidth = strokeSize;

    if (penStyle === "dashed" && !isEraserMode) {
      context.setLineDash([5, 5]);
    } else if (penStyle === "dotted" && !isEraserMode) {
      context.setLineDash([1, 5]);
    } else {
      context.setLineDash([]);
    }

    const rect = canvas.getBoundingClientRect();
    let startX = e.clientX - rect.left;
    let startY = e.clientY - rect.top;

    if (isTextMode) {
      context.fillStyle = selectedColor;
      context.font = `${strokeSize * 5}px Arial`;
      context.fillText(text, startX, startY);
      setIsTextMode(false);
      return;
    }

    context.beginPath();
    context.moveTo(startX, startY);

    const handleMouseMove = (moveEvent) => {
      const endX = moveEvent.clientX - rect.left;
      const endY = moveEvent.clientY - rect.top;

      context.lineTo(endX, endY);
      context.stroke();

      drawingDataRef.current.push({
        startX,
        startY,
        endX,
        endY,
        color: isEraserMode ? "#FFFFFF" : selectedColor,
        lineWidth: strokeSize,
        penStyle: isEraserMode ? "solid" : penStyle,
      });

      startX = endX;
      startY = endY;
    };

    const handleMouseUp = () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
      // Do not reset eraser mode here
    };

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (!isDrawingEnabled) return;

    e.preventDefault();

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.strokeStyle = isEraserMode ? "#FFFFFF" : selectedColor;
    context.lineWidth = strokeSize;

    if (penStyle === "dashed" && !isEraserMode) {
      context.setLineDash([5, 5]);
    } else if (penStyle === "dotted" && !isEraserMode) {
      context.setLineDash([1, 5]);
    } else {
      context.setLineDash([]);
    }

    const rect = canvas.getBoundingClientRect();
    const touch = e.touches[0];
    let startX = touch.clientX - rect.left;
    let startY = touch.clientY - rect.top;

    if (isTextMode) {
      context.fillStyle = selectedColor;
      context.font = `${strokeSize * 5}px Arial`;
      context.fillText(text, startX, startY);
      setIsTextMode(false);
      return;
    }

    context.beginPath();
    context.moveTo(startX, startY);

    const handleTouchMove = (moveEvent) => {
      moveEvent.preventDefault();

      const touchMove = moveEvent.touches[0];
      const endX = touchMove.clientX - rect.left;
      const endY = touchMove.clientY - rect.top;

      context.lineTo(endX, endY);
      context.stroke();

      drawingDataRef.current.push({
        startX,
        startY,
        endX,
        endY,
        color: isEraserMode ? "#FFFFFF" : selectedColor,
        lineWidth: strokeSize,
        penStyle: isEraserMode ? "solid" : penStyle,
      });

      startX = endX;
      startY = endY;
    };

    const handleTouchEnd = () => {
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
      // Do not reset eraser mode here
    };

    canvas.addEventListener("touchmove", handleTouchMove, { passive: false });
    canvas.addEventListener("touchend", handleTouchEnd);
  };

  const sendDrawingData = () => {
    const now = Date.now();
    if (now - lastSendTimeRef.current >= sendInterval && drawingDataRef.current.length > 0) {
      socketRef.current.send(
        JSON.stringify({
          type: "drawing",
          course_id: courseId,
          chapter_number: chapterNumber,
          session_id: sessionId,
          user_id: userId,
          is_host: isHost,
          drawings: drawingDataRef.current,
        })
      );
      drawingDataRef.current = [];
      lastSendTimeRef.current = now;
    }
  };

  setInterval(sendDrawingData, sendInterval);

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height="500"
      style={{ border: "1px solid black", marginTop: "20px" }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    ></canvas>
  );
};

export default Canvas;