import React from "react";
import {
  TextField,
  Box,
  Tooltip,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const ProfileField = ({
  label,
  name,
  value,
  onChange,
  tooltip,
  multiline = false,
  rows = 1,
  type = "text",
  error = false,
  helperText = "",
  inputProps = {},
  placeholder = "",
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        margin="normal"
        multiline={multiline}
        rows={rows}
        type={type}
        error={error}
        helperText={helperText}
        inputProps={inputProps}
        placeholder={placeholder}
      />
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={tooltip}
          open={tooltipOpen}
          onClose={handleTooltipClose}
          disableHoverListener
        >
          <IconButton sx={{ color: "blue" }} onClick={handleTooltipOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default ProfileField;
