import React from 'react';
import { Grid, TextField, Button, Avatar, Paper } from '@mui/material';

const AddStudent = ({ newStudent, setNewStudent, selectedAvatar, setAvatarDialogOpen, addStudent }) => {
  return (
    <Paper style={{ padding: '20px', marginBottom: '20px', borderRadius: '15px', backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            fullWidth
            label="Enter student name"
            value={newStudent}
            onChange={(e) => setNewStudent(e.target.value)}
            style={{ fontFamily: 'Times New Roman' }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={addStudent} fullWidth>Add Student</Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setAvatarDialogOpen(true)}>
                Select Avatar
              </Button>
            </Grid>
            <Grid item>
              {selectedAvatar && (
                <Avatar src={selectedAvatar} alt="Selected Avatar" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddStudent;