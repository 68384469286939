import React, { useState, useEffect } from 'react';
import TeacherProfile from './TeacherProfile/TeacherProfile';
import TeacherCalendar from './TeacherCalendar/TeacherCalendar';
import PayTeacher from './PayTeacher/PayTeacher';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { getBaseUrl } from '../Utils/getBaseUrl'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScheduleTeacherDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [availability, setAvailability] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [newBookingRequest, setNewBookingRequest] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const fetchTeacherDetails = async () => {
            try {
                if (!id) {
                    navigate('/');
                    return; // Exit the function to avoid further code execution
                }

                const baseUrl = getBaseUrl();
                const dataRequest = {
                    sku: id
                };

                const response = await fetch(`${baseUrl}/api/teacher-profile-availability`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataRequest),
                });

                if (response.status !== 200) {
                    toast.error('Teacher does not accept one on one lessons');
                    setProfile({
                        email: "Not provided",
                        picture_url: "",
                        interests: "Not provided",
                        experience: "Not provided",
                        tagline: "Not provided",
                        rate_per_half_hour: 0,
                        teacher_id: 0,
                        name: "Not provided",
                        country: "Not provided"
                    });
                    setAvailability([]);
                    setBookings([]);
                } else {
                    const data = await response.json();
                    setProfile(data.profile);
                    setAvailability(data.availability);
                    setBookings(data.bookings);
                }
            } catch (error) {
                console.log('Unable to fetch teacher details', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTeacherDetails();
    }, [id, navigate]);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <TeacherProfile profile={profile} />
            <TeacherCalendar 
                availability={availability} 
                bookings={bookings} 
                newBookingRequest={newBookingRequest}
                setNewBookingRequest={setNewBookingRequest}
                profile={profile}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}/>
            {profile.teacher_id !== 0 && (
                <PayTeacher 
                    profile={profile}
                    totalPrice={totalPrice}
                    newBookingRequest={newBookingRequest}/>
            )}
        </div>
    );
};

export default ScheduleTeacherDetails;