import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, Avatar, Rating, Button, Modal, Box, TextField, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getBaseUrl } from "../Utils/getBaseUrl";
import { toast } from "react-toastify";
import capitalizeName from '../Utils/capitalizeName';


const useStyles = makeStyles({
  container: {
    marginTop: "20px",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#3f51b5",
    fontFamily: "Times New Roman",
  },
  card: {
    border: "1px solid #e0e0e0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    transition: "transform 0.2s",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:hover": {
      transform: "scale(1.05)",
    },
    width: "100%", // Ensure cards take up full width of their container
  },
  cardContent: {
    padding: "20px",
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    marginRight: "10px",
  },
  feedbackHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  feedbackDate: {
    color: "#757575",
  },
  feedbackText: {
    marginTop: "10px",
    fontStyle: "italic",
  },
  feedbackRating: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
  },
  replyButton: {
    marginTop: "10px",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#fff", // Added background color
    borderRadius: "10px", // Added border radius
    padding: "20px", // Added padding
  },
  textField: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  sendButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
});

const CourseRatingDetails = ({ item, courseOwner }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [response, setResponse] = useState("");
  const [expandedFeedback, setExpandedFeedback] = useState({});

  const handleOpen = (feedback) => {
    setSelectedFeedback(feedback);
    setResponse(feedback.response || "");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResponse("");
  };

  const handleResponseChange = (e) => {
    setResponse(e.target.value);
  };

  const handleReply = async () => {
    const baseUrl = getBaseUrl();

    const dataRequest = {
      feedback_id: selectedFeedback.id,
      email: localStorage.getItem("email"),
      course_id: item.course_id,
      response,
      is_response: true,
    };

    const responseBackendCall = await fetch(`${baseUrl}/api/update-user-product-feedback`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });

    if (responseBackendCall.ok) {
      toast.success("Responded to the feedback");

      // Update the feedback response in the state
      setSelectedFeedback((prevFeedback) => ({
        ...prevFeedback,
        response,
      }));

      // Update the feedback in the item state
      item.feedbacks = item.feedbacks.map((fb) =>
        fb.id === selectedFeedback.id ? { ...fb, response } : fb
      );
    } else {
      toast.error("Failed to respond");
    }

    handleClose();
  };

  const toggleFeedback = (id) => {
    setExpandedFeedback((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const truncateFeedback = (feedback, id) => {
    const maxLength = 100;
    if (feedback.length <= maxLength || expandedFeedback[id]) {
      return feedback;
    }
    return `${feedback.substring(0, maxLength)}...`;
  };

  return (
    <div className={classes.container}>
      {item.feedbacks && item.feedbacks.length > 0 && (
        <Typography variant="h5" component="div" className={classes.title}>
          Reviews
        </Typography>
      )}
      <Grid container spacing={2}>
        {item.feedbacks && item.feedbacks.map((feedback, index) => (
          <Grid item xs={12} sm={6} key={index} style={{ display: "flex" }}>
            <Card className={classes.card} style={{ backgroundColor: feedback.from_teacher ? '#e0ffe0' : '#fff' }}>
              <CardContent className={classes.cardContent}>
                <div className={classes.feedbackHeader}>
                  <Avatar className={classes.avatar}>
                    {feedback.name.charAt(0)}
                  </Avatar>
                  <Typography variant="h6" component="div">
                    {capitalizeName(feedback.name)}
                  </Typography>
                </div>
                <Typography variant="body2" className={classes.feedbackDate}>
                  {new Date(feedback.updated_at).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" component="div" className={classes.feedbackText}>
                  {truncateFeedback(feedback.feedback, feedback.id)}
                  {feedback.feedback.length > 100 && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => toggleFeedback(feedback.id)}
                    >
                      {expandedFeedback[feedback.id] ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </Typography>
                <div className={classes.feedbackRating}>
                  <Rating value={feedback.rating} readOnly precision={0.5} />
                </div>
                {feedback.response && (
                  <Typography variant="body2" component="div" style={{ marginTop: "10px", color: "#3f51b5" }}>
                    Teacher's Response: {feedback.response}
                  </Typography>
                )}
                {feedback.from_teacher == 1 && (
                  <Chip
                    label="Instructor's Feedback"
                    color="primary"
                    style={{ marginTop: "10px" }}
                  />
                )}
                {courseOwner && (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.replyButton}
                    onClick={() => handleOpen(feedback)}
                  >
                    Reply
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.modalBox}>
          {selectedFeedback && (
            <>
              <Typography variant="h6" component="div">
                {selectedFeedback.name}
              </Typography>
              <Typography variant="body2" className={classes.feedbackDate}>
                {new Date(selectedFeedback.updated_at).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" component="div" className={classes.feedbackText}>
                {selectedFeedback.feedback}
              </Typography>
              <TextField
                label="Response"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={response}
                onChange={handleResponseChange}
                className={classes.textField}
              />
              <div className={classes.sendButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReply}
                >
                  Send Reply
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CourseRatingDetails;