import React from "react";
import Rating from "@mui/material/Rating";

const CourseRating = ({ item }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        marginTop: "10px",
        marginBottom: "10px", // Added spacing below
      }}
    >
      <Rating
        name="read-only"
        value={parseFloat(item.average_rating)}
        precision={0.1}
        readOnly
        size="small"
      />
      <span style={{ marginLeft: "5px", fontWeight: "bold" }}>
        ({item.total_ratings})
      </span>
    </div>
  );
};

export default CourseRating;
