import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

// Import GIFs
import authGif from "./Step1.gif";
import createCourseGif from "./Step2.gif";
import profileDataGif from "./Step3.gif";
import calendarGif from "./Step4.gif";

const steps = [
  {
    label: "Login",
    icon: <PersonOutlineIcon fontSize="large" sx={{ color: "#FF0000" }} />,
    description:
      "Securely authenticate your identity to access personalized tools and resources.",
    gif: authGif,
    moreDescription:
      "Our authentication process is quick and secure, ensuring that your personal information is protected at all times.",
  },
  {
    label: "Create Course",
    icon: <SchoolOutlinedIcon fontSize="large" sx={{ color: "#FF0000" }} />,
    description:
      "Easily design and set up your courses with our intuitive course creation tools.",
    gif: createCourseGif,
    moreDescription:
      "With our user-friendly interface, you can create engaging courses in no time, tailored to your teaching style and subject matter.",
  },
  {
    label: "Create Teacher Profile",
    icon: <AccountBoxOutlinedIcon fontSize="large" sx={{ color: "#FF0000" }} />,
    description:
      "Manage your professional profile to connect with students and showcase your expertise.",
    gif: profileDataGif,
    moreDescription:
      "Keep your profile up-to-date to attract more students and highlight your qualifications and experience.",
  },
  {
    label: "Update Calendar",
    icon: <CalendarTodayOutlinedIcon fontSize="large" sx={{ color: "#FF0000" }} />,
    description:
      "Organize your teaching schedule and stay on top of important events.",
    gif: calendarGif,
    moreDescription:
      "Our calendar tool helps you manage your time effectively, ensuring you never miss a class or important event.",
  },
];

export default steps;