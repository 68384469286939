// Function to replace placeholders with images or audio
export const replaceWithMedia = (text, attachments = [], audioAttachments = [], isDesktopOrLarger) => {
  if (!text) {
    return '';
  }
  return text.replace(/\[(attach|audio)(\d+)\]/g, (match, type, index) => {
    const attachmentIndex = parseInt(index) - 1;
    if (type === 'attach' && attachments[attachmentIndex]) {
      return `<img style="max-width: 100%; max-height: 100%; object-fit: contain;" src="${attachments[attachmentIndex]}" alt="attachment${index}" />`;
    } else if (type === 'audio' && audioAttachments[attachmentIndex]) {
      return `
        <div style="display: flex; align-items: center;">
          <button onclick="document.getElementById('audio${attachmentIndex}').play()" style="background-color: #4CAF50; border: none; color: white; padding: 10px 20px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 12px;">
            <i class="fa fa-play"></i> Play
          </button>
          <audio id="audio${attachmentIndex}" style="display: none;">
            <source src="${audioAttachments[attachmentIndex]}" type="audio/webm">
            Your browser does not support the audio element.
          </audio>
        </div>`;
    } else {
      return match;
    }
  });
};