import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Button,
} from "@mui/material";
import ChapterSelection from "./ChapterSelection";
import { getBaseUrl } from "../Utils/getBaseUrl";
import "./style.css";
import { toast } from 'react-toastify';

const DeleteChapter = ({ courseData, appFileData }) => {
  const navigate = useNavigate();
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("Select a chapter to delete");

  const handleChapterClick = (chapter) => {
    setSelectedChapter(chapter);
    setMessage(`Chapter Number ${chapter} will be deleted`);
  };

  const handleDeleteChapter = async () => {
    if (!selectedChapter) {
      toast.error('You must select a chapter to delete.');
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const dataRequest = new FormData();
      dataRequest.append("user_email", localStorage.getItem("email"));
      dataRequest.append("course_id", courseData?.id);
      dataRequest.append("course_url", courseData?.app_data_url);
      dataRequest.append("chapter_number", selectedChapter);
  
      const deleteChapterResponse = await fetch(
        `${baseUrl}/api/delete-chapter`,
        {
          method: "POST",
          body: dataRequest,
        }
      );
  
      if (deleteChapterResponse.ok) {
        toast.success('Chapter is deleted.');
        navigate(`/Courses/${courseData?.id}`);
      } else {
        toast.error('Failed to delete chapter.');
      }
    } catch (error) {
      console.error("Error deleting the chapter:", error);
      toast.error('Error deleting the chapter.');
    } finally {
      setIsLoading(false);
    }
  };

  const totalChapters =
  courseData && (courseData.type === "programming" || courseData.type === "language")
      ? courseData?.number_of_chapters
      : appFileData?.totalChapters;

  return (
    <div>
      <h3>Delete Chapter</h3>

      <ChapterSelection
        totalChapters={totalChapters}
        selectedChapter={selectedChapter}
        handleChapterClick={handleChapterClick}
      />
      <p className="fancy-message">{message}</p>
      {isLoading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <div className="quiz-content">
          <div className="update-button">
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteChapter}
              disabled={isLoading}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/Courses/${courseData?.id}`)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteChapter;