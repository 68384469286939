import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import "./style.css"; // Import the CSS file

function CourseCategories({ formData, handleCheckboxChange, errors, refs }) {
  return (
    <>
      <h2 className="subheader">Course Category *</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <FormGroup className="checkboxGroup" ref={refs.categoriesRef}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.categories.kids}
                onChange={handleCheckboxChange}
                name="kids"
              />
            }
            label="Kids"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.categories.programming}
                onChange={handleCheckboxChange}
                name="programming"
              />
            }
            label="Software Programming"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.categories.languages}
                onChange={handleCheckboxChange}
                name="languages"
              />
            }
            label="Spoken Languages"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.categories.certifications}
                onChange={handleCheckboxChange}
                name="certifications"
              />
            }
            label="Certifications"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.categories.live}
                onChange={handleCheckboxChange}
                name="live"
              />
            }
            label="Live"
          />
        </FormGroup>
        {errors.categories && (
          <span className="error">{errors.categories}</span>
        )}
      </div>
    </>
  );
}

export default CourseCategories;
