import React, { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../Spinner";

const CheckoutForm = (props) => {
  const navigate = useNavigate();
  const { stripe, elements, profile, totalPrice, newBookingRequest } = props;
  const [formData, setFormData] = useState({
    email: localStorage.getItem("email") || "",
    name: "",
    phone: "",
    notes: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = getBaseUrl();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrors({}); // Clear any previous errors on change
  };

  const bookTeacherTimeSlots = async () => {
    const dataRequest = {
      teacher_email: profile.email,
      bookings: newBookingRequest.map((booking) => {
        const startTime = new Date(booking.start);
        const endTime = new Date(startTime.getTime() + 30 * 60000); // Add 30 minutes
        return {
          start_time: startTime.toISOString(),
          end_time: endTime.toISOString(),
          repeat_type: booking.repeatType || 'none',
          repeat_days: booking.repeatDays || [],
        };
      }),
      total_amount: totalPrice,
      notes: formData.notes,
      user_email: formData.email,
      user_name: formData.name,
      user_phone_number: formData.phone,
    };
  
    try {
      const response = await fetch(`${baseUrl}/api/create-teacher-booking`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
  
      if (!response.ok) {
        throw new Error(
          "Your card has been charged, but we failed to create the booking. Please contact support."
        );
      }
  
      const data = await response.json();
      if (data.success) {
        toast.success(
          "Bookings created successfully. Look out for an email confirmation with meeting details."
        );
        navigate("/");
      } else {
        toast.error(data.error || "Failed to create booking");
      }
    } catch (error) {
      toast.error(
        "Your card has been charged, but we failed to create the booking. Please contact support."
      );
    }
  };

  const handlePayment = async (event) => {
    try {
      setIsLoading(true);

      event.preventDefault();
      // Basic sanity checks
      const validationErrors = {};
      if (
        !formData.email ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ) {
        validationErrors.email = "Please enter a valid email address.";
      }
      if (!formData.name) {
        validationErrors.name = "Please enter your name.";
      }
      if (!formData.phone) {
        validationErrors.phone = "Please enter your phone number.";
      }

      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        if (totalPrice <= 0) {
          await bookTeacherTimeSlots();
          return;
        }

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable your form or show a loading indicator.
          return;
        }

        const dataRequest = {
          amount: totalPrice,
          currency: "USD",
        };

        const response = await fetch(`${baseUrl}/api/stripe-secret`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });
        const data = await response.json();

        // Use cardElement to confirm the payment
        const result = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: formData.name,
              email: formData.email,
              phone: formData.phone,
            },
          },
        });

        if (result.error) {
          toast.error("Payment Declined. Registration was not successful.");
        } else {
          await bookTeacherTimeSlots();
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Container sx={{ mt: 2 }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6}>
            <form onSubmit={handlePayment}>
              <fieldset style={{ borderRadius: '10px', border: '2px solid #1976d2', padding: '20px' }}>
                <legend style={{ fontFamily: "Times New Roman", fontSize: '1.5rem', color: '#1976d2' }}>
                  Contact Information
                </legend>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ fontFamily: "Times New Roman" }}
                  />
                  <TextField
                    label="Name"
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{ fontFamily: "Times New Roman" }}
                  />
                  <TextField
                    label="Phone"
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    error={!!errors.phone}
                    helperText={errors.phone}
                    sx={{ fontFamily: "Times New Roman" }}
                  />
                </Box>
                {totalPrice > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <CardElement options={{ style: { base: { fontFamily: 'Times New Roman', fontSize: '16px' } } }} />
                  </Box>
                )}
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    fullWidth
                    sx={{ fontFamily: "Times New Roman", fontSize: '1.2rem', padding: '10px 0' }}
                  >
                    {totalPrice > 0 ? "Pay" : "Book"}
                  </Button>
                </Box>
              </fieldset>
            </form>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "20px" }}>
            <Card className="text-center" sx={{ mb: 2, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h5" sx={{ fontFamily: "Times New Roman", color: '#1976d2' }}>
                  Total Amount
                </Typography>
                <Typography variant="h6" sx={{ fontFamily: "Times New Roman" }}>
                  {totalPrice} USD
                </Typography>
              </CardContent>
            </Card>
            <TextField
              label="Special Instructions"
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 1000 }}
              sx={{ fontFamily: "Times New Roman" }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          profile={props.profile}
          totalPrice={props.totalPrice}
          newBookingRequest={props.newBookingRequest}
        />
      )}
    </ElementsConsumer>
  );
}