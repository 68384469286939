import React from "react";
import { Typography, Box } from "@mui/material";

function ImageUploadInstructions() {
  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        marginBottom: 2,
        fontFamily: "Times New Roman, Times, serif",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: "bold", fontFamily: "Times New Roman, Times, serif" }}>
        Do not put your personal image as course image.
      </Typography>
    </Box>
  );
}

export default ImageUploadInstructions;