import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid } from '@mui/material';
import MicrophoneSelector from './MicrophoneSelector';
import RecordingControls from './RecordingControls';
import ConfirmationDialog from './ConfirmationDialog';

const MAX_RECORDING_TIME = 1800; // 30 minutes
const WARNING_TIME = 10; // 10 seconds before max time

const RecordAndShareAudio = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [recordingTime, setRecordingTime] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      setDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedDevice(audioDevices[0].deviceId);
      }
    }).catch(error => {
      console.error('Error enumerating devices:', error);
    });
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const startRecording = async () => {
    if (audioURL && !isRecording) {
      showConfirmationDialog(() => {
        setAudioURL('');
        setAudioBlob(null);
        startRecordingProcess();
      });
    } else {
      startRecordingProcess();
    }
  };

  const startRecordingProcess = async () => {
    try {
      const constraints = isMobile ? { audio: true } : { audio: { deviceId: selectedDevice } };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => {
        setAudioBlob(e.data);
        setAudioURL(URL.createObjectURL(e.data));
      };
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
      setRecordingTime(0);
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => {
          if (prev >= MAX_RECORDING_TIME - WARNING_TIME && prev < MAX_RECORDING_TIME) {
            setShowWarningDialog(true);
          }
          if (prev >= MAX_RECORDING_TIME) {
            stopRecording();
            return prev;
          }
          return prev + 1;
        });
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Could not start recording. Please ensure you have granted microphone permissions.');
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.resume();
      setIsPaused(false);
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => {
          if (prev >= MAX_RECORDING_TIME - WARNING_TIME && prev < MAX_RECORDING_TIME) {
            setShowWarningDialog(true);
          }
          if (prev >= MAX_RECORDING_TIME) {
            stopRecording();
            return prev;
          }
          return prev + 1;
        });
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      setIsPaused(false);
      clearInterval(timerRef.current);
      setShowWarningDialog(false);
    }
  };

  const downloadRecording = () => {
    const link = document.createElement('a');
    link.href = audioURL;
    link.download = 'recording.wav';
    link.click();
  };

  const deleteRecording = () => {
    setAudioURL('');
    setAudioBlob(null);
  };

  const handleDialogClose = (confirm) => {
    setShowDialog(false);
    if (confirm && dialogAction) {
      dialogAction();
    }
  };

  const showConfirmationDialog = (action) => {
    setDialogAction(() => action);
    setShowDialog(true);
  };

  const handleWarningDialogClose = () => {
    setShowWarningDialog(false);
  };

  return (
    <Card style={{ fontFamily: 'Times New Roman', backgroundColor: '#f0f8ff', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
      <CardContent>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <Typography variant="h6" gutterBottom style={{ color: '#4b0082', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>
              Record and Share Audio
            </Typography>
          </Grid>
          <Grid item>
            <MicrophoneSelector devices={devices} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice} />
          </Grid>
          <Grid item>
            <RecordingControls
              isRecording={isRecording}
              isPaused={isPaused}
              startRecording={startRecording}
              pauseRecording={pauseRecording}
              resumeRecording={resumeRecording}
              stopRecording={stopRecording}
              downloadRecording={downloadRecording}
              deleteRecording={() => showConfirmationDialog(deleteRecording)}
              audioURL={audioURL}
            />
          </Grid>
          {isRecording && (
            <Grid item>
              <Typography variant="body2" style={{ color: '#4b0082', textAlign: 'center', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>
                Recording Time: {Math.floor(recordingTime / 60)}:{recordingTime % 60 < 10 ? '0' : ''}{recordingTime % 60}
              </Typography>
            </Grid>
          )}
          {!isRecording && audioURL && (
            <Grid item>
              <Typography variant="body2" style={{ color: '#4b0082', textAlign: 'center', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)' }}>
                Total Recording Time: {Math.floor(recordingTime / 60)}:{recordingTime % 60 < 10 ? '0' : ''}{recordingTime % 60}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <ConfirmationDialog
        open={showDialog}
        onClose={handleDialogClose}
      />
      <Dialog
        open={showWarningDialog}
        onClose={handleWarningDialogClose}
      >
        <DialogTitle>Recording Time Limit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your recording will stop automatically in {MAX_RECORDING_TIME - recordingTime} seconds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default RecordAndShareAudio;