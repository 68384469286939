import React from 'react';
import { Paper, Typography, FormControl, InputLabel, Select, MenuItem, Grid, Avatar } from '@mui/material';

const StudentOfTheLesson = ({ students, studentOfTheLesson, handleStudentOfTheLessonChange, selectedStudent }) => {
  return (
    <Paper style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h5" gutterBottom style={{ fontFamily: 'Times New Roman' }}>Student of the Lesson</Typography>
      <FormControl fullWidth>
        <InputLabel>Select a student</InputLabel>
        <Select
          value={studentOfTheLesson}
          onChange={handleStudentOfTheLessonChange}
          style={{ fontFamily: 'Times New Roman' }}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="all">All the students</MenuItem>
          {students.map((student) => (
            <MenuItem key={student.name} value={student.name}>{student.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {studentOfTheLesson && studentOfTheLesson !== "all" && (
        <Grid container alignItems="center" style={{ marginTop: '10px' }}>
          <Grid item>
            <Typography variant="body1" style={{ fontFamily: 'Times New Roman' }}>Selected: {studentOfTheLesson}</Typography>
          </Grid>
          <Grid item>
            <Avatar src={selectedStudent?.avatar} alt={studentOfTheLesson} style={{ marginLeft: '10px' }} />
          </Grid>
        </Grid>
      )}
      {studentOfTheLesson === "all" && (
        <Typography variant="body1" style={{ marginTop: '10px', fontFamily: 'Times New Roman' }}>Selected: All the students</Typography>
      )}
    </Paper>
  );
};

export default StudentOfTheLesson;