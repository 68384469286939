import React from 'react';
import { Avatar, Box, Button, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ProfilePictureUpload = ({ pictureUrl, handlePictureUpload, isPictureValid }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handlePictureUpload(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handlePictureUpload(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box textAlign="center" onDrop={handleDrop} onDragOver={handleDragOver}>
      <Avatar
        alt="Profile Picture"
        src={pictureUrl}
        sx={{ width: 150, height: 150, margin: 'auto' }}
      />
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-picture"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-picture">
        <Button variant="contained" component="span" sx={{ marginTop: 2 }}>
          Upload Picture
        </Button>
      </label>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title="Upload your picture"
          open={tooltipOpen}
          onClose={handleTooltipClose}
          disableHoverListener
        >
          <IconButton sx={{ color: 'blue' }} onClick={handleTooltipOpen}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default ProfilePictureUpload;