import React from 'react';
import { useDragLayer } from 'react-dnd';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    dragLayer: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    draggedItem: {
        position: 'absolute',
        pointerEvents: 'none',
        backgroundColor: '#e0e0e0',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100px',
        maxWidth: '200px',
        textAlign: 'center',
    },
});

const CustomDragLayer = () => {
    const classes = useStyles();
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging || !currentOffset) {
        return null;
    }

    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;

    return (
        <div className={classes.dragLayer}>
            <div className={classes.draggedItem} style={{ transform }}>
                {item.option.text}
            </div>
        </div>
    );
};

export default CustomDragLayer;