const Review = ({ quizData, userAnswers, isDesktopOrLarger }) => {
    const replaceWithMedia = (text, attachments = [], audioAttachments = [], isDesktopOrLarger) => {
        return text.replace(/\[(attach|audio)(\d+)\]/g, (match, type, index) => {
          const attachmentIndex = parseInt(index) - 1;
          if (type === 'attach' && attachments[attachmentIndex]) {
            return `<img style="max-width: 100%; max-height: 100%; object-fit: contain;" src="${attachments[attachmentIndex]}" alt="attachment${index}" />`;
          } else if (type === 'audio' && audioAttachments[attachmentIndex]) {
            return `<audio controls>
                      <source src="${audioAttachments[attachmentIndex]}" type="audio/webm">
                      Your browser does not support the audio element.
                    </audio>`;
          } else {
            return match;
          }
        });
      };

    return (
        <div>
            {quizData.map((question, index) => {
                const questionText = replaceWithMedia(question.question, question.attachments, question.audio_attachments, isDesktopOrLarger);
                const answerText = replaceWithMedia(question.answer, question.attachments, question.audio_attachments, isDesktopOrLarger);
                const option1Text = replaceWithMedia(question.option1, question.attachments, question.audio_attachments, isDesktopOrLarger);
                const option2Text = replaceWithMedia(question.option2, question.attachments, question.audio_attachments, isDesktopOrLarger);
                const option3Text = replaceWithMedia(question.option3, question.attachments, question.audio_attachments, isDesktopOrLarger);

                return (
                    <div key={index}>
                        <p style={{ fontWeight: 'bold', marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: questionText }}></p>
                        {['option1', 'option2', 'option3', 'answer'].map((option, optionIndex) => {
                            const isCorrectAnswer = question[option] === question.answer;
                            const isUserAnswer = question[option] === userAnswers[index];
                            const isUserCorrect = isCorrectAnswer && isUserAnswer;
                            const isUserIncorrect = !isCorrectAnswer && isUserAnswer;

                            let optionColor;
                            let optionSymbol;
                            if (isCorrectAnswer) {
                                optionColor = 'green';
                                optionSymbol = '✓';
                            } else if (isUserIncorrect) {
                                optionColor = 'red';
                                optionSymbol = '✗';
                            } else {
                                optionColor = 'black';
                                optionSymbol = '';
                            }

                            let optionText;
                            switch (option) {
                                case 'option1':
                                    optionText = option1Text;
                                    break;
                                case 'option2':
                                    optionText = option2Text;
                                    break;
                                case 'option3':
                                    optionText = option3Text;
                                    break;
                                case 'answer':
                                    optionText = answerText;
                                    break;
                                default:
                                    optionText = '';
                            }

                            return (
                                <>
                                    <div key={optionIndex} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                        <span dangerouslySetInnerHTML={{ __html: optionText }}></span>
                                        <span style={{ marginLeft: '10px', color: optionColor, fontSize: '5em' }}>{optionSymbol}</span>
                                    </div>
                                   
                                </>
                            );
                        })}
                    <hr style={{ margin: '20px 0', border: '2px solid black' }} />
                    </div>
                );
            })}

        </div>
    );
};

export default Review;