import React, { useState } from "react";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MessageInput = ({
  userId,
  input,
  setInput,
  messageId,
  setMessageId,
  parent,
  setParent,
  courseId,
  chapterNumber,
  ws,
  isDemoCourse,
}) => {
  const [attachment, setAttachment] = useState(null);
  const [attachmentName, setAttachmentName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const renderMedia = (url) => {
    return (
      <>
        <img
          src={url}
          alt="media"
          onError={(e) => {
            e.target.style.display = "none";
            e.target.nextSibling.style.display = "block";
          }}
          style={{
            maxWidth: "100%",
            marginTop: "10px",
            borderRadius: "10px",
            display: "block",
          }}
        />
        <video
          controls
          style={{
            maxWidth: "100%",
            marginTop: "10px",
            borderRadius: "10px",
            display: "none",
          }}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </>
    );
  };

  const handleSendMessage = async (messageContent, attachment) => {
    try {
      setIsLoading(true);

      // Ensure WebSocket connection is established
      if (!ws || ws.readyState !== WebSocket.OPEN) {
        throw new Error("WebSocket is not open");
      }

      // Convert attachment to Base64 if it exists
      let attachmentData = null;
      if (attachment) {
        const reader = new FileReader();
        reader.onload = () => {
          attachmentData = reader.result.split(",")[1]; // Get Base64 part of the result
          sendWebSocketMessage(messageContent, attachmentData);
        };
        reader.readAsDataURL(attachment); // Read file as Data URL to get Base64 string
      } else {
        sendWebSocketMessage(messageContent, null);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
      setInput(""); // Clear the input field after sending the message
      setAttachment(null); // Clear the attachment after sending the message
      setAttachmentName(""); // Clear the attachment name after sending the message
      setMessageId(null); // Clear the message id after sending the message
      setParent(null); // Clear the parent after sending the message
    }
  };

  const sendWebSocketMessage = (messageContent, attachmentData) => {
    const messageData = {
      action: "upload",
      message: {
        user_id: userId,
        content: messageContent,
        course_id: courseId,
        chapter_number: chapterNumber,
      },
      files: attachmentData
        ? [{ name: attachment.name, data: attachmentData }]
        : [],
    };

    if (parent?.parentId) {
      messageData.message.parent_message_id = parent.parentId;
    }
    if (messageId) {
      messageData.message.message_id = messageId;
    }
    // Send the message data as a JSON string via WebSocket
    ws.send(JSON.stringify(messageData));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (isDemoCourse) {
      toast.info("Messaging is not available for demo courses");
      return;
    }
    if (attachment && attachment.size > 20 * 1024) {
      alert("File cannot be greater than 20 KB");
      return;
    }
    handleSendMessage(input, attachment);
  };

  const handleFileChange = (e) => {
    if (isDemoCourse) {
      toast.info("Messaging is not available for demo courses");
      return;
    }
    const file = e.target.files[0];
    if (file && file.size <= 20 * 1024) {
      setAttachment(file);
      setAttachmentName(file.name);
    } else {
      alert("File size cannot be greater than 20 KB");
    }
  };

  const isSendDisabled =
    input.trim() === "" || input.length > 1000 || isLoading;
  const isAttachDisabled = isLoading;
  const tooltipMessage = isLoading
    ? "Message is being sent..."
    : "Message cannot be empty or greater than 1000 characters";

  return (
    <>
      {parent?.parentContent || parent?.parentMediaUrl ? (
        <div
          style={{
            position: "relative",
            backgroundColor: "#f0f0f0",
            color: "#888",
            padding: "10px",
            borderRadius: "10px",
            cursor: "pointer",
            opacity: 0.7,
            zIndex: 10,
            marginBottom: "10px",
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
              color: "#888",
            }}
            onClick={() => setParent(null)}
          >
            <CancelIcon />
          </IconButton>
          {parent.parentContent && <div>{parent.parentContent}</div>}
          {parent.parentMediaUrl && (
            <div style={{ width: "50px", height: "50px", overflow: "hidden" }}>
              {renderMedia(parent.parentMediaUrl, {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              })}
            </div>
          )}
        </div>
      ) : null}
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          padding: "10px",
          borderTop: "1px solid #ccc",
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{
            flex: 1,
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginRight: "10px",
          }}
        />
        <input
          type="file"
          accept="image/*,video/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="attachment-input"
          disabled={isAttachDisabled}
        />
        <label
          htmlFor="attachment-input"
          style={{
            padding: "10px 20px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginRight: "10px",
            cursor: isAttachDisabled ? "not-allowed" : "pointer",
            backgroundColor: isAttachDisabled ? "#ccc" : "white",
          }}
        >
          Attach
          {attachmentName && (
            <span
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                backgroundColor: "#007bff",
                color: "white",
                borderRadius: "5px",
                padding: "2px 5px",
                fontSize: "0.8em",
              }}
            >
              {attachmentName}
            </span>
          )}
        </label>
        <button
          type="submit"
          disabled={isSendDisabled}
          title={isSendDisabled ? tooltipMessage : ""}
          style={{
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            backgroundColor: isSendDisabled ? "#ccc" : "#007bff",
            color: "white",
            cursor: isSendDisabled ? "not-allowed" : "pointer",
          }}
        >
          Send
        </button>
      </form>
    </>
  );
};

export default MessageInput;
