import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import "./Login.css";

const Login = ({
  formEmail,
  formPassword,
  handleChangeEmail,
  handleChangePassword,
  handleSubmitLogin,
  setStage,
  signInUsingGoogle,
  navigate,
}) => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleSubmitLogin(e);
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    await signInUsingGoogle();
    setLoading(false);
  };

  return (
    <Container className="auth-container">
      <Box className="auth-box">
        <Typography variant="h4" className="auth-title">
          Welcome back
        </Typography>
        <form onSubmit={handleLogin} className="auth-form">
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="email"
            value={formEmail}
            placeholder="Enter Email"
            onChange={handleChangeEmail}
            label="Email"
            disabled={loading}
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={formPassword}
            placeholder="Password"
            onChange={handleChangePassword}
            label="Password"
            disabled={loading}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className="auth-button"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Sign In"}
          </Button>
          <Button
            fullWidth
            variant="text"
            color="secondary"
            onClick={() => setStage("register")}
            className="auth-link"
            disabled={loading}
          >
            Register
          </Button>
        </form>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={handleGoogleSignIn}
          className="auth-google-button"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Sign in with Google"}
        </Button>
        <Typography
          variant="body2"
          className="auth-help"
          style={{ marginTop: "10px" }}
          onClick={() => setStage("forgot")}
          disabled={loading}
        >
          Need help?
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
