import React from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import StepItem from "./StepItem";
import steps from "./steps";

const OnboardingCard = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        padding: "30px",
        borderRadius: "15px",
        margin: "20px 0",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#B71C1C",
          fontWeight: "bold",
          marginBottom: "30px",
          textAlign: "center",
          fontFamily: "Times New Roman",
          background: "linear-gradient(45deg, #E53935 30%, #D32F2F 90%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        4 Easy Steps to Become a Ghumman Tech Teacher
      </Typography>
      <Box
        sx={{
          position: "relative",
          paddingLeft: { xs: "10px", md: "40px" },
          paddingRight: { xs: "10px", md: "40px" },
        }}
      >
        {steps.map((step, index) => (
          <StepItem key={index} step={step} index={index} />
        ))}
      </Box>
      <Box textAlign="center" mt={4}>
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(45deg, #E53935 30%, #D32F2F 90%)",
            color: "#fff",
            size: "large",
          }}
          onClick={() => (window.location.href = "/Onboarding")}
        >
          JOIN NOW
        </Button>
      </Box>
    </Paper>
  );
};

export default OnboardingCard;