import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Box, Grid, IconButton, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ImageUploadInstructions from "./ImageUploadInstructions";

function FileInput({ formData, handleFileChange, errors, setErrors, refs }) {
  const [images, setImages] = useState(formData.images);

  useEffect(() => {
    setImages(formData.images);
  }, [formData.images]);

  const onDrop = (acceptedFiles, index) => {
    const file = acceptedFiles[0];
    const newErrors = { ...errors };

    if (
      file &&
      file.type.startsWith("image/") &&
      file.size <= 1.8 * 1024 * 1024
    ) {
      const event = {
        target: {
          files: acceptedFiles,
          name: `image_${index}`,
        },
      };
      handleFileChange(event, index);

      // Update the images state to trigger re-render
      const newImages = [...images];
      newImages[index] = acceptedFiles[0];
      setImages(newImages);

      // Clear the specific image error
      if (newErrors.images) {
        newErrors.images[index] = null;
        if (Object.values(newErrors.images).every((error) => error === null)) {
          delete newErrors.images;
        }
      }
    } else {
      // Handle invalid file type or size
      if (!newErrors.images) {
        newErrors.images = {};
      }
      newErrors.images[index] = "Only image files less than 1.8MB are allowed";
    }
    setErrors(newErrors);
  };

  const handleInputChange = (e, index) => {
    const file = e.target.files[0];
    const newErrors = { ...errors };

    if (
      file &&
      file.type.startsWith("image/") &&
      file.size <= 1.8 * 1024 * 1024
    ) {
      handleFileChange(e, index);

      // Update the images state to trigger re-render
      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);

      // Clear the specific image error
      if (newErrors.images && typeof newErrors.images === "object") {
        newErrors.images[index] = null;
        if (Object.values(newErrors.images).every((error) => error === null)) {
          delete newErrors.images;
        }
      } else if (typeof newErrors.images === "string") {
        newErrors.images = {};
        newErrors.images[index] = null;
      }
    } else {
      // Handle invalid file type or size
      if (!newErrors.images || typeof newErrors.images === "string") {
        newErrors.images = {};
      }
      newErrors.images[index] = "Only image files less than 1.8MB are allowed";
    }
    setErrors(newErrors);
  };

  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        fontFamily: "Times New Roman, Times, serif",
      }}
      ref={refs.imagesRef}
    >
      <ImageUploadInstructions />
      <Typography variant="body1" sx={{ fontWeight: "bold", marginBottom: 2, fontFamily: "Times New Roman, Times, serif" }}>
        Uploading new images will replace all your current images. If you want
        to change just one image, you must re-upload all the images again.
        Ensure each image is less than 1.8MB.
      </Typography>
      <Grid container spacing={2}>
        {images.map((image, index) => {
          const { getRootProps, getInputProps } = useDropzone({
            onDrop: (acceptedFiles) => onDrop(acceptedFiles, index),
            accept: "image/*",
            multiple: false,
          });

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                {...getRootProps()}
                sx={{
                  textAlign: "center",
                  padding: 2,
                  border: "1px dashed #ccc",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  fontFamily: "Times New Roman, Times, serif",
                }}
              >
                <input
                  {...getInputProps()}
                  name={`image_${index}`}
                  accept="image/*"
                  onChange={(e) => handleInputChange(e, index)}
                  style={{ display: "none" }}
                  id={`image_${index}`}
                />
                <Tooltip title={image ? "Change Image" : "Add Image"} arrow>
                  <IconButton color="primary" component="span">
                    {image ? (
                      <CloudUploadIcon fontSize="large" />
                    ) : (
                      <AddIcon fontSize="large" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              {errors.images && errors.images[index] && (
                <Typography variant="body2" color="error" sx={{ fontFamily: "Times New Roman, Times, serif" }}>
                  {errors.images[index]}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
      {errors.images && typeof errors.images === "string" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 2,
            color: "red",
            fontFamily: "Times New Roman, Times, serif",
          }}
        >
          <ErrorOutlineIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2" sx={{ fontFamily: "Times New Roman, Times, serif" }}>
            {errors.images}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default FileInput;