import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "../Spinner";
import CourseTabs from "./CourseTabs/CourseTabs";
import LanguageSelector from "./LanguageSelector";
import Chip from "@mui/material/Chip";
import { checkIfEligibleAllScenarios } from "../Utils/eligibilityService";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Box,
  Slider,
} from "@mui/material";

import { styled } from "@mui/system";
import RecordAndShareAudio from "./RecordAndShareAudio/RecordAndShareAudio"; // Import the new component
import Attendance from "./Attendance/Attendance";
import MeetingDetails from "./MeetingDetails";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedbackPopup from "./FeedbackPopup/FeedbackPopup";
import EditIcon from "@mui/icons-material/Edit"; // Import the Edit icon
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Mic from "@mui/icons-material/Mic";
import AttendanceIcon from "@mui/icons-material/EventAvailable";
import MeetingDetailsIcon from "@mui/icons-material/MeetingRoom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CourseDetails() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [eligible, setEligible] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [chapterContent, setChapterContent] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(1);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [userLanguage, setUserLanguage] = useState(
    localStorage.getItem("user-language")
  );
  const [isUserLanguageSelected, setIsUserLanguageSelected] = useState(
    localStorage.getItem("user-language") ? true : false
  );
  const [userLanguageFileData, setUserLanguageFileData] = useState(null);
  const [learnLanguageFileData, setLearnLanguageFileData] = useState(null);
  const [appFileData, setAppFileData] = useState(null);
  const [quizFileData, setQuizFileData] = useState(null);
  const [isLanugageCourseLoaded, setIsLanguageCourseLoaded] = useState(false);

  const email = localStorage.getItem("email");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
  const [isMeetingDetailsVisible, setIsMeetingDetailsVisible] = useState(false);

  const handleToggleAudio = () => {
    setIsAudioVisible(!isAudioVisible);
  };

  const handleToggleAttendance = () => {
    setIsAttendanceVisible(!isAttendanceVisible);
  };

  const handleToggleMeetingDetails = () => {
    setIsMeetingDetailsVisible(!isMeetingDetailsVisible);
  };

  const handleFeedbackOpen = () => {
    if (courseData.is_demo_course) {
      toast.info("You cannot provide feedback for a demo course.");
      return;
    }
    setFeedbackOpen(true);
  };

  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  useEffect(() => {
    const checkEligibility = async () => {
      const isEligible = await checkIfEligibleAllScenarios(email, id);
      if (!isEligible) {
        setEligible(false);
        navigate(`/`);
      } else {
        setEligible(true);
      }
    };

    checkEligibility();
  }, [email, id, navigate]);

  useEffect(() => {
    const getQuizContent = async () => {
      try {
        setIsLoading(true);

        if (!selectedChapter) {
          setSelectedChapter(1);
        }
        if (
          userLanguageFileData &&
          learnLanguageFileData &&
          courseData?.type === "language"
        ) {
          const chapter = userLanguageFileData.data.find((chapter) =>
            selectedChapter
              ? chapter.customChapterNumber === selectedChapter
              : chapter.customChapterNumber === 1
          );
          if (chapter) {
            setChapterContent({
              course_id: id,
              name: chapter.customChapterName,
              description: chapter?.customChapterDescription,
              chapter_number: selectedChapter,
              study_text_link: null,
              video_link: null,
              course_language: courseData?.name.split(" ")[1].toLowerCase(),
            });
          }

          // Get the bookmark data for this course
          const baseUrl = getBaseUrl();
          const dataRequest = {
            user_email: email,
            course_id: id,
          };
          const allBookmarksForCourseResponse = await fetch(
            `${baseUrl}/api/all-bookmark-questions-for-course`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dataRequest),
            }
          );

          if (allBookmarksForCourseResponse.ok) {
            const allBookmarksForCourseData =
              await allBookmarksForCourseResponse.json();
            const newQuizData = userLanguageFileData.data
              .filter(
                (chapter) => chapter.customChapterNumber === selectedChapter
              )
              .map((chapter) => {
                const correspondingChapter = learnLanguageFileData.data.find(
                  (learnChapter) =>
                    learnChapter.customFlashcardNumber ===
                    chapter.customFlashcardNumber
                );
                return {
                  course_id: id,
                  chapter_id: selectedChapter,
                  question: correspondingChapter
                    ? correspondingChapter.slideAnswer
                    : chapter.slideAnswer,
                  answer: chapter.slideAnswer,
                  option1: chapter.slideWrongOption1,
                  option2: chapter.slideWrongOption2,
                  option3: chapter.slideWrongOption3,
                  id: chapter.customFlashcardNumber,
                  bookmarked: allBookmarksForCourseData.includes(
                    chapter.customFlashcardNumber
                  )
                    ? 1
                    : 0,
                };
              });
            setQuizFileData(newQuizData);
          }
        }
      } catch (error) {
        console.error("Error setting up bookmark:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getQuizContent();
  }, [
    userLanguageFileData,
    learnLanguageFileData,
    selectedChapter,
    courseData,
  ]);

  useEffect(() => {
    const getAppQuizContent = async () => {
      try {
        setIsLoading(true);

        if (!selectedChapter) {
          setSelectedChapter(1);
        }
        if (
          appFileData &&
          (courseData?.type === "app" ||
            courseData?.type === "live" ||
            courseData?.type === "community")
        ) {
          const chapter = appFileData.data.find((chapter) =>
            selectedChapter
              ? chapter.customChapterNumber === selectedChapter
              : chapter.customChapterNumber === 1
          );
          // if (chapter) {
          const chapterNumber = selectedChapter || 1;
          setChapterContent({
            course_id: id,
            name: chapter?.customChapterName,
            description: chapter?.customChapterDescription,
            chapter_number: chapterNumber,
            study_text_link: appFileData[`chapter${chapterNumber}Text`],
            isFileCourse: true,
            video_link: appFileData[`chapter${chapterNumber}Video`],
            course_language:
              courseData?.name?.split(" ")[1]?.toLowerCase() || "en",
            meeting_details: appFileData[`meetingDetails`],
          });
          // }

          // Get the bookmark data for this course
          const baseUrl = getBaseUrl();
          const dataRequest = {
            user_email: email,
            course_id: id,
          };
          const allBookmarksForCourseResponse = await fetch(
            `${baseUrl}/api/all-bookmark-questions-for-course`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(dataRequest),
            }
          );

          if (allBookmarksForCourseResponse.ok) {
            const allBookmarksForCourseData =
              await allBookmarksForCourseResponse.json();
            const newQuizData = appFileData.data
              .filter(
                (chapter) => chapter.customChapterNumber === selectedChapter
              )
              .map((chapter) => {
                return {
                  course_id: id,
                  chapter_id: selectedChapter,
                  question: chapter.slideQuestion,
                  question_language: chapter?.slideQuestionLanguage,
                  answer: chapter.slideAnswer,
                  answer_language: chapter?.slideAnswerLanguage,
                  option1: chapter.slideWrongOption1,
                  option1_language: chapter?.slideWrongOption1Language,
                  option2: chapter.slideWrongOption2,
                  option2_language: chapter?.slideWrongOption2Language,
                  option3: chapter.slideWrongOption3,
                  option3_language: chapter?.slideWrongOption3Language,
                  id: chapter.customFlashcardNumber,
                  bookmarked: allBookmarksForCourseData.includes(
                    chapter.customFlashcardNumber
                  )
                    ? 1
                    : 0,
                  attachments: chapter.slideAttachments,
                  audio_attachments: chapter.slideAudioAttachments,
                  course_type: courseData?.type,
                };
              });
            setQuizFileData(newQuizData, courseData);
          }
        }
      } catch (error) {
        console.error("Error setting up bookmark:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getAppQuizContent();
  }, [appFileData, selectedChapter, courseData]);

  const handleChapterClick = async (chapterNumber) => {
    setSelectedChapter(chapterNumber); // Update selected chapter
    if (courseData.type === "programming") {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          course_id: id,
          chapter_number: chapterNumber,
        };
        const response = await fetch(`${baseUrl}/api/chapter`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });
        const data = await response.json();
        setChapterContent(data);
      } catch (error) {
        console.error("Error fetching chapter content:", error);
      }
    }
  };

  useEffect(() => {
    const getUserLanguageFile = async () => {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: `spoken-languages/${userLanguage}-flashcards.json`,
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(
            responseData.presignedUrl
          );
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            setUserLanguageFileData(content);
          }
        }
      } catch (error) {
        console.error("Error fetching user language file:", error);
      }
    };

    const getLearnLanguageFile = async () => {
      try {
        const language = courseData?.name.split(" ")[1].toLowerCase();

        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: "spoken-languages/" + language + "-flashcards.json",
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(
            responseData.presignedUrl
          );
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            setLearnLanguageFileData(content);
          }
        }
      } catch (error) {
        console.error("Error fetching user language file:", error);
      }
    };

    const getCourseFile = async () => {
      try {
        const url = new URL(courseData?.app_data_url);
        const fileLocation = url.pathname.substring(1); // Remove the leading '/'

        const baseUrl = getBaseUrl();
        const dataRequest = {
          pdfFileName: fileLocation,
        };
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        if (response.ok) {
          const responseData = await response.json();
          // Fetch the content from the presigned URL
          const responseFromPresignedUrl = await fetch(
            responseData.presignedUrl
          );
          if (responseFromPresignedUrl.ok) {
            const content = await responseFromPresignedUrl.json();

            // setLearnLanguageFileData(content);
            setAppFileData(content);
          }
        }
      } catch (error) {
        console.error("Error fetching user language file:", error);
      }
    };

    if (courseData && courseData.type === "language" && userLanguage) {
      const prevUserLanguage = localStorage.getItem("user-language");
      if (userLanguage !== prevUserLanguage) {
        localStorage.setItem("user-language", userLanguage);
        getUserLanguageFile();
      }
      if (!isLanugageCourseLoaded) {
        getLearnLanguageFile();
        getUserLanguageFile();
        setIsLanguageCourseLoaded(true);
      }
    } else if (courseData && courseData.type === "programming") {
      setIsUserLanguageSelected(true);
      handleChapterClick(selectedChapter);
    } else if (
      courseData &&
      (courseData.type === "app" ||
        courseData.type === "live" ||
        courseData.type === "community")
    ) {
      setIsUserLanguageSelected(true);
      getCourseFile();
    }
  }, [userLanguage, courseData]);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = {
          course_id: id,
        };
        const response = await fetch(`${baseUrl}/api/course`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        const data = await response.json();
        setCourseData(data);
        console.log("value of data: ", data);
        if (data.type === "programming") {
          await handleChapterClick(selectedChapter || 1);
        }
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (eligible) {
      fetchCourseDetails();
    }
  }, [eligible, selectedChapter]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!eligible || !courseData) {
    return <p>Loading...</p>;
  }

  function getGridSize(width) {
    if (width > 992) {
      // Adjust breakpoint as needed
      return 8;
    } else {
      return 4;
    }
  }

  const totalChapters =
    courseData.type === "programming" || courseData.type === "language"
      ? courseData?.number_of_chapters
      : appFileData?.totalChapters;

  const handleSliderChange = (event, newValue) => {
    setCurrentChapter(newValue);
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    handleChapterClick(newValue);
  };

  const marks = [
    { value: 1, label: "1" },
    { value: totalChapters, label: `${totalChapters}` },
    ...Array.from({ length: totalChapters - 2 }, (_, index) => ({
      value: index + 2,
      label: "",
    })),
  ];

  return (
    <>
      {isUserLanguageSelected && (
        <div>
          {courseData.type === "language" && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "20px",
              }}
            >
              <div className="title">User Language:</div>
              <Chip
                label={
                  userLanguage.charAt(0).toUpperCase() + userLanguage.slice(1)
                }
                color="primary"
                onClick={() => setIsUserLanguageSelected(false)}
              />
            </div>
          )}
          <Container
            maxWidth="lg"
            style={{ padding: "0 10px", marginTop: "20px" }}
          >
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                    padding: "20px",
                    background: "linear-gradient(135deg, #f0f8ff, #e6e6fa)",
                    borderRadius: "15px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    width: "100%",
                    boxSizing: "border-box",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    style={{
                      marginBottom: "20px",
                      color: "#4b0082",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                      textDecoration: "underline",
                    }}
                  >
                    {courseData.name}
                  </Typography>
                  <div style={{ display: "flex", gap: "15px" }}>
                    {(courseData.owner === email ||
                      email === "ghummantech@gmail.com") &&
                      appFileData && (
                        <Button
                          variant="contained"
                          className="headerButtonStyle"
                          onClick={() =>
                            navigate(`/Courses/${courseData.id}/Edit`, {
                              state: { courseData, appFileData },
                            })
                          }
                          startIcon={<EditIcon />}
                          style={{
                            backgroundColor: "#4b0082",
                            color: "#fff",
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                            transition: "transform 0.3s",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        >
                          Edit
                        </Button>
                      )}
                    <Button
                      variant="contained"
                      startIcon={<FeedbackIcon />}
                      onClick={handleFeedbackOpen}
                      style={{
                        backgroundColor: "#4b0082",
                        color: "#fff",
                        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                        transition: "transform 0.3s",
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      Feedback
                    </Button>
                  </div>
                  <FeedbackPopup
                    open={feedbackOpen}
                    onClose={handleFeedbackClose}
                    user_email={email}
                    course_id={id}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleToggleAudio}
                >
                  <Mic style={{ color: "#4b0082", marginRight: "10px" }} />
                  <Typography
                    variant="h6"
                    style={{ color: "#4b0082", marginRight: "10px" }}
                  >
                    Record and Share Audio
                  </Typography>
                  {isAudioVisible ? (
                    <ExpandLess style={{ color: "#4b0082" }} />
                  ) : (
                    <ExpandMore style={{ color: "#4b0082" }} />
                  )}
                </div>
                <div
                  style={{
                    display: isAudioVisible ? "block" : "none",
                    padding: "20px",
                    background: "linear-gradient(135deg, #f0f8ff, #e6e6fa)",
                    borderRadius: "15px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: "20px",
                  }}
                >
                  <RecordAndShareAudio />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleToggleAttendance}
                >
                  <AttendanceIcon
                    style={{ color: "#4b0082", marginRight: "10px" }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: "#4b0082", marginRight: "10px" }}
                  >
                    Attendance
                  </Typography>
                  {isAttendanceVisible ? (
                    <ExpandLess style={{ color: "#4b0082" }} />
                  ) : (
                    <ExpandMore style={{ color: "#4b0082" }} />
                  )}
                </div>
                <div
                  style={{
                    display: isAttendanceVisible ? "block" : "none",
                    padding: "20px",
                    background: "linear-gradient(135deg, #f0f8ff, #e6e6fa)",
                    borderRadius: "15px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: "20px",
                  }}
                >
                  {chapterContent && <Attendance />}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleToggleMeetingDetails}
                >
                  <MeetingDetailsIcon
                    style={{ color: "#4b0082", marginRight: "10px" }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: "#4b0082", marginRight: "10px" }}
                  >
                    Meeting Details
                  </Typography>
                  {isMeetingDetailsVisible ? (
                    <ExpandLess style={{ color: "#4b0082" }} />
                  ) : (
                    <ExpandMore style={{ color: "#4b0082" }} />
                  )}
                </div>
                <div
                  style={{
                    display: isMeetingDetailsVisible ? "block" : "none",
                    padding: "20px",
                    background: "linear-gradient(135deg, #f0f8ff, #e6e6fa)",
                    borderRadius: "15px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    width: "100%",
                    boxSizing: "border-box",
                    marginTop: "20px",
                  }}
                >
                  {chapterContent?.meeting_details && (
                    <MeetingDetails details={chapterContent?.meeting_details} />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <Box sx={{ mt: 4, ml: -2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              Select Chapter
            </Typography>
            <Slider
              value={currentChapter}
              min={1}
              max={totalChapters}
              step={1}
              marks={marks}
              valueLabelDisplay="on"
              onChange={handleSliderChange}
              onChangeCommitted={handleSliderChangeCommitted}
              sx={{
                width: "80%",
                margin: "auto",
                transition: "all 0.3s ease",
                "& .MuiSlider-thumb": {
                  backgroundColor: "#ff0000", // Red color for the selected marker
                  transition: "all 0.3s ease",
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                  },
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#3f51b5",
                  transition: "all 0.3s ease",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#bdbdbd",
                  transition: "all 0.3s ease",
                },
                "& .MuiSlider-markLabel": {
                  color: "#000",
                },
                "& .MuiSlider-mark": {
                  backgroundColor: (theme) => {
                    if (
                      currentChapter === 1 ||
                      currentChapter === totalChapters
                    ) {
                      return "#4caf50";
                    }
                    if (
                      currentChapter === 1 ||
                      currentChapter === totalChapters ||
                      currentChapter === totalChapters
                    ) {
                      return "#ff0000"; // Red color for the selected chapter
                    }
                    return "#3f51b5";
                  },
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  transition: "all 0.3s ease",
                },
              }}
            />
          </Box>

          {chapterContent && (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2>Chapter {selectedChapter}</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#f0f0f0",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h2
                  style={{
                    color: "#333",
                    fontFamily: "'Times New Roman', Times, serif",
                    marginBottom: "10px",
                  }}
                >
                  {chapterContent.name}
                </h2>
                <p
                  style={{
                    color: "#666",
                    fontFamily: "'Times New Roman', Times, serif",
                    fontSize: "16px",
                  }}
                >
                  {chapterContent.description}
                </p>
              </div>
              <CourseTabs
                chapterContent={chapterContent}
                quizFileData={quizFileData}
                isDemoCourse={courseData.is_demo_course}
              />
            </div>
          )}
        </div>
      )}
      {!isUserLanguageSelected && courseData.type === "language" && (
        <LanguageSelector
          userLanguage={userLanguage}
          setUserLanguage={setUserLanguage}
          courseLanguage={courseData?.name.split(" ")[1].toLowerCase()}
          setIsUserLanguageSelected={setIsUserLanguageSelected}
        />
      )}
    </>
  );
}

export default CourseDetails;
