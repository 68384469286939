import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from '@mui/material';
import { getBaseUrl } from '../../Utils/getBaseUrl';
import Spinner from '../../Spinner';
import ProfilePictureUpload from './ProfilePictureUpload';
import ProfileField from './ProfileField';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TeacherProfileData = () => {
  const [profile, setProfile] = useState({
    email: '',
    interests: '',
    experience: '',
    tagline: '',
    rate_per_half_hour: '',
    picture_url: '',
    zoom_link: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isRateValid, setIsRateValid] = useState(false); // Initially set to false
  const [isPictureValid, setIsPictureValid] = useState(true);
  const [pictureFile, setPictureFile] = useState(null); // Store the picture file
  const userEmail = localStorage.getItem("email") || "";

  useEffect(() => {
    const fetchTeacherProfile = async () => {
      try {
        if (!userEmail) {
          console.log('User email not found');
          return;
        }

        const baseUrl = getBaseUrl();
        const dataRequest = { email: userEmail };

        const response = await fetch(`${baseUrl}/api/get-teacher-profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (response.status === 500) {
          console.log('Server error');
          return;
        }

        const data = await response.json();
        if (data.success) {
          setProfile(data.profile);
          validateRate(data.profile.rate_per_half_hour);
        } else {
          setProfile({
            email: userEmail,
            interests: '',
            experience: '',
            tagline: '',
            rate_per_half_hour: '',
            picture_url: '',
            zoom_link: ''
          });
          console.log('Teacher profile not found');
        }
      } catch (error) {
        console.log('Unable to fetch teacher profile');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeacherProfile();
  }, [userEmail]);

  const validateRate = (rate) => {
    const rateValue = parseFloat(rate);
    setIsRateValid(!isNaN(rateValue) && rateValue >= 0 && rateValue <= 1000 && rate.toString().trim() !== '');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
    setIsChanged(true);

    if (name === 'rate_per_half_hour') {
      validateRate(value);
    }
  };

  const handlePictureUpload = (file) => {
    if (!file.type.startsWith('image/')) {
      setIsPictureValid(false);
      console.log('File is not an image');
      return;
    }

    if (file.size > 2.5 * 1024 * 1024) {
      setIsPictureValid(false);
      console.log('File size exceeds 2.5 MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setProfile(prevProfile => ({
        ...prevProfile,
        picture_url: reader.result
      }));
      setPictureFile(file); // Store the picture file
      setIsChanged(true);
      setIsPictureValid(true);
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    if (!isRateValid) {
      console.log('Rate per Half Hour must be a valid number between 0 and 1000');
      return;
    }

    if (!isPictureValid) {
      console.log('Picture is not valid');
      return;
    }

    if (!profile.zoom_link || !profile.zoom_link.trim()) {
      console.log('Video link is required');
      return;
    }

    try {
      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      formDataToSend.append('email', profile.email);
      formDataToSend.append('interests', profile.interests);
      formDataToSend.append('experience', profile.experience);
      formDataToSend.append('tagline', profile.tagline);
      formDataToSend.append('rate_per_half_hour', profile.rate_per_half_hour);
      formDataToSend.append('zoom_link', profile.zoom_link);
      if (pictureFile) {
        formDataToSend.append('picture', pictureFile); // Append the picture file
      }

      const response = await fetch(`${baseUrl}/api/update-teacher-profile`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        toast.success('Profile updated successfully');
        setIsChanged(false);
      } else {
        toast.error('Failed to update profile');
      }
    } catch (error) {
      toast.error('Failed to update profile');
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <ProfilePictureUpload
            pictureUrl={profile.picture_url}
            handlePictureUpload={handlePictureUpload}
            isPictureValid={isPictureValid}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ProfileField
            label="Interests"
            name="interests"
            value={profile.interests}
            onChange={handleInputChange}
            tooltip="Put your interests like hobbies"
            multiline
            rows={4}
          />
          <ProfileField
            label="Experience"
            name="experience"
            value={profile.experience}
            onChange={handleInputChange}
            tooltip="Example: 5 years of teaching experience"
            multiline
            rows={4}
          />
          <ProfileField
            label="Tagline"
            name="tagline"
            value={profile.tagline}
            onChange={handleInputChange}
            tooltip="Example: Passionate about teaching math"
          />
          <ProfileField
            label="Rate per Half Hour *"
            name="rate_per_half_hour"
            value={profile.rate_per_half_hour}
            onChange={handleInputChange}
            tooltip="Example: 50 (must be between 0 and 1000)"
            type="number"
            error={!isRateValid}
            helperText={!isRateValid ? 'Rate must be a valid number between 0 and 1000' : ''}
            inputProps={{ min: 0, max: 1000 }}
          />
          <ProfileField
            label="Video Meeting Link like Zoom or Google Meet*"
            name="zoom_link"
            value={profile.zoom_link}
            onChange={handleInputChange}
            tooltip="Enter your Zoom meeting link"
            required
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isChanged || !isRateValid || !isPictureValid || !profile.zoom_link || !profile.zoom_link.trim()}
            sx={{ marginTop: 2 }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherProfileData;