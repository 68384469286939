import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { ArcherContainer, ArcherElement } from 'react-archer';

const useStyles = makeStyles({
    card: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px', // Reduced padding
        width: '90vw',
        minHeight: '300px',
        maxHeight: props => (props.isDesktopOrLarger ? '2200px' : '3000px'),
        backgroundColor: '#f9f9f9',
        color: 'black',
        overflowY: 'auto',
        textAlign: 'center',
        borderRadius: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between', // Increase space between columns
        alignItems: 'center', // Center vertically
        width: '100%',
        maxWidth: '1200px',
        position: 'relative',
        flexWrap: 'wrap', // Allow wrapping on smaller screens
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '30%',
        padding: '20px',
        border: '4px solid #4caf50',
        borderRadius: '20px',
        backgroundColor: '#e8f5e9',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        marginBottom: '20px', // Add margin for spacing
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '45%',
        minWidth: '300px', // Ensure minimum width
        padding: '20px',
    },
    option: {
        margin: '10px',
        padding: '10px',
        border: '3px solid #2196f3',
        borderRadius: '15px',
        cursor: 'pointer',
        transition: 'border-color 0.3s, opacity 0.3s, transform 0.3s',
        '&:hover': {
            opacity: 0.7,
            borderColor: '#1976d2',
            transform: 'scale(1.05)',
        },
        boxSizing: 'border-box',
        backgroundColor: '#bbdefb',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal', // Allow text to wrap
        wordWrap: 'break-word', // Break long words
    },
    icon: {
        padding: '10px',
        cursor: 'pointer',
        transition: 'color 0.3s',
        '&:hover': {
            color: '#1976d2',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
    },
    optionText: {
        marginLeft: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal', // Allow text to wrap
        wordWrap: 'break-word', // Break long words
        '& img': {
            maxWidth: '100%',
            maxHeight: '150px',
            objectFit: 'contain',
        },
    },
    selectedOption: {
        borderColor: '#4caf50',
        backgroundColor: '#c8e6c9',
    },
    archerContainer: {
        zIndex: 20, // Ensure the lines are on top
    },
    archerElement: {
        zIndex: 20, // Ensure the elements are on top
    },
});

const MatchQuestionCard = ({
    question,
    quizData,
    currentQuestion,
    shuffledOptions,
    selectedOption,
    handleSpeak,
    handleOptionChange,
    isDesktopOrLarger,
    course_language
}) => {
    const classes = useStyles({ isDesktopOrLarger });

    return (
        <Card className={classes.card}>
            <CardContent>
                <ArcherContainer strokeColor="black" strokeWidth={4} className={classes.archerContainer}>
                    <div className={classes.container}>
                        <ArcherElement
                            id="question"
                            relations={
                                selectedOption !== null
                                    ? [
                                        {
                                            targetId: `option-${shuffledOptions.findIndex(option => option.text === selectedOption)}`,
                                            targetAnchor: 'left',
                                            sourceAnchor: 'right',
                                        },
                                    ]
                                    : []
                            }
                            className={classes.archerElement}
                        >
                            <div className={classes.questionContainer}>
                                <FontAwesomeIcon
                                    icon={faVolumeUp}
                                    size="1x"
                                    className={classes.icon}
                                    onClick={() =>
                                        handleSpeak(
                                            quizData[currentQuestion]?.question,
                                            true,
                                            quizData[currentQuestion]?.question_language,
                                            quizData,
                                            currentQuestion,
                                            course_language
                                        )
                                    }
                                />
                                <p
                                    id="question-0"
                                    dangerouslySetInnerHTML={{ __html: question }}
                                ></p>
                            </div>
                        </ArcherElement>
                        <div className={classes.optionsContainer}>
                            {shuffledOptions.map((option, index) => (
                                <ArcherElement key={index} id={`option-${index}`} className={classes.archerElement}>
                                    <div
                                        id={`option-${index}`}
                                        className={`${classes.option} ${selectedOption === option.text ? classes.selectedOption : ''}`}
                                        onClick={() => handleOptionChange(option)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faVolumeUp}
                                            size="1x"
                                            className={classes.icon}
                                            onClick={() => handleSpeak(option.text, false, option.language, quizData, currentQuestion, course_language)}
                                        />
                                        <label htmlFor={`option-${index}`} className={classes.label}>
                                            <input
                                                type="radio"
                                                id={`option-${index}`}
                                                name="options"
                                                value={option.text}
                                                checked={selectedOption === option.text}
                                                onChange={() => handleOptionChange(option)}
                                                style={{ display: 'none' }}
                                            />
                                            <span className={classes.optionText} dangerouslySetInnerHTML={{ __html: option.text }}></span>
                                        </label>
                                    </div>
                                </ArcherElement>
                            ))}
                        </div>
                    </div>
                </ArcherContainer>
            </CardContent>
        </Card>
    );
};

export default MatchQuestionCard;