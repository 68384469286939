import { languages } from "../languages";
const apiKey = "AIzaSyCIBPx5Ww6q7Cbuqca6EYoZmTTLeYiRJU4";

export const shuffleOptions = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const handleSpeak = async (text, isQuestion, textLang, quizData, currentQuestion, course_language) => {
  const utterance = new SpeechSynthesisUtterance();
  utterance.text = text;

  if (textLang === "urdu" || textLang === "french" || textLang === "bangla") {
    await handleSpeakUrdu(text, textLang);
    return;
  } else if (quizData[currentQuestion]?.course_type === "language") {
    const userLanguage = localStorage.getItem("user-language") || "en";
    const courseLang = languages[course_language] || languages["en"];
    const userLang = languages[userLanguage] || languages["en"];
    utterance.lang = isQuestion ? courseLang : userLang;
  } else {
    utterance.lang = languages[textLang] || languages["english"];  
  }

  window.speechSynthesis.speak(utterance);
};

const handleSpeakUrdu = async (text, textLang) => {
  const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
  const data = {
    input: { text },
    voice: { languageCode: languages[textLang] || languages["english"] },
    audioConfig: { audioEncoding: "MP3" },
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const audioContent = result.audioContent;
    const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
    audio.play();
  } catch (error) {
    console.error("Error with Google Text-to-Speech API:", error);
  }
};