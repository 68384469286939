import React from "react";
import { TextField, Tooltip } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

function CourseFormFields({
  formData,
  handleChange,
  handleDescriptionChange,
  errors,
  refs,
}) {
  return (
    <div className="course-form-fields">
      <Tooltip title="Enter the name of the course" arrow>
        <div className="field-container">
          <TextField
            label="Course Name *"
            name="courseName"
            placeholder="Course Name"
            value={formData.courseName}
            onChange={handleChange}
            className="textFieldCreateCourse"
            style={{ marginBottom: "20px" }}
            inputRef={refs.courseNameRef}
          />
          {errors.courseName && (
            <span className="error">{errors.courseName}</span>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Enter a detailed description of the course" arrow>
        <div className="field-container">
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            Course Description *
          </label>
          <ReactQuill
            value={formData.courseDescription}
            onChange={handleDescriptionChange}
            ref={refs.courseDescriptionRef}
          />
          {errors.courseDescription && (
            <span className="error">{errors.courseDescription}</span>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Enter the timing of the course" arrow>
        <div className="field-container">
          <TextField
            label="Timing *"
            name="timing"
            placeholder="Timing - e.g., 5:00 PM - 6:00 PM PST (Monday - Friday). Put NA if not applicable."
            value={formData.timing}
            onChange={handleChange}
            className="textFieldCreateCourse"
            style={{ marginBottom: "20px" }}
            inputRef={refs.timingRef}
          />
          {errors.timing && <span className="error">{errors.timing}</span>}
        </div>
      </Tooltip>
      <Tooltip title="Enter the name of the instructor" arrow>
        <div className="field-container">
          <TextField
            label="Instructor *"
            name="instructor"
            placeholder="Instructor Name like John Doe"
            value={formData.instructor}
            onChange={handleChange}
            className="textFieldCreateCourse"
            style={{ marginBottom: "20px" }}
            inputRef={refs.instructorRef}
          />
          {errors.instructor && (
            <span className="error">{errors.instructor}</span>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Enter the class meeting link (e.g., Zoom or Google Meet)"
        arrow
      >
        <div className="field-container">
          <TextField
            label="Class Meeting Link (e.g., Zoom or Google Meet) *"
            name="zoomLink"
            placeholder="Class Meeting Link like Zoom or Google Meet"
            value={formData.zoomLink}
            onChange={handleChange}
            className="textFieldCreateCourse"
            style={{ marginBottom: "20px" }}
            inputRef={refs.zoomLinkRef}
          />
          {errors.zoomLink && <span className="error">{errors.zoomLink}</span>}
        </div>
      </Tooltip>
      <Tooltip title="Enter the monthly cost of the course" arrow>
        <div className="field-container">
          <TextField
            label="Monthly Cost USD($) *"
            name="monthlyCost"
            placeholder="Monthly Cost USD($) - e.g., 30. Put 0 if free."
            type="number"
            value={formData.monthlyCost}
            onChange={handleChange}
            className="textFieldCreateCourse"
            inputProps={{ min: 0, max: 1000 }}
            style={{ marginBottom: "20px" }}
            inputRef={refs.monthlyCostRef}
          />
          {errors.monthlyCost && (
            <span className="error">{errors.monthlyCost}</span>
          )}
        </div>
      </Tooltip>
      <Tooltip title="Enter the promotional video link for the course" arrow>
        <div className="field-container">
          <TextField
            label="Promotional Youtube Video Link"
            name="courseVideoLink"
            placeholder="Promotional Video Link - e.g., YouTube link. Only YouTube videos are supported."
            value={formData.courseVideoLink}
            onChange={handleChange}
            className="textFieldCreateCourse"
            style={{ marginBottom: "20px" }}
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default CourseFormFields;
