import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Button } from "@mui/material";
import { withAlert } from "react-alert";
import "./style.css";
import CourseFormFields from "./CourseFormFields";
import CourseCategories from "./CourseCategories";
import FileInput from "../CommonComponents/FileInput/FileInput";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateNewCourseDetails(props) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    courseName: "",
    courseDescription: "",
    categories: {
      kids: false,
      programming: false,
      languages: false,
      certifications: false,
      live: true,
    },
    images: [null, null, null],
    timing: "",
    instructor: "",
    zoomLink: "",
    monthlyCost: "",
    courseVideoLink: "", // New field for course video link
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const courseNameRef = useRef(null);
  const courseDescriptionRef = useRef(null);
  const categoriesRef = useRef(null);
  const imagesRef = useRef(null);
  const timingRef = useRef(null);
  const instructorRef = useRef(null);
  const zoomLinkRef = useRef(null);
  const monthlyCostRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, courseDescription: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      categories: { ...formData.categories, [name]: checked },
    });
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const newImages = [...formData.images];
    const newErrors = { ...errors };
  
    if (file && file.size <= 1.8 * 1024 * 1024) {
      // 1.8 MB limit
      newImages[index] = file;
      setFormData({ ...formData, images: newImages });
  
      // Clear the specific image error
      if (newErrors.images) {
        newErrors.images[index] = null;
        if (Object.values(newErrors.images).every(error => error === null)) {
          delete newErrors.images;
        }
      }
    } else {
      if (!newErrors.images) {
        newErrors.images = {};
      }
      newErrors.images[index] = "Each image must be less than 1.8MB";
    }
    setErrors(newErrors);
  };

  const handleSubmit = async () => {
    setErrors({});
    const errors = {};

    if (!formData.courseName.trim()) {
      errors.courseName = "Course name is required";
    } else if (formData.courseName.length > 100) {
      errors.courseName = "Course name cannot be more than 100 characters";
    }

    if (!formData.courseDescription.trim()) {
      errors.courseDescription = "Course description is required";
    } else if (formData.courseDescription.length > 5000) {
      errors.courseDescription =
        "Course description cannot be more than 5000 characters";
    }

    const selectedCategories = Object.keys(formData.categories).filter(
      (category) => formData.categories[category]
    );
    if (selectedCategories.length === 0) {
      errors.categories = "At least one category must be selected";
    }

    if (formData.images.every((image) => image === null)) {
      errors.images = "At least one image is required";
    }

    if (!formData.timing.trim()) {
      errors.timing = "Timing is required";
    }

    if (!formData.instructor.trim()) {
      errors.instructor = "Instructor is required";
    }

    if (!formData.zoomLink.trim()) {
      errors.zoomLink = "Zoom link is required";
    }

    if (!formData.monthlyCost.trim()) {
      errors.monthlyCost = "Monthly cost is required";
    } else if (
      isNaN(formData.monthlyCost) ||
      formData.monthlyCost < 0 ||
      formData.monthlyCost > 1000
    ) {
      errors.monthlyCost = "Monthly cost must be between 0 and 1000";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      const firstErrorField = Object.keys(errors)[0];
      switch (firstErrorField) {
        case 'courseName':
          courseNameRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'courseDescription':
          courseDescriptionRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'categories':
          categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'images':
          imagesRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'timing':
          timingRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'instructor':
          instructorRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'zoomLink':
          zoomLinkRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'monthlyCost':
          monthlyCostRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          break;
      }
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      formDataToSend.append("course_name", formData.courseName);
      formDataToSend.append("course_description", formData.courseDescription);
      selectedCategories.push("courses"); // Add 'courses' category
      formDataToSend.append("categories", JSON.stringify(selectedCategories));
      formData.images.forEach((image, index) => {
        if (image) {
          formDataToSend.append(`image_${index}`, image);
        }
      });
      formDataToSend.append(
        "meetingDetails",
        JSON.stringify({
          timing: formData.timing,
          instructor: formData.instructor,
          zoomLink: formData.zoomLink,
        })
      );
      formDataToSend.append("monthly_cost", formData.monthlyCost);
      formDataToSend.append("course_video_link", formData.courseVideoLink); // Append course video link
      formDataToSend.append("user_email", localStorage.getItem("email")); // Append user email

      const response = await fetch(`${baseUrl}/api/create-course`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to create course");
      }
      toast.success('Course created successfully');
      setErrorMessage("Course created successfully.");
      navigate("/MyAccount/Teaching");
    } catch (error) {
      toast.error('Failed to create course. Please try again later.');
      // setErrorMessage("Failed to create course. Please try again later.");
      console.error("Error creating course:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="form-container">
          <h1 className="header">Create New Course</h1>
          <CourseFormFields
            formData={formData}
            handleChange={handleChange}
            handleDescriptionChange={handleDescriptionChange}
            errors={errors}
            refs={{
              courseNameRef,
              courseDescriptionRef,
              categoriesRef,
              imagesRef,
              timingRef,
              instructorRef,
              zoomLinkRef,
              monthlyCostRef,
            }}
          />
          <CourseCategories
            formData={formData}
            handleCheckboxChange={handleCheckboxChange}
            errors={errors}
            refs={{ categoriesRef }}
          />
          <FileInput
            formData={formData}
            handleFileChange={handleFileChange}
            errors={errors}
            setErrors={setErrors}
            refs={{ imagesRef }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className="submitButton"
            >
              Create Course
            </Button>
          </div>
          {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
}

export default withAlert()(CreateNewCourseDetails);