import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import TextIcon from "@mui/icons-material/Description";
import StudyIcon from "@mui/icons-material/School";
import QuizIcon from "@mui/icons-material/Quiz";
import KahootIcon from "@mui/icons-material/EmojiEvents";
import BookmarkStudyIcon from "@mui/icons-material/Bookmark";
import BookmarkQuizIcon from "@mui/icons-material/BookmarkBorder";
import ResultsIcon from "@mui/icons-material/Assessment";
import MessagesIcon from "@mui/icons-material/Message";
import DrawingIcon from "@mui/icons-material/Brush";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import { Box } from "@mui/material";

import Quiz from "../Quiz/Quiz";
import Flashcard from "../Flashcard";
import Results from "../Results";
import Messages from "../Messages/Messages";
import Drawing from "../Drawing/Drawing";
import Kahoot from "../Kahoot/Kahoot";

import { getBaseUrl } from "../../Utils/getBaseUrl";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import "./style.css";
import ConfirmationDialog from "../ConfirmationDialog";

const CourseTabs = ({ chapterContent, quizFileData, isDemoCourse }) => {
  const containerRef = useRef();

  const [fileType, setFileType] = useState("");
  const [pdfData, setPdfData] = useState(null);
  const [textData, setTextData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [quizData, setQuizData] = useState(quizFileData);
  const [containerWidth, setContainerWidth] = useState(175);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(
          containerRef.current.getBoundingClientRect().width - 200
        ); // Subtract 200 for extra space
      }
    };

    // Initial width calculation when component mounts
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getBookUrl = async () => {
      const baseUrl = getBaseUrl();
      try {
        const response = await fetch(`${baseUrl}/api/book-url`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            pdfFileName: chapterContent?.isFileCourse
              ? chapterContent.study_text_link
              : `chapters/${chapterContent.course_id}_${chapterContent.chapter_number}.pdf`,
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          fetch(responseData.presignedUrl)
            .then((response) => response.blob())
            .then((blob) => {
              if (blob.type === "application/pdf") {
                setFileType("pdf");
                setPdfData(blob);
              } else if (blob.type === "text/plain") {
                setFileType("txt");
                setPdfData(blob);
                const reader = new FileReader();
                reader.onload = (e) => {
                  setTextData(e.target.result);
                };
                reader.readAsText(blob);
              }
            });
        }
      } catch (error) {
        console.error("Error fetching book URL:", error);
      }
    };

    const getQuizContent = async () => {
      const baseUrl = getBaseUrl();
      if (!quizFileData) {
        try {
          const dataRequest = {
            course_id: chapterContent.course_id,
            chapter_id: chapterContent.chapter_number,
            user_email: localStorage.getItem("email"),
          };
          const response = await fetch(`${baseUrl}/api/quiz-question`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataRequest),
          });
          const data = await response.json();
          setQuizData(data);
        } catch (error) {
          console.error("Error getting Quiz Questions:", error);
        }
      } else if (quizFileData) {
        setQuizData(quizFileData);
      }
    };

    if (chapterContent.study_text_link) {
      getBookUrl();
    }
    getQuizContent();
    setSelectedTab(0);
  }, [chapterContent, quizFileData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage =
        "All the session data will be lost. Are you sure you want to proceed?";
      event.preventDefault();
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleTabChange = (event, newTab) => {
    setDialogOpen(true);
    setPendingAction(() => () => {
      setSelectedTab(newTab);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setPendingAction(null);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    if (pendingAction) {
      pendingAction();
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return chapterContent ? (
    <div ref={containerRef}>
      <Box display="flex" justifyContent="center">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          scrollButtons="auto"
          variant="scrollable"
        >
          {chapterContent.video_link && (
            <Tab icon={<VideoIcon sx={{ color: "red" }} />} label="Video" />
          )}
          {chapterContent.study_text_link && (
            <Tab icon={<TextIcon sx={{ color: "red" }} />} label="Text" />
          )}
          <Tab icon={<StudyIcon sx={{ color: "red" }} />} label="Study" />
          <Tab icon={<QuizIcon sx={{ color: "red" }} />} label="Quiz" />
          <Tab icon={<KahootIcon sx={{ color: "red" }} />} label="Kahoot" />
          <Tab
            icon={<BookmarkStudyIcon sx={{ color: "red" }} />}
            label="Bookmarked Study"
          />
          <Tab
            icon={<BookmarkQuizIcon sx={{ color: "red" }} />}
            label="Bookmarked Quiz"
          />
          <Tab icon={<ResultsIcon sx={{ color: "red" }} />} label="Results" />
          <Tab icon={<MessagesIcon sx={{ color: "red" }} />} label="Messages" />
          <Tab icon={<DrawingIcon sx={{ color: "red" }} />} label="Drawing" />
        </Tabs>
      </Box>
      {selectedTab === 0 && chapterContent.video_link && (
        <div
          style={{
            position: "relative",
            width: "90vw",
            paddingTop: "56.25%", // for 16:9 aspect ratio
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "0", // This is important to include
            margin: "0 auto", // Center the container horizontally
          }}
        >
          <iframe
            src={chapterContent.video_link}
            frameBorder="0"
            allowFullScreen
            title="Chapter Video"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
      {(chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 1) ||
      (!chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 0) ? (
        pdfData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              {fileType === "pdf" && (
                <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      width={containerWidth}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                    />
                  ))}
                </Document>
              )}
              {fileType === "txt" && <pre>{textData}</pre>}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={() => {
                  setContainerWidth((prevWidth) => prevWidth + 200);
                }}
                aria-label="Zoom In"
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setContainerWidth((prevWidth) => prevWidth - 200);
                }}
                aria-label="Zoom Out"
              >
                <ZoomOutIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <p>Loading document...</p>
        )
      ) : null}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 2) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 1) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 1) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 0)) &&
        quizData &&
        chapterContent && (
          <Flashcard quizData={quizData} chapterContent={chapterContent} isDemoCourse={isDemoCourse}/>
        )}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 3) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 2) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 2) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 1)) &&
        quizData &&
        chapterContent && (
          <Quiz
            quizData={quizData}
            chapterContent={chapterContent}
            isBookmarkedTab={false}
            isDemoCourse={isDemoCourse}
          />
        )}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 4) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 3) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 3) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 2)) &&
        chapterContent && (
          <Kahoot
            quizData={quizData}
            chapterContent={chapterContent}
            courseId={chapterContent?.course_id}
            chapterNumber={chapterContent?.chapter_number}
            isDemoCourse={isDemoCourse}
          />
        )}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 5) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 4) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 4) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 3)) &&
        quizData &&
        chapterContent && (
          <Flashcard
            quizData={quizData.filter((question) => question.bookmarked === 1)}
            chapterContent={chapterContent}
          />
        )}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 6) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 5) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 5) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 4)) &&
        quizData &&
        chapterContent && (
          <Quiz
            quizData={quizData.filter((question) => question.bookmarked === 1)}
            chapterContent={chapterContent}
            isBookmarkedTab={true}
          />
        )}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 7) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 6) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 6) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 5)) &&
        chapterContent && <Results chapterContent={chapterContent} />}

      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 8) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 7) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 7) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 6)) &&
        chapterContent && (
          <Messages
            courseId={chapterContent?.course_id}
            chapterNumber={chapterContent?.chapter_number}
            isDemoCourse={isDemoCourse}
          />
        )}
      {((chapterContent.video_link &&
        chapterContent.study_text_link &&
        selectedTab === 9) ||
        (chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 8) ||
        (!chapterContent.video_link &&
          chapterContent.study_text_link &&
          selectedTab === 8) ||
        (!chapterContent.video_link &&
          !chapterContent.study_text_link &&
          selectedTab === 7)) &&
        chapterContent && (
          <Drawing
            courseId={chapterContent?.course_id}
            chapterNumber={chapterContent?.chapter_number}
            isDemoCourse={isDemoCourse}
          />
        )}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </div>
  ) : null;
};

export default CourseTabs;
