import React from 'react';
import { Card, CardContent, Typography, Avatar, IconButton, Button } from '@mui/material';
import { Star, StarBorder, Delete as DeleteIcon } from '@mui/icons-material';

const StudentDetails = ({ selectedStudent, stars, incrementStars, decrementStars, setTimeoutForStudent, removeStudent, showRewardAnimation }) => {
  const renderStars = (count) => {
    const starElements = [];
    for (let i = 0; i < 5; i++) {
      starElements.push(
        i < count ? (
          <Star key={i} style={{ color: 'gold', cursor: 'pointer' }} onClick={() => decrementStars(selectedStudent)} />
        ) : (
          <StarBorder key={i} style={{ cursor: 'pointer' }} onClick={() => incrementStars(selectedStudent)} />
        )
      );
    }
    return starElements;
  };

  return (
    <Card style={{ marginBottom: '20px', backgroundColor: '#e8f5e9' }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Avatar src={selectedStudent.avatar} alt={selectedStudent.name} style={{ marginRight: '10px' }} />
          <Typography variant="h5" gutterBottom style={{ fontFamily: 'Times New Roman', marginRight: '10px' }}>
            {selectedStudent.name}
          </Typography>
          <div style={{ display: 'flex', marginRight: '10px' }}>
            {renderStars(stars[selectedStudent.name] || 0)}
          </div>
          {showRewardAnimation && <span className="reward-animation" style={{ marginRight: '10px' }}>🎉</span>}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setTimeoutForStudent(selectedStudent)}
            style={{ marginRight: '10px' }}
          >
            Timeout
          </Button>
          <IconButton color="error" onClick={() => removeStudent(selectedStudent.name)} style={{ marginRight: '10px' }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  );
};

export default StudentDetails;