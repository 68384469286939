import React, { useState, useEffect } from 'react';
import './style.css'; // Assuming the CSS file is in the same directory
import { Link, Element } from 'react-scroll';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = process.env.REACT_APP_NOT_SECRET_CODE ?
  loadStripe('pk_test_4hmpKDMcjCeCwm8cvzr4ZYrv') :
  loadStripe('pk_live_zed2dWneRMXvc1gfUmwFU99t');

let elementsInstance;
const BuyMembership = () => {

  const isMobile = window.innerWidth <= 600;

  const [planType, setPlanType] = useState('Yearly');
  const [currentPlanPrice, setCurrentPlanPrice] = useState(99.99);

  const handlePlanChange = (event) => {
    setPlanType(event.target.value);
    if (event.target.value === 'Monthly') {
      setCurrentPlanPrice(9.99);
    } else {
      setCurrentPlanPrice(99.99);
    }
  };

  useEffect(() => {
    async function initStripe() {
      const stripe = await stripePromise;
      elementsInstance = stripe.elements();

      if (elementsInstance) {
        elementsInstance.on('ready', () => {
        });
      }
    }

    initStripe();
  }, [stripePromise]);

  return (
    <div className="prodigy-math">
      <div className="hero">
        <h1>Get Exclusive Benefits: Become a Member</h1>
        <h2>Unlock Premium Content, Courses, and More Today!</h2>
        <p>Welcome to our exclusive membership community! By becoming a member today, you'll unlock a world of premium content, courses, and more, all designed to supercharge your learning journey. Gain access to our extensive library of books, enriching courses, engaging quizzes, insightful lectures, and interactive flashcards. Whether you're looking to expand your knowledge, sharpen your skills, or explore new interests, our membership provides you with the tools and resources you need to thrive. With unlimited access to all the books and self-paced courses, you'll have the freedom to learn at your own pace, on your own terms. Join us today and embark on a journey of discovery and growth!</p>
        <Link style={{ padding: "10px", color: "white", cursor: "pointer", margin: "20px" }} to="membership-payment" spy={true} smooth={true} offset={50} duration={500}>
          <button>Buy a Membership</button>
        </Link>
      </div>
      <div className="stats" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '0 auto' : 'initial' }}>
        <div className="savings">
          <h2>Plan Details</h2>
          <div className="choose-plan-button">
            <span>Choose your plan</span>
            <div className="billing-options">
              <label className={planType === 'Monthly' ? 'selected' : ''}>
                <input type="radio" value="Monthly" checked={planType === 'Monthly'} onChange={handlePlanChange} />
                <span style={{backgroundColor: planType === 'Monthly' ? 'orange' : ''}}>Monthly</span>
              </label>
              <label className={`yearly-option ${planType === 'Yearly' ? 'selected' : ''}`}>
                <input type="radio" value="Yearly" checked={planType === 'Yearly'} onChange={handlePlanChange} />
                <span style={{backgroundColor: planType === 'Yearly' ? 'orange' : ''}}>Yearly</span>
                <span className="more-savings">More savings</span>
              </label>
            </div>
          </div>
          <div className="plan-container">
            <div className="plan">
              <h3>Gold Membership</h3>
              <del>{planType === 'Yearly' && '$9.99/mo'}</del>
              <p>{planType === 'Yearly' ? '$8.33/mo' : '$9.99/mo'}</p>
              <p>{planType === 'Yearly' && 'You save $20 (Billed annually $99.99)'}</p>
              <p>{planType === 'Yearly' ? 'Welcome to our Gold Yearly Membership plan! For only $8.33 per month, billed annually at $99.99, you\'ll enjoy all the benefits of our Gold Membership at an unbeatable value, saving you $20! Gain exclusive access to our comprehensive library of self-paced courses, meticulously crafted to enhance your skills and knowledge across various subjects. Dive into our extensive collection of flashcards and engaging quizzes, designed to reinforce your learning journey throughout the year. Plus, take advantage of a complimentary 7-day free trial to experience the full scope of our membership. With this plan, you\'ll have uninterrupted access to our premium resources, empowering you to learn and grow at your own pace. Join now and unlock a year of unlimited learning opportunities!'
                :  
                'Welcome to our Gold Monthly Membership plan! For just $9.99 per month, gain exclusive access to all our self-paced courses, meticulously crafted to enhance your skills and knowledge across various subjects. Dive into our extensive collection of flashcards and engaging quizzes, designed to reinforce your learning journey. Plus, enjoy a complimentary 7-day free trial to experience the full benefits of our membership before committing. With this plan, you\'ll have the flexibility to enjoy our premium resources at your own pace, month after month. Join now and embark on a journey of continuous growth and discovery!'}</p>
              <Link style={{ color: "white", cursor: "pointer" }} to="membership-payment" spy={true} smooth={true} offset={50} duration={500}>
                <button>Buy Gold Membership</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Element name="membership-payment" className="element">
        <Elements stripe={stripePromise}>
          <CheckoutForm seminar={{ price: currentPlanPrice }} planType={planType} />
        </Elements>
      </Element>
    </div>
  );
}

export default BuyMembership;