import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getBaseUrl } from "../Utils/getBaseUrl";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import AddChapter from './AddChapter';
import EditChapter from './EditChapter';
import DeleteChapter from './DeleteChapter';
import SwapChapters from './SwapChapters';
import './style.css';

const CourseEditDetails = () => {
  const location = useLocation();
  const { courseData, appFileData } = location.state || {};
  const [action, setAction] = useState('add');

  console.log('value of courseData', courseData);
  console.log('value of appFileData', appFileData);


  const getActionLabel = (action) => {
    switch (action) {
      case 'add':
        return 'Add Chapter';
      case 'edit':
        return 'Edit Chapter';
      case 'delete':
        return 'Delete Chapter';
      case 'swap':
        return 'Swap Chapters';
      default:
        return '';
    }
  };

  const renderActionComponent = (action) => {
    switch (action) {
      case 'add':
        return <AddChapter courseData={courseData} appFileData={appFileData} />;
      case 'edit':
        return <EditChapter courseData={courseData} appFileData={appFileData} />;
      case 'delete':
        return <DeleteChapter courseData={courseData} appFileData={appFileData} />;
      case 'swap':
        return <SwapChapters courseData={courseData} appFileData={appFileData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {courseData && <h1 className="course-title">{courseData?.name}</h1>}
      <div className="dropdown-section">
        <FormControl fullWidth>
          <InputLabel id="action-select-label">What do you want to do?</InputLabel>
          <Select
            labelId="action-select-label"
            id="action-select"
            value={action}
            label="What do you want to do?"
            onChange={(e) => setAction(e.target.value)}
          >
            <MenuItem value="add">Add Chapter</MenuItem>
            <MenuItem value="edit">Edit Chapter</MenuItem>
            <MenuItem value="delete">Delete Chapter</MenuItem>
            <MenuItem value="swap">Swap Chapters</MenuItem>
          </Select>
        </FormControl>
      </div>
      <h2 className="selected-action">{getActionLabel(action)}</h2>
      {renderActionComponent(action)}
    </div>
  );
};

export default CourseEditDetails;