import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Mic, Pause, Stop, Download, Delete, PlayArrow } from '@mui/icons-material';

const RecordingControls = ({
  isRecording,
  isPaused,
  startRecording,
  pauseRecording,
  resumeRecording,
  stopRecording,
  downloadRecording,
  deleteRecording,
  audioURL
}) => {
  return (
    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
      <Tooltip title="Start Recording">
        <IconButton
          onClick={startRecording}
          disabled={isRecording}
          style={{
            marginRight: '5px',
            borderRadius: '50%',
            backgroundColor: isRecording ? '#add8e6' : '#1e90ff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.2s',
            padding: '8px',
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <Mic style={{ color: '#fff', fontSize: '20px' }} />
        </IconButton>
      </Tooltip>
      {isPaused ? (
        <Tooltip title="Resume Recording">
          <IconButton
            onClick={resumeRecording}
            style={{
              marginRight: '5px',
              borderRadius: '50%',
              backgroundColor: '#ffb6c1',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s',
              padding: '8px',
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <PlayArrow style={{ color: '#fff', fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Pause Recording">
          <IconButton
            onClick={pauseRecording}
            disabled={!isRecording}
            style={{
              marginRight: '5px',
              borderRadius: '50%',
              backgroundColor: isRecording ? '#ffb6c1' : '#f0f0f0',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s',
              padding: '8px',
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <Pause style={{ color: '#fff', fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Stop Recording">
        <IconButton
          onClick={stopRecording}
          disabled={!isRecording}
          style={{
            marginRight: '5px',
            borderRadius: '50%',
            backgroundColor: isRecording ? '#ff6347' : '#f0f0f0',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.2s',
            padding: '8px',
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <Stop style={{ color: '#fff', fontSize: '20px' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Download Recording">
        <IconButton
          onClick={downloadRecording}
          disabled={!audioURL}
          style={{
            borderRadius: '50%',
            backgroundColor: audioURL ? '#98fb98' : '#f0f0f0',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'transform 0.2s',
            padding: '8px',
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <Download style={{ color: '#fff', fontSize: '20px' }} />
        </IconButton>
      </Tooltip>
      {audioURL && (
        <Tooltip title="Delete Recording">
          <IconButton
            onClick={deleteRecording}
            style={{
              marginLeft: '5px',
              borderRadius: '50%',
              backgroundColor: '#ff6347',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s',
              padding: '8px',
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <Delete style={{ color: '#fff', fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default RecordingControls;