import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles({
    card: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '50px',
        width: '90vw',
        minHeight: '300px',
        maxHeight: props => (props.isDesktopOrLarger ? '2200px' : '3000px'),
        backgroundColor: 'white',
        color: 'black', // Change text color to black for better visibility
        overflowY: 'auto',
        textAlign: 'center',
        borderRadius: '20px', // Add curved border
    },
    questionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        border: '4px solid black',
        borderRadius: '20px',
        position: 'relative',
        width: '100%',
        maxWidth: '600px',
    },
    optionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: '20px',
        width: '100%',
        maxWidth: '600px',
    },
    option: {
        flex: '1 1 45%',
        margin: '10px',
        padding: '10px',
        border: '3px solid white',
        borderRadius: '15px',
        cursor: 'pointer',
        transition: 'border-color 0.3s, opacity 0.3s',
        '&:hover': {
            opacity: 0.7,
        },
        boxSizing: 'border-box',
        backgroundColor: '#f0f0f0', // Light gray background for options
    },
    icon: {
        padding: '10px',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    optionText: {
        marginLeft: '10px',
        '& img': {
            maxWidth: '100%',
            maxHeight: '150px', // Set a maximum height for images
            objectFit: 'contain',
        },
    },
    selectedOption: {
        borderColor: 'green',
    },
});

const McqQuestionCard = ({
    question,
    quizData,
    currentQuestion,
    shuffledOptions,
    selectedOption,
    handleSpeak,
    handleOptionChange,
    isDesktopOrLarger,
    course_language
}) => {
    const classes = useStyles({ isDesktopOrLarger });

    const containsMediaOrHtmlTags = (text) => {
        return /<\/?[a-z][\s\S]*>|(\[attach|audio)\d+\]/i.test(text);
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.questionContainer}>
                    {!containsMediaOrHtmlTags(quizData[currentQuestion]?.question) && (
                        <FontAwesomeIcon
                            icon={faVolumeUp}
                            size="1x"
                            className={classes.icon}
                            onClick={() =>
                                handleSpeak(
                                    quizData[currentQuestion]?.question,
                                    true,
                                    quizData[currentQuestion]?.question_language,
                                    quizData,
                                    currentQuestion,
                                    course_language
                                )
                            }
                        />
                    )}
                    <p dangerouslySetInnerHTML={{ __html: question }}></p>
                </div>
                <div className={classes.optionsContainer}>
                    {shuffledOptions.map((option, index) => (
                        <div
                            key={index}
                            className={`${classes.option} ${selectedOption === option.text ? classes.selectedOption : ''
                                }`}
                            onClick={() => handleOptionChange(option)}
                        >
                            {!containsMediaOrHtmlTags(option.text) && (
                                <FontAwesomeIcon
                                    icon={faVolumeUp}
                                    size="1x"
                                    className={classes.icon}
                                    onClick={() => handleSpeak(option.text, false, option.language, quizData, currentQuestion, course_language)}
                                />
                            )}
                            <label htmlFor={`option-${index}`} className={classes.label}>
                                <input
                                    type="radio"
                                    id={`option-${index}`}
                                    name="options"
                                    value={option.text}
                                    checked={selectedOption === option.text}
                                    onChange={() => handleOptionChange(option)}
                                    style={{ display: 'none' }}
                                />
                                <span className={classes.optionText} dangerouslySetInnerHTML={{ __html: option.text }}></span>
                            </label>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default McqQuestionCard;