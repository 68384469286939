import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const LanguageSelect = ({ value, handleChange, label, disabled }) => (
  <FormControl fullWidth variant="outlined" margin="normal" disabled={disabled}>
    <InputLabel>{label}</InputLabel>
    <Select
      value={value}
      onChange={handleChange}
      label={label}
    >
      <MenuItem value="english">English</MenuItem>
      <MenuItem value="chinese">Chinese</MenuItem>
      <MenuItem value="hindi">Hindi</MenuItem>
      <MenuItem value="spanish">Spanish</MenuItem>
      <MenuItem value="arabic">Arabic</MenuItem>
      <MenuItem value="french">French</MenuItem>
      <MenuItem value="bangla">Bengali</MenuItem>
      <MenuItem value="portuguese">Portuguese</MenuItem>
      <MenuItem value="russian">Russian</MenuItem>
      <MenuItem value="urdu">Urdu</MenuItem>
    </Select>
  </FormControl>
);

export default LanguageSelect;