import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FeedbackPopup = ({ open, onClose, user_email, course_id }) => {
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchFeedback = async () => {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: user_email,
        course_id: course_id,
      };
      const response = await fetch(`${baseUrl}/api/get-user-product-feedback`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.feedback.length > 0) {
          setFeedback(data.feedback[0].feedback);
          setRating(data.feedback[0].rating);
        }
      }
    };

    if (open) {
      fetchFeedback();
    }
  }, [open, user_email, course_id]);

  const handleSubmit = async () => {
    const baseUrl = getBaseUrl();
    const dataRequest = {
      email: user_email,
      course_id: course_id,
      feedback: feedback,
      rating: rating,
    };
    const response = await fetch(
      `${baseUrl}/api/update-user-product-feedback`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );

    if (response.ok) {
      toast.success("Feedback submitted successfully!");
    } else {
      toast.error("Error submitting feedback.");
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Submit Feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="feedback"
          label="Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Rating
          name="rating"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!feedback || rating === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FeedbackPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user_email: PropTypes.string.isRequired,
  course_id: PropTypes.number.isRequired,
};

export default FeedbackPopup;