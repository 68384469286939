import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: '15px',
    padding: '20px',
  },
  dialogTitle: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    textAlign: 'center',
    padding: '10px 0',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  dialogContent: {
    padding: '20px',
    textAlign: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px 20px',
  },
  button: {
    margin: '0 10px',
    padding: '10px 20px',
    borderRadius: '20px',
    textTransform: 'none',
  },
  cancelButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
  confirmButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
}));

const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        Confirm Navigation
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          All the session data will be lost. Are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          className={`${classes.button} ${classes.cancelButton}`}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className={`${classes.button} ${classes.confirmButton}`}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;