import React from "react";
import QuizNavigation from "./QuizNavigation";

const QuizHeader = ({
  userAnswers,
  setCurrentQuestion,
  currentQuestion,
  quizData,
  handlePreviousQuestion,
  handleNextQuestion,
  handleSubmitQuiz,
  handleBookmark,
  disableBookmark,
  quizType,
  setQuizType,
}) => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <QuizNavigation
        userAnswers={userAnswers}
        setCurrentQuestion={setCurrentQuestion}
        currentQuestion={currentQuestion}
        quizData={quizData}
        handlePreviousQuestion={handlePreviousQuestion}
        handleNextQuestion={handleNextQuestion}
        handleSubmitQuiz={handleSubmitQuiz}
        handleBookmark={handleBookmark}
        disableBookmark={disableBookmark}
        quizType={quizType}
        setQuizType={setQuizType}
      />
    </div>
  );
};

export default QuizHeader;