import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Box, Grid, Typography, Chip, Card, CardContent } from '@mui/material';

const TeacherCalendar = ({
  availability = [],
  bookings = [],
  newBookingRequest = [],
  setNewBookingRequest,
  profile,
  totalPrice,
  setTotalPrice,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 0; i < 24 * 2; i++) {
      const hour = Math.floor(i / 2);
      const minutes = i % 2 === 0 ? '00' : '30';
      slots.push(`${hour.toString().padStart(2, '0')}:${minutes}`);
    }
    return slots;
  };

  const isAvailable = (timeSlot) => {
    const selected = new Date(selectedDate);
    selected.setHours(timeSlot.split(':')[0], timeSlot.split(':')[1]);

    const available = availability.some((slot) => {
      const start = new Date(slot.start_time);
      const end = new Date(slot.end_time);
      const repeatType = slot.repeat_type;
      const repeatDays = slot.repeat_days || [];

      if (repeatType === 'daily') {
        return selected.getHours() === start.getHours() && selected.getMinutes() === start.getMinutes();
      } else if (repeatType === 'weekly') {
        return selected.getDay() === start.getDay() && selected.getHours() === start.getHours() && selected.getMinutes() === start.getMinutes();
      } else if (repeatType === 'custom') {
        const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][selected.getDay()];
        return repeatDays.includes(dayOfWeek) && selected.getHours() === start.getHours() && selected.getMinutes() === start.getMinutes();
      } else {
        return selected >= start && selected < end;
      }
    });

    const overlappingBooking = bookings.some((booking) => {
      const start = new Date(booking.start_time);
      const end = new Date(booking.end_time);
      return selected >= start && selected < end;
    });

    return available && !overlappingBooking;
  };

  const handleBooking = (timeSlot) => {
    if (newBookingRequest.length < 6) {
      const selected = new Date(selectedDate);
      selected.setHours(timeSlot.split(':')[0], timeSlot.split(':')[1]);
      setNewBookingRequest([...newBookingRequest, { start: selected }]);
      setTotalPrice(totalPrice + profile.rate_per_half_hour);
    }
  };

  const handleRemoveBooking = (index) => {
    const updatedBookingRequest = [...newBookingRequest];
    updatedBookingRequest.splice(index, 1);
    setNewBookingRequest(updatedBookingRequest);
    setTotalPrice(totalPrice - profile.rate_per_half_hour);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ fontFamily: 'Times New Roman' }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: 'Times New Roman',
          color: 'black',
        }}
      >
        Book one-on-one session with
        <br />
        <span style={{ textDecoration: 'underline', color: '#fe0000' }}>{profile.name}</span>
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </Grid>
      </Grid>
      <Box mt={4} width="100%">
        <Typography variant="h6" align="center" sx={{ fontFamily: 'Times New Roman' }}>
          Available Time Slots
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {availability.length > 0 ? (
            generateTimeSlots().map((timeSlot) => (
              <Grid item key={timeSlot}>
                <Chip
                  label={timeSlot}
                  color={
                    newBookingRequest.some(
                      (booking) =>
                        booking.start.getHours() === parseInt(timeSlot.split(':')[0]) &&
                        booking.start.getMinutes() === parseInt(timeSlot.split(':')[1])
                    )
                      ? 'secondary'
                      : isAvailable(timeSlot)
                      ? 'primary'
                      : 'default'
                  }
                  onClick={() =>
                    isAvailable(timeSlot) &&
                    !newBookingRequest.some(
                      (booking) =>
                        booking.start.getHours() === parseInt(timeSlot.split(':')[0]) &&
                        booking.start.getMinutes() === parseInt(timeSlot.split(':')[1])
                    ) &&
                    handleBooking(timeSlot)
                  }
                  disabled={!isAvailable(timeSlot)}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="body1" align="center" color="textSecondary" sx={{ fontFamily: 'Times New Roman', padding: '16px' }}>
              No availability for the selected date.
            </Typography>
          )}
        </Grid>
      </Box>
      <Box mt={4} width="100%">
        <Card>
          <CardContent>
            <Typography variant="h6" align="center" sx={{ fontFamily: 'Times New Roman' }}>
              Selected Time Slots
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {newBookingRequest.map((booking, index) => (
                <Grid item key={index}>
                  <Chip
                    label={`${booking.start.getHours().toString().padStart(2, '0')}:${booking.start.getMinutes().toString().padStart(2, '0')}`}
                    color="secondary"
                    onDelete={() => handleRemoveBooking(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4} width="100%">
        <Card>
          <CardContent>
            <Typography variant="h6" align="center" sx={{ fontFamily: 'Times New Roman' }}>
              Total Cost: ${totalPrice} USD
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TeacherCalendar;