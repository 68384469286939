import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

const StepItem = ({ step, index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: [0.6, -0.28, 0.735, 0.045] },
    },
    hidden: {
      opacity: 0,
      y: 50,
      transition: { duration: 0.8, ease: [0.6, -0.28, 0.735, 0.045] },
    },
  };

  const gifVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
    hidden: { opacity: 0, y: 50, transition: { duration: 1, ease: "easeOut" } },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "40px",
        flexDirection: {
          xs: "column",
          md: index % 2 === 0 ? "row" : "row-reverse",
        },
        position: "relative",
      }}
    >
      {index % 2 === 0 ? (
        <>
          <Box sx={{ flex: 1, textAlign: { xs: "center", md: "center" } }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Card
                sx={{
                  backgroundColor: "#90ee91",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                  width: isMobile ? "100%" : "70%",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#B71C1C",
                      fontWeight: "600",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    Step {index + 1}: {step.label} {step.icon}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#546E7A", fontFamily: "Times New Roman" }}
                  >
                    {step.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#546E7A",
                      fontFamily: "Times New Roman",
                      marginTop: "10px",
                    }}
                  >
                    {step.moreDescription}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              fontSize: "5.7rem", // 1.9rem * 3
              position: "relative",
            }}
          >
            <StarOutlineIcon sx={{ fontSize: "8rem" }} /> {/* 4rem * 3 */}
            <Box
              component="span"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "1.5rem", // 1rem * 3
                fontWeight: "bold",
                color: "black",
              }}
            >
              {"#" + (index + 1)}
            </Box>
          </Box>
          {!isMobile && (
            <motion.div
              initial="hidden"
              animate={controls}
              variants={gifVariants}
              style={{ flex: 1 }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    backgroundColor: "lightgreen",
                    borderRadius: "10px",
                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                    width: "70%",
                    padding: "10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={step.gif}
                    alt={`${step.label} gif`}
                    sx={{ borderRadius: "10px" }}
                  />
                </Card>
              </div>
            </motion.div>
          )}
        </>
      ) : (
        <>
          {!isMobile && (
            <motion.div
              initial="hidden"
              animate={controls}
              variants={gifVariants}
              style={{ flex: 1 }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    backgroundColor: "lightgreen",
                    borderRadius: "10px",
                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                    width: "70%",
                    padding: "10px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={step.gif}
                    alt={`${step.label} gif`}
                    sx={{ borderRadius: "10px" }}
                  />
                </Card>
              </div>
            </motion.div>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              fontSize: "5.7rem", // 1.9rem * 3
              position: "relative",
            }}
          >
            <StarOutlineIcon sx={{ fontSize: "8rem" }} /> {/* 4rem * 3 */}
            <Box
              component="span"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "1.5rem", // 1rem * 3
                fontWeight: "bold",
                color: "black",
              }}
            >
              {"#" + (index + 1)}
            </Box>
          </Box>
          <Box sx={{ flex: 1, textAlign: { xs: "center", md: "center" } }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Card
                sx={{
                  backgroundColor: "#90ee91",
                  borderRadius: "10px",
                  padding: "20px",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                  width: isMobile ? "100%" : "70%",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#B71C1C",
                      fontWeight: "600",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    Step {index + 1}: {step.label} {step.icon}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#546E7A", fontFamily: "Times New Roman" }}
                  >
                    {step.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#546E7A",
                      fontFamily: "Times New Roman",
                      marginTop: "10px",
                    }}
                  >
                    {step.moreDescription}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
        </>
      )}
    </motion.div>
  );
};

export default StepItem;
