export const languages = {
  'arabic': 'ar-QA',
  'basque': 'en-US',
  'bangla': 'bn',
  'bosnian': 'en-US',
  'bulgarian': 'bg-BG',
  'burmese': 'th-TH',
  'catalan': 'ca-ES',
  'chinese': 'zh-CN',
  'croatian': 'en-US',
  'czech': 'cs-CZ',
  'danish': 'da-DK',
  'dutch': 'nl-BE',
  'english': 'en-US',
  'estonian': 'et-EE',
  'filipino': 'fil-PH',
  'finnish': 'fi-FI',
  'french': 'fr-FR',
  'galician': 'pt-BR',
  'georgian': 'ru-RU',
  'german': 'de-DE',
  'greek': 'el-GR',
  'gujarati': 'gu-IN',
  'hausa': 'en-US',
  'hebrew': 'ja-JP',
  'hindi': 'hi-IN',
  'hungarian': 'hu-HU',
  'icelandic': 'nb-NO',
  'indonesian': 'id-ID',
  'italian': 'it-IT',
  'japanese': 'ja-JP',
  'javanese': 'jv-ID',
  'kannada': 'kn-IN',
  'kazakh': 'ru-RU',
  'khmer': 'km-KH',
  'korean': 'ko-KR',
  'lao': 'th-TH',
  'latvian': 'lv-LV',
  'lithuanian': 'pl-PL',
  'macedonian': 'el-GR',
  'malay': 'ms-MY',
  'malayalam': 'ml-IN',
  'marathi': 'mr-IN',
  'mongolian': 'ru-RU',
  'nepali': 'ne-NP',
  'norwegian': 'nb-NO',
  'pashto': 'ur-PK',
  'persian': 'ur-PK',
  'polish': 'pl-PL',
  'portuguese': 'pt-BR',
  'punjabi': 'pa-IN',
  'romanian': 'ro-RO',
  'russian': 'ru-RU',
  'serbian': 'sr-RS',
  'sinhala': 'si-LK',
  'slovak': 'sk-SK',
  'slovenian': 'sk-SK',
  'spanish': 'es-US',
  'sundanese': 'su-ID',
  'swedish': 'sv-SE',
  'swahili': 'en-US',
  'tamil': 'ta-IN',
  'telugu': 'te-IN',
  'thai': 'th-TH',
  'turkish': 'tr-TR',
  'ukrainian': 'uk-UA',
  'urdu': 'ur-PK',
  'uzbek': 'tr-TR',
  'vietnamese': 'vi-VN',
  'zulu': 'en-US'
  };