import React, { useState, useRef } from 'react';
import { Card, CardContent, IconButton, Grid, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { QuestionField, AnswerField, OptionField } from './FieldComponent';
import LanguageSelect from './LanguageSelect';
import { getBaseUrl } from "../../Utils/getBaseUrl";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";

const QuizCard = ({ quiz, index, handleInputChange, handleDeleteQuestion }) => {
  const [attachmentIndex, setAttachmentIndex] = useState(null);
  const [enabledAttachments, setEnabledAttachments] = useState({
    question: false,
    answer: false,
    option1: false,
    option2: false,
    option3: false,
  });
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(30);
  const [audioField, setAudioField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  if (quiz.deleted) {
    return null;
  }

  const renderAttachmentIcon = (field, value, attachIndex) => {
    const isEnabled = enabledAttachments[field] || value.includes(`[attach${attachIndex + 1}]`);
    return (
      <IconButton
        color="primary"
        onClick={() => handleAttachmentClick(field, attachIndex)}
      >
        <AttachmentIcon />
      </IconButton>
    );
  };

  const handleAttachmentClick = async (field, attachIndex) => {
    setAttachmentIndex(attachIndex);
    setEnabledAttachments((prev) => ({ ...prev, [field]: true }));
    if (!quiz[field].includes(`[attach${attachIndex + 1}]`)) {
      handleFieldChange(field, `${quiz[field]} [attach${attachIndex + 1}]`);
    }
    document.getElementById(`file-input-${index}`).click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        toast.error('File size exceeds 1 MB. Upload not permitted.');
        return;
      }

      if (['image/jpeg', 'image/png'].includes(file.type)) {
        const baseUrl = getBaseUrl();
        const dataRequest = { course_id: quiz.course_id, folder: 'images' };

        setIsLoading(true);
        try {
          const response = await fetch(`${baseUrl}/api/get-presigned-url-for-aws-bucket`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataRequest),
          });

          if (response.ok) {
            const responseData = await response.json();
            const presignedUrl = responseData.bucket_url;

            const uploadResponse = await fetch(presignedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': file.type },
              body: file,
            });

            if (uploadResponse.ok) {
              const fileUrl = presignedUrl.split('?')[0];
              const updatedAttachments = [...quiz.slideAttachments];
              updatedAttachments[attachmentIndex] = fileUrl;
              handleInputChange(index, 'slideAttachments', updatedAttachments);
            } else {
              console.error('Error uploading file:', uploadResponse.statusText);
              toast.error(`Error uploading file: ${uploadResponse.statusText}`);
            }
          } else {
            console.error('Error getting presigned URL:', response.statusText);
            toast.error(`Error getting presigned URL: ${response.statusText}`);
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(`Error: ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error('Invalid file type. Only JPEG and PNG are allowed.');
      }
    }
  };

  const handleFieldChange = (field, value) => {
    if (!quiz.slideAttachments) {
      quiz.slideAttachments = [];
    }
    const match = value.match(/\[attach(\d+)\]/);
    if (match) {
      const attachIndex = parseInt(match[1], 10) - 1;
      quiz.slideAttachments[attachIndex] = '';
    }
    handleInputChange(index, field, value);
  };

  const handleMicClick = (field) => {
    setAudioField(field);
    setRecording(true);
    setRecordingTime(30);
    audioChunksRef.current = [];

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = event => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.start();

        const interval = setInterval(() => {
          setRecordingTime(prev => {
            if (prev === 1) {
              clearInterval(interval);
              stopRecording();
            }
            return prev - 1;
          });
        }, 1000);
      })
      .catch(error => {
        console.error('Error accessing microphone:', error);
        toast.error('Error accessing microphone.');
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        if (audioBlob.size > 1 * 1024 * 1024) {
          toast.error('Audio file size exceeds 1 MB. Upload not permitted.');
          return;
        }

        const baseUrl = getBaseUrl();
        const dataRequest = { course_id: quiz.course_id, folder: 'audios' };

        setIsLoading(true);
        try {
          const response = await fetch(`${baseUrl}/api/get-presigned-url-for-aws-bucket`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataRequest),
          });

          if (response.ok) {
            const responseData = await response.json();
            const presignedUrl = responseData.bucket_url;

            const uploadResponse = await fetch(presignedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': 'audio/webm' },
              body: audioBlob,
            });

            if (uploadResponse.ok) {
              const fileUrl = presignedUrl.split('?')[0];
              const updatedAudioAttachments = [...(quiz.slideAudioAttachments || [])];
              const audioIndex = getAudioIndex(audioField);
              updatedAudioAttachments[audioIndex] = fileUrl;
              handleInputChange(index, 'slideAudioAttachments', updatedAudioAttachments);
              handleFieldChange(audioField, `[audio${audioIndex + 1}]`);
            } else {
              console.error('Error uploading audio:', uploadResponse.statusText);
              toast.error(`Error uploading audio: ${uploadResponse.statusText}`);
            }
          } else {
            console.error('Error getting presigned URL:', response.statusText);
            toast.error(`Error getting presigned URL: ${response.statusText}`);
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(`Error: ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      };
    }
    setRecording(false);
    setAudioField(null);
  };

  const getAudioIndex = (field) => {
    switch (field) {
      case 'question':
        return 0;
      case 'answer':
        return 1;
      case 'option1':
        return 2;
      case 'option2':
        return 3;
      case 'option3':
        return 4;
      default:
        return -1;
    }
  };

  return (
    <Card className="quiz-card">
      <CardContent>
        {isLoading && <Spinner />}
        <QuestionField
          quiz={quiz}
          handleFieldChange={handleFieldChange}
          handleMicClick={handleMicClick}
          renderAttachmentIcon={renderAttachmentIcon}
          recording={audioField === 'question' && recording}
          recordingTime={recordingTime}
          stopRecording={stopRecording}
        />
        <LanguageSelect
          value={quiz.question_language}
          handleChange={(e) => handleInputChange(index, 'question_language', e.target.value)}
          label="Language"
          disabled={recording}
        />
        <AnswerField
          quiz={quiz}
          handleFieldChange={handleFieldChange}
          handleMicClick={handleMicClick}
          renderAttachmentIcon={renderAttachmentIcon}
          recording={audioField === 'answer' && recording}
          recordingTime={recordingTime}
          stopRecording={stopRecording}
        />
        <LanguageSelect
          value={quiz.answer_language}
          handleChange={(e) => handleInputChange(index, 'answer_language', e.target.value)}
          label="Language"
          disabled={recording}
        />
        <OptionField
          quiz={quiz}
          option="option1"
          handleFieldChange={handleFieldChange}
          handleMicClick={handleMicClick}
          renderAttachmentIcon={renderAttachmentIcon}
          index={0}
          recording={audioField === 'option1' && recording}
          recordingTime={recordingTime}
          stopRecording={stopRecording}
        />
        <LanguageSelect
          value={quiz.option1_language}
          handleChange={(e) => handleInputChange(index, 'option1_language', e.target.value)}
          label="Language"
          disabled={recording}
        />
        <OptionField
          quiz={quiz}
          option="option2"
          handleFieldChange={handleFieldChange}
          handleMicClick={handleMicClick}
          renderAttachmentIcon={renderAttachmentIcon}
          index={1}
          recording={audioField === 'option2' && recording}
          recordingTime={recordingTime}
          stopRecording={stopRecording}
        />
        <LanguageSelect
          value={quiz.option2_language}
          handleChange={(e) => handleInputChange(index, 'option2_language', e.target.value)}
          label="Language"
          disabled={recording}
        />
        <OptionField
          quiz={quiz}
          option="option3"
          handleFieldChange={handleFieldChange}
          handleMicClick={handleMicClick}
          renderAttachmentIcon={renderAttachmentIcon}
          index={2}
          recording={audioField === 'option3' && recording}
          recordingTime={recordingTime}
          stopRecording={stopRecording}
        />
        <LanguageSelect
          value={quiz.option3_language}
          handleChange={(e) => handleInputChange(index, 'option3_language', e.target.value)}
          label="Language"
          disabled={recording}
        />
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton color="secondary" onClick={() => handleDeleteQuestion(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <input
          type="file"
          id={`file-input-${index}`}
          style={{ display: 'none' }}
          accept="image/jpeg,image/png"
          onChange={handleFileChange}
        />
      </CardContent>
    </Card>
  );
};

export default QuizCard;