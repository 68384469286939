import React, { useState } from 'react';
import { Grid, Typography, TextField, Box, IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from '@mui/icons-material/Info';

const FieldComponent = ({ quiz, field, handleFieldChange, handleMicClick, renderAttachmentIcon, recording, recordingTime, stopRecording }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={9}>
        <Typography variant="h6" component="div">
          {field.label}:
        </Typography>
        {!recording ? (
          <TextField
            fullWidth
            variant="outlined"
            value={quiz[field.name]}
            onChange={(e) => handleFieldChange(field.name, e.target.value)}
          />
        ) : (
          <Typography variant="body1" component="div">
            Recording... {recordingTime}s
          </Typography>
        )}
      </Grid>
      <Grid item xs={1}>
        <Box display="flex" justifyContent="flex-end">
          {renderAttachmentIcon(field.name, quiz[field.name], field.attachIndex)}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box display="flex" justifyContent="flex-end">
          {!recording ? (
            <IconButton color="primary" onClick={() => handleMicClick(field.name)}>
              <MicIcon />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={stopRecording}>
              <StopIcon />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title="You can either have an attachment or an audio recording for a question, answer, or an option."
            open={tooltipOpen}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          >
            <IconButton color="primary" onClick={handleTooltipOpen}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export const QuestionField = (props) => (
  <FieldComponent {...props} field={{ name: 'question', label: 'Question', attachIndex: 0 }} />
);

export const AnswerField = (props) => (
  <FieldComponent {...props} field={{ name: 'answer', label: 'Answer', attachIndex: 1 }} />
);

export const OptionField = (props) => (
  <FieldComponent {...props} field={{ name: `option${props.index + 1}`, label: `Option ${props.index + 1}`, attachIndex: props.index + 2 }} />
);