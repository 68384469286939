import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Slider from "react-slick";
import ShareIcon from "@mui/icons-material/Share";
import LinkIcon from "@mui/icons-material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import capitalizeName from "../Utils/capitalizeName";
import AllCourses from "./AllCourses.jpg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ThreeLatestCourses({ onLoad }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();

        const response = await fetch(`${baseUrl}/api/last-three-courses`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 500) {
          navigate("/Careers");
          return; // Exit the function to avoid further code execution
        }

        const data = await response.json();
        setCourses(data.products);
        onLoad(); // Call onLoad after setting the courses
      } catch (error) {
        console.log("Unable to fetch job details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, [navigate, onLoad]);

  const handleShare = async (course) => {
    const shareUrl = course
      ? `https://www.ghummantech.com/Categories/Product/${course.sku}`
      : `https://www.ghummantech.com/Categories/courses`;
    try {
      await navigator.share({
        title: course ? course.title : "Ghumman Tech Courses",
        url: shareUrl,
      });
    } catch (error) {
      if (error.name !== "AbortError") {
        console.log("Share failed:", error);
      }
    }
  };

  const handleCopyLink = (course) => {
    const shareUrl = course
      ? `https://www.ghummantech.com/Categories/Product/${course.sku}`
      : `https://www.ghummantech.com/Categories/courses`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.info("Link copied to clipboard!");
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "black",
          right: "10px",
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "black",
          left: "10px",
          zIndex: 1,
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true, // Add this line
    autoplaySpeed: 3000, // Add this line (3000ms = 3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      <div
        className="header-title"
        style={{
          color: "rgb(254, 1, 0)",
          fontWeight: "bold",
          fontFamily: "Times New Roman",
          textAlign: "center",
        }}
      >
        Featured Courses for Curious Minds
      </div>
      <div
        className="header-subtitle"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        Inspire the Next Generation—Launch Your Course Now!
      </div>

      <Slider {...settings}>
        {courses.map((course) => (
          <div key={course.id} style={{ padding: "20px" }}>
            <Card style={{ margin: "0 10px" }}>
              <CardMedia
                component="img"
                height="300"
                image={course.images[0]}
                alt={course.title}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ fontWeight: "bold", fontFamily: "Times New Roman" }}
                >
                  {course.title}
                </Typography>
                <Chip
                  label={course.price === 0 ? "FREE!" : `$${course.price}`}
                  color="primary"
                  variant="outlined"
                />
                <Chip
                  label={
                    course.included_in_membership
                      ? "Ghumman Tech"
                      : capitalizeName(course.owner_name)
                  }
                  color="secondary"
                  variant="outlined"
                />
                <IconButton
                  aria-label="share"
                  onClick={() => handleShare(course)}
                >
                  <ShareIcon />
                </IconButton>
                <IconButton
                  aria-label="copy link"
                  onClick={() => handleCopyLink(course)}
                >
                  <LinkIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(`/Categories/Product/${course.sku}`)}
                >
                  View Course
                </Button>
              </CardContent>
            </Card>
          </div>
        ))}
        <div style={{ padding: "20px" }}>
          <Card style={{ margin: "0 10px" }}>
            <CardMedia
              component="img"
              height="300"
              image={AllCourses}
              alt="Explore More Courses"
            />
            <CardContent>
              <Typography
                variant="h6"
                noWrap
                style={{ fontWeight: "bold", fontFamily: "Times New Roman" }}
              >
                Explore From Hundreds Of Courses...
              </Typography>
              <Chip
                label="By World Class Instructors"
                color="primary"
                variant="outlined"
              />
              <IconButton aria-label="share" onClick={() => handleShare(null)}>
                <ShareIcon />
              </IconButton>
              <IconButton
                aria-label="copy link"
                onClick={() => handleCopyLink(null)}
              >
                <LinkIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/Categories/courses")}
                style={{ backgroundColor: "purple", color: "white" }}
              >
                All Courses
              </Button>
            </CardContent>
          </Card>
        </div>
      </Slider>
    </Box>
  );
}

export default ThreeLatestCourses;
