import React, { useState } from 'react';
import { IconButton, Popover, FormControl, InputLabel, Select, MenuItem, Tooltip, Typography } from '@mui/material';
import { Mic } from '@mui/icons-material';

const MicrophoneSelector = ({ devices, selectedDevice, setSelectedDevice }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'microphone-popover' : undefined;

  return (
    <div style={{ textAlign: 'center' }}>
      <Tooltip title="Select Microphone">
        <IconButton onClick={handleClick} style={{ marginBottom: '5px' }}>
          <Mic style={{ color: '#1e90ff' }} />
        </IconButton>
      </Tooltip>
      <Typography variant="body2" style={{ color: '#1e90ff' }}>
        Select Mic
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <FormControl style={{ margin: '10px', minWidth: '200px' }}>
          <InputLabel id="mic-select-label">Microphone</InputLabel>
          <Select
            labelId="mic-select-label"
            value={selectedDevice}
            onChange={(e) => {
              setSelectedDevice(e.target.value);
              handleClose();
            }}
          >
            {devices.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Microphone ${device.deviceId}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Popover>
    </div>
  );
};

export default MicrophoneSelector;