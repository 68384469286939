// index.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Button, TextField, InputAdornment } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { connect } from 'react-redux';
import { updateCurrency } from '../../services/currency/actions';
import logo from '../../assets/gtLogoHeader.jpg';
import BuyMembershipButton from './BuyMembershipButton';
import GhummanRoboTrialButton from './GhummanRoboTrialButton';
import SignInPopup from './SignInPopup';
import './style.css';

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const isDesktopOrLarger = useMediaQuery('(min-width:768px)');
  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    const resultEmail = localStorage.getItem("email");
    setEmail(resultEmail);
  }, [showSignInPopup]);

  useEffect(() => {
    if (props.showSignInPopup) {
      setShowSignInPopup(true);
      props.setShowSignInPopup(false);
    }
  }, [props.showSignInPopup]);

  const goToMyAccount = () => {
    navigate("/MyAccount");
  };

  const goToSignIn = () => {
    setShowSignInPopup(true);
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  const handleSearch = () => {
    if (searchText.trim()) {
      navigate(`/Categories/courses/${searchText}`);
    }
  };

  const clearSearch = () => {
    setSearchText("");
  };

  let signinTag;
  let signinMessageLocal = "";
  try {
    if (email) {
      signinMessageLocal = email.charAt(0).toUpperCase();
      signinTag = (
        <Avatar sx={{ bgcolor: 'green', cursor: 'pointer' }} onClick={goToMyAccount}>
          {signinMessageLocal}
        </Avatar>
      );
    } else {
      signinMessageLocal = "Sign In";
      signinTag = (
        <IconButton color="inherit" onClick={goToSignIn}>
          <PersonOutlineIcon />
        </IconButton>
      );
    }
  } catch (e) {
    signinMessageLocal = "Sign In";
    signinTag = (
      <IconButton color="inherit" onClick={goToSignIn}>
        <PersonOutlineIcon />
      </IconButton>
    );
  }

  const breadcrumbs = location.pathname.split('/').slice(1);
  const formatCrumb = (crumb) => {
    if (crumb.startsWith('GT')) {
      return crumb;
    }

    return crumb
      .split(/(?=[A-Z])/) // Split on uppercase letters
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' '); // Join words with spaces
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      {showSignInPopup && <SignInPopup closePopup={closeSignInPopup} />}
      <Toolbar>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={logo} alt="GT Logo" className="no-outline" style={{ width: '70px', height: 'auto' }} />
          </Link>
          {isDesktopOrLarger && breadcrumbs.length > 0 && (
            <Box sx={{ ml: 2 }}>
              <ul style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0 }}>
                {breadcrumbs.map((crumb, index) => (
                  <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                      to={`/${breadcrumbs.slice(0, index + 1).join('/')}`}
                      style={{ textDecoration: 'underline', color: 'blue' }}
                    >
                      {formatCrumb(crumb)}
                    </Link>
                    {index < breadcrumbs.length - 1 && (
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>&rarr;</span>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
          {location.pathname === '/' ? (
            <TextField
              variant="outlined"
              placeholder="Search online classes and tutors"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: '#f1f1f1',
                  paddingRight: '8px',
                  '&.Mui-focused': {
                    backgroundColor: '#fff',
                  },
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ccc',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#aaa',
                  },
                  '& input': {
                    outline: 'none',
                    border: 'none',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && (
                      <IconButton onClick={clearSearch}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <>
              {isDesktopOrLarger ? (
                <Typography variant="h6" sx={{ color: 'rgb(254, 1, 0)', fontWeight: 'bold', fontFamily: 'Times New Roman' }}>
                  GHUMMAN TECH
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" sx={{ color: 'rgb(254, 1, 0)', fontWeight: 'bold', fontFamily: 'Times New Roman' }}>
                    GHUMMAN
                  </Typography>
                  <Typography variant="h6" sx={{ color: 'rgb(254, 1, 0)', fontWeight: 'bold', fontFamily: 'Times New Roman' }}>
                    TECH
                  </Typography>
                </>
              )}
              {isDesktopOrLarger && (
                <Typography variant="subtitle1" sx={{ color: 'rgb(254, 1, 0)', fontFamily: 'Times New Roman' }}>
                  Empowering Lifelong Learners
                </Typography>
              )}
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton color="inherit" onClick={props.openMenu}>
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" onClick={props.openCart}>
            <ShoppingCartIcon />
          </IconButton>
          {signinTag}
          {isDesktopOrLarger && (
            <>
              <Button color="inherit">
                <BuyMembershipButton />
              </Button>
              <Button color="inherit">
                <GhummanRoboTrialButton />
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  currency: state.currency.data
});

export default connect(
  mapStateToProps,
  { updateCurrency }
)(Header);