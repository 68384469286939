import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import './subscriptionDetails.css'; // Assuming the CSS file is in the same directory

const SubscriptionDetails = ({ planType, seminar }) => (
    <Grid container justifyContent="center" className="subscription-details-row">
        <Grid item xs={12} md={8}>
            <Card className="subscription-card" sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h5" component="div" className="subscription-title" gutterBottom>
                        First 7 Days on us
                    </Typography>
                    <Typography variant="body1" className="subscription-subtitle" gutterBottom>
                        Your card will not be charged for first 7 days.
                    </Typography>
                    <Typography variant="body1" className="subscription-subtitle" gutterBottom>
                        You can cancel your subscription free of charge anytime during the trial.
                    </Typography>
                    <Typography variant="h6" component="div" className="subscription-title" gutterBottom>
                        After Trial Period
                    </Typography>
                    <Typography variant="h6" component="div" className="subscription-title" gutterBottom>
                        Total Amount
                    </Typography>
                    <Box className="subscription-text">
                        {planType === 'Yearly' ? (
                            <Typography variant="body1">
                                <del>9.99 USD / month</del> <br /> {seminar.price} USD / year (8.33 USD / month)
                            </Typography>
                        ) : (
                            <Typography variant="body1">
                                {seminar.price} USD / month
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default SubscriptionDetails;