import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Box, FormControl, InputLabel, Select, MenuItem, useMediaQuery } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';

const QuizNavigation = ({
    userAnswers,
    setCurrentQuestion,
    currentQuestion,
    quizData,
    handlePreviousQuestion,
    handleNextQuestion,
    handleSubmitQuiz,
    handleBookmark,
    disableBookmark,
    quizType,
    setQuizType
}) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <div style={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px'
        }}>
            <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="3x"
                style={{ padding: '10px' }}
                color={currentQuestion === 0 ? 'gray' : 'green'}
                onClick={currentQuestion === 0 ? null : handlePreviousQuestion}
            />

            <DropdownButton
                id="dropdown-basic-button"
                title={`Question ${currentQuestion + 1} / ${quizData.length}`}
                style={{ margin: '10px' }}
                className="dropdown-scrollable"
            >
                {quizData.map((question, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => setCurrentQuestion(index)}
                        style={{ color: userAnswers[index] === null ? 'red' : 'green' }}
                    >
                        Question {index + 1}
                    </Dropdown.Item>
                ))}
            </DropdownButton>

            {currentQuestion === quizData.length - 1 ? (
                <button
                    style={{
                        borderRadius: '10px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '10px 20px',
                        margin: '10px'
                    }}
                    onClick={handleSubmitQuiz}
                >
                    Submit Quiz
                </button>
            ) : (
                <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    size="3x"
                    style={{ padding: '10px' }}
                    color='green'
                    onClick={handleNextQuestion}
                />
            )}

            <button
                onClick={handleBookmark}
                style={{
                    fontSize: '20px',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    padding: '10px',
                    backgroundColor: '#000080',
                    border: '1px solid black',
                    color: quizData[currentQuestion].bookmarked === 1 ? 'yellow' : 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px'
                }}
                disabled={disableBookmark}
            >
                {quizData[currentQuestion].bookmarked === 1 ? '★' : '☆'}
            </button>

            <FormControl sx={{ m: 1, minWidth: 120 }} style={{ marginTop: isSmallScreen ? '10px' : '0' }}>
                <InputLabel id="quiz-type-label">Quiz Type</InputLabel>
                <Select
                    labelId="quiz-type-label"
                    id="quiz-type-select"
                    value={quizType}
                    label="Quiz Type"
                    onChange={(e) => setQuizType(e.target.value)}
                >
                    <MenuItem value="Random">Random</MenuItem>
                    <MenuItem value="MCQs">MCQs</MenuItem>
                    <MenuItem value="Match">Match</MenuItem>
                    <MenuItem value="Drag/Drop">Drag/Drop</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default QuizNavigation;