import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import Review from '../Review'; // Adjust the import path as necessary

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
    },
    button: {
        borderRadius: '10px',
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '10px',
    },
    heading: {
        marginTop: '20px',
    },
});

const QuizResult = ({ score, quizData, userAnswers, handleRestartQuiz, handleUploadScore, isBookmarkedTab, isLoading }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRestartQuiz}
                    className={classes.button}
                >
                    Restart Quiz
                </Button>
                {!isBookmarkedTab && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUploadScore}
                        disabled={isLoading}
                        className={classes.button}
                    >
                        {isLoading ? "Uploading..." : "Upload Score"}
                    </Button>
                )}
            </Box>
            <Typography variant="h4" className={classes.heading}>
                You scored {score} out of {quizData.length} questions!
            </Typography>
            <Review quizData={quizData} userAnswers={userAnswers} />
        </Box>
    );
};

export default QuizResult;