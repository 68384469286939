import React from 'react';
import Box from '@mui/material/Box';
import { FaYoutube, FaFacebook, FaInstagram, FaPinterest, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { youtubeLink, facebookLink, instagramLink, twitterLink, pintrestLink } from './../Utils/constants';

const Footer = () => { 
  const navigate = useNavigate();

  const privacyOpen = () => {
    navigate('/Privacy');
  };

  const userAgreementOpen = () => {
    navigate('/UserAgreement');
  };

  const exchangeOpen = () => {
    navigate('/Exchange');
  };

  const sizeChartOpen = () => {
    navigate('/SizeChart');
  };

  const contactOpen = () => {
    navigate('/ContactUs');
  };

  const aboutOpen = () => {
    navigate('/AboutUs');
  };

  return (
    <footer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        p={2}
        sx={{ background: 'linear-gradient(135deg, #fe0000 30%, #ffffff 90%)', color: '#fff' }}
      >
        <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
          <Box 
            sx={{ 
              fontFamily: "Georgia", 
              cursor: "pointer", 
              marginBottom: '10px', 
              transition: 'transform 0.3s, color 0.3s', 
              color: '#fff',
              '&:hover': { color: '#000', transform: 'scale(1.1)' } 
            }} 
            onClick={exchangeOpen}
          >
            Exchanges & Refunds
          </Box>
          <Box 
            sx={{ 
              fontFamily: "Georgia", 
              cursor: "pointer", 
              marginBottom: '10px', 
              transition: 'transform 0.3s, color 0.3s', 
              color: '#fff',
              '&:hover': { color: '#000', transform: 'scale(1.1)' } 
            }} 
            onClick={privacyOpen}
          >
            Privacy Policy
          </Box>
          <Box 
            sx={{ 
              fontFamily: "Georgia", 
              cursor: "pointer", 
              marginBottom: '10px', 
              transition: 'transform 0.3s, color 0.3s', 
              color: '#fff',
              '&:hover': { color: '#000', transform: 'scale(1.1)' } 
            }} 
            onClick={userAgreementOpen}
          >
            User Agreement
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-start" mb={2}>
          <Box component="a" href={youtubeLink} target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaYoutube style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
          <Box component="a" href={facebookLink} target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaFacebook style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
          <Box component="a" href={instagramLink} target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaInstagram style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
          <Box component="a" href={twitterLink} target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaSquareXTwitter style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
          <Box component="a" href={pintrestLink} target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaPinterest style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
          <Box component="a" href="https://www.linkedin.com/company/ghummantech" target="_blank" rel="noopener noreferrer" sx={{ transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.5)' } }}>
            <FaLinkedin style={{ color: '#fff', margin: '10px', fontSize: '32px' }} />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box 
            sx={{ 
              fontFamily: "Georgia", 
              cursor: "pointer", 
              marginBottom: '10px', 
              transition: 'transform 0.3s, color 0.3s', 
              color: '#fff',
              '&:hover': { color: '#000', transform: 'scale(1.1)' } 
            }} 
            onClick={contactOpen}
          >
            Contact us
          </Box>
          <Box 
            sx={{ 
              fontFamily: "Georgia", 
              cursor: "pointer", 
              marginBottom: '10px', 
              transition: 'transform 0.3s, color 0.3s', 
              color: '#fff',
              '&:hover': { color: '#000', transform: 'scale(1.1)' } 
            }} 
            onClick={aboutOpen}
          >
            About us
          </Box>
        </Box>

        <Box sx={{ fontFamily: "Georgia", fontSize: '1rem', color: '#fff', mt: 2 }}>
          &copy; {new Date().getFullYear()} Ghumman Tech 
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;