import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Button,
} from "@mui/material";
import ChapterSelection from "./ChapterSelection";
import { getBaseUrl } from "../Utils/getBaseUrl";
import "./style.css";
import { toast } from 'react-toastify';

const SwapChapters = ({ courseData, appFileData }) => {
  const navigate = useNavigate();
  const [selectedChapter1, setSelectedChapter1] = useState(null);
  const [selectedChapter2, setSelectedChapter2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("Select two different chapters to swap");

  const handleChapterClick = (chapter) => {
    if (!selectedChapter1) {
      setSelectedChapter1(chapter);
      setMessage(`Chapter Number ${chapter} selected as first chapter. Now select another chapter to swap with.`);
    } else if (!selectedChapter2 && chapter !== selectedChapter1) {
      setSelectedChapter2(chapter);
      setMessage(`Chapter Number ${chapter} selected as second chapter. Ready to swap.`);
    } else if (chapter === selectedChapter1) {
      toast.error('You cannot select the same chapter for both positions.');
    } else {
      setSelectedChapter1(chapter);
      setSelectedChapter2(null);
      setMessage(`Chapter Number ${chapter} selected as first chapter. Now select another chapter to swap with.`);
    }
  };

  const handleSwapChapters = async () => {
    if (!selectedChapter1 || !selectedChapter2) {
      toast.error('You must select two different chapters to swap.');
      return;
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const dataRequest = new FormData();
      dataRequest.append("user_email", localStorage.getItem("email"));
      dataRequest.append("course_id", courseData?.id);
      dataRequest.append("course_url", courseData?.app_data_url);
      dataRequest.append("chapter_number1", selectedChapter1);
      dataRequest.append("chapter_number2", selectedChapter2);
  
      const swapChaptersResponse = await fetch(
        `${baseUrl}/api/swap-chapters`,
        {
          method: "POST",
          body: dataRequest,
        }
      );
  
      if (swapChaptersResponse.ok) {
        toast.success('Chapters swapped successfully.');
        navigate(`/Courses/${courseData?.id}`);
      } else {
        toast.error('Failed to swap chapters.');
      }
    } catch (error) {
      console.error("Error swapping the chapters:", error);
      toast.error('Error swapping the chapters.');
    } finally {
      setIsLoading(false);
    }
  };

  const totalChapters =
  courseData && (courseData.type === "programming" || courseData.type === "language")
      ? courseData?.number_of_chapters
      : appFileData?.totalChapters;

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Swap Chapters</h3>
      <p>Please select two different chapters to swap their positions in the course.</p>

      <ChapterSelection
        totalChapters={totalChapters}
        selectedChapter={selectedChapter1}
        handleChapterClick={handleChapterClick}
      />
      <ChapterSelection
        totalChapters={totalChapters}
        selectedChapter={selectedChapter2}
        handleChapterClick={handleChapterClick}
      />
      <p className="fancy-message">{message}</p>
      {isLoading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        <div className="quiz-content">
          <div className="update-button">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSwapChapters}
              disabled={isLoading}
            >
              Swap
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(`/Courses/${courseData?.id}`)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SwapChapters;