import React, { useState, useEffect } from 'react';
import { Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, Avatar, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Confetti from 'react-confetti';
import './Attendance.css'; // Import the CSS file for animations
import AddStudent from './AddStudent';
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';
import StudentNotes from './StudentNotes';
import StudentOfTheLesson from './StudentOfTheLesson';

const avatars = [
  'https://e7.pngegg.com/pngimages/774/336/png-clipart-sonic-heroes-sonic-lost-world-knuckles-the-echidna-sonic-unleashed-knuckles-chaotix-sonic-the-hedgehog-sonic-the-hedgehog-rock-thumbnail.png', 'https://sonicworldfangame.com/wp-content/uploads/2021/06/sonic_render_1.png?w=309&h=309', 'https://e7.pngegg.com/pngimages/566/864/png-clipart-doctor-eggman-dr-wily-mario-sonic-at-the-olympic-games-sonic-the-hedgehog-tails-doctor-figure-sonic-the-hedgehog-video-game-thumbnail.png',
  'https://i.pinimg.com/originals/9d/97/0e/9d970e393a5d7fb2727fe3e28ec0b7d5.png', 'https://i.pinimg.com/736x/7f/7a/5a/7f7a5a2cbd86f2e9f30bcf08481ecb31.jpg', 'https://i.pinimg.com/originals/1e/17/37/1e1737a24e1a65639accc01a37990f8d.png',
  'https://i.pinimg.com/736x/96/68/75/966875e773b6ae232211e60d47d50752.jpg', 'https://i.pinimg.com/originals/88/23/64/8823641c68da4d9ab64e4a8b70f4a158.png', 'https://i.pinimg.com/originals/9e/74/95/9e7495d3c96ce4ca6fa4046c764870b5.png'
];

const Attendance = () => {
  const [students, setStudents] = useState([]);
  const [newStudent, setNewStudent] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [stars, setStars] = useState({});
  const [notes, setNotes] = useState({});
  const [timeouts, setTimeouts] = useState({});
  const [studentOfTheLesson, setStudentOfTheLesson] = useState("");
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showRewardAnimation, setShowRewardAnimation] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const addStudent = () => {
    if (newStudent.trim() !== "") {
      setStudents([...students, { name: newStudent.trim(), avatar: selectedAvatar }]);
      setNewStudent("");
      setSelectedAvatar("");
    }
  };

  const removeStudent = (studentName) => {
    setStudents(students.filter(student => student.name !== studentName));
    const { [studentName]: _, ...newStars } = stars;
    const { [studentName]: __, ...newNotes } = notes;
    const { [studentName]: ___, ...newTimeouts } = timeouts;
    setStars(newStars);
    setNotes(newNotes);
    setTimeouts(newTimeouts);
    if (selectedStudent && selectedStudent.name === studentName) {
      setSelectedStudent(null);
    }
  };

  const incrementStars = (student) => {
    setStars({
      ...stars,
      [student.name]: Math.min((stars[student.name] || 0) + 1, 5),
    });
    setShowRewardAnimation(true);
    setTimeout(() => setShowRewardAnimation(false), 1000);
  };

  const decrementStars = (student) => {
    setStars({
      ...stars,
      [student.name]: Math.max((stars[student.name] || 0) - 1, 0),
    });
  };

  const addNote = (student, note) => {
    setNotes({
      ...notes,
      [student.name]: note,
    });
  };

  const setTimeoutForStudent = (student) => {
    setTimeouts({
      ...timeouts,
      [student.name]: true,
    });
    setTimeout(() => {
      setTimeouts((prev) => ({ ...prev, [student.name]: false }));
    }, 60000); // 1 minute
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    setAvatarDialogOpen(false);
  };

  const handleStudentOfTheLessonChange = (e) => {
    setStudentOfTheLesson(e.target.value);
    if (e.target.value && e.target.value !== "all") {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  return (
    <Container maxWidth="md" style={{ padding: '20px', fontFamily: 'Times New Roman' }}>
      {showConfetti && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 9999 }}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'Times New Roman' }}>Student's Attendance</Typography>

      <AddStudent
        newStudent={newStudent}
        setNewStudent={setNewStudent}
        selectedAvatar={selectedAvatar}
        setAvatarDialogOpen={setAvatarDialogOpen}
        addStudent={addStudent}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StudentList
            students={students}
            selectedStudent={selectedStudent}
            handleStudentClick={handleStudentClick}
            stars={stars}
            timeouts={timeouts}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {selectedStudent && (
            <>
              <StudentDetails
                selectedStudent={selectedStudent}
                stars={stars}
                incrementStars={incrementStars}
                decrementStars={decrementStars}
                setTimeoutForStudent={setTimeoutForStudent}
                removeStudent={removeStudent}
                showRewardAnimation={showRewardAnimation}
              />
              <StudentNotes
                selectedStudent={selectedStudent}
                notes={notes}
                addNote={addNote}
              />
            </>
          )}
        </Grid>
      </Grid>

      <StudentOfTheLesson
        students={students}
        studentOfTheLesson={studentOfTheLesson}
        handleStudentOfTheLessonChange={handleStudentOfTheLessonChange}
        selectedStudent={selectedStudent}
      />

      <Dialog open={avatarDialogOpen} onClose={() => setAvatarDialogOpen(false)}>
        <DialogTitle>
          Select an Avatar
          <IconButton
            aria-label="close"
            onClick={() => setAvatarDialogOpen(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {avatars.map((avatar) => (
              <Grid item xs={4} key={avatar}>
                <Avatar
                  src={avatar}
                  alt={avatar}
                  style={{ cursor: 'pointer', width: '100px', height: '100px' }}
                  onClick={() => handleAvatarSelect(avatar)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAvatarDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Attendance;