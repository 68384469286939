import React, { useState, useEffect } from "react";
import QuizHeader from "./QuizHeader";
import QuizBody from "./QuizBody";
import QuizFooter from "./QuizFooter";
import Spinner from "../../Spinner";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import { shuffleOptions, handleSpeak } from "./QuizUtils";
import { replaceWithMedia } from "../../Utils/replaceWithMedia";
import { useMediaQuery } from "react-responsive";
import { Box, IconButton } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Quiz = ({ quizData, chapterContent, isBookmarkedTab, isDemoCourse }) => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const course_language = chapterContent.course_language || "en";

  if (quizData.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        No bookmarks present.
      </Box>
    );
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [userAnswers, setUserAnswers] = useState(Array(quizData.length).fill(null));
  const [localQuestions, setLocalQuestions] = useState(quizData);
  const [disableBookmark, setDisableBookmark] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [quizType, setQuizType] = useState("Random");
  const email = localStorage.getItem("email");

  const currentCard = quizData[currentQuestion];
  const question = replaceWithMedia(currentCard.question, currentCard.attachments, currentCard.audio_attachments, isDesktopOrLarger);
  const answer = replaceWithMedia(currentCard.answer, currentCard.attachments, currentCard.audio_attachments, isDesktopOrLarger);
  const option1 = replaceWithMedia(currentCard.option1, currentCard.attachments, currentCard.audio_attachments, isDesktopOrLarger);
  const option2 = replaceWithMedia(currentCard.option2, currentCard.attachments, currentCard.audio_attachments, isDesktopOrLarger);
  const option3 = replaceWithMedia(currentCard.option3, currentCard.attachments, currentCard.audio_attachments, isDesktopOrLarger);

  useEffect(() => {
    if (quizData && Array.isArray(quizData) && quizData.length > 0) {
      const shuffled = shuffleOptions([
        { text: option1, language: quizData[currentQuestion]?.option1_language, origText: currentCard.option1 },
        { text: option2, language: quizData[currentQuestion]?.option2_language, origText: currentCard.option2 },
        { text: option3, language: quizData[currentQuestion]?.option3_language, origText: currentCard.option3 },
        { text: answer, language: quizData[currentQuestion]?.answer_language, origText: currentCard.answer },
      ]);
      setShuffledOptions(shuffled);
    }
    setLocalQuestions(quizData);
  }, [quizData, currentQuestion, chapterContent]);

  useEffect(() => {
    setSelectedOption(userAnswers[currentQuestion]);
  }, [currentQuestion]);

  const handleOptionChange = (op) => {
    setSelectedOption(op.text);
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestion] = op.origText;
    setUserAnswers(updatedAnswers);
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const handlePreviousQuestion = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const handleSubmitQuiz = () => {
    if (userAnswers.includes(null)) {
      alert("Please answer all the questions before submitting the quiz.");
    } else {
      setIsSubmitted(true);
      setIsMaximized(false); // Minimize the screen when the quiz is submitted
      const correctAnswers = userAnswers.filter(
        (answer, index) => answer === quizData[index].answer
      );
      setScore(correctAnswers.length);
    }
  };

  const handleRestartQuiz = () => {
    setCurrentQuestion(0);
    setSelectedOption(null);
    setIsSubmitted(false);
    setScore(0);
    setUserAnswers(Array(quizData.length).fill(null));
  };

  const handleUploadScore = async () => {
    if (isDemoCourse) {
      toast.info("Score uploading is not available for demo courses.");
      return;
    }
    
    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();
      const scorePercentage = (score / quizData.length) * 100;
      const dataRequest = {
        course_id: chapterContent.course_id,
        chapter_id: chapterContent.chapter_number,
        user_email: email,
        score: scorePercentage,
      };

      const response = await fetch(`${baseUrl}/api/upload-score`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error("Error uploading score:", responseData);
      } else {
        alert("Score uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading score:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookmark = async () => {
    if (isDemoCourse) {
      toast.info("Bookmarking is not available for demo courses.");
      return;
    }
    setDisableBookmark(true);
    const newLocalQuestions = [...localQuestions];
    newLocalQuestions[currentQuestion].bookmarked =
      newLocalQuestions[currentQuestion].bookmarked === 0 ? 1 : 0;
    setLocalQuestions(newLocalQuestions);

    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        quiz_question_id: quizData[currentQuestion].id,
        user_email: email,
      };
      const response = await fetch(`${baseUrl}/api/bookmark-question`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error("Error setting up bookmark:", responseData);
      }
    } catch (error) {
      console.error("Error setting up bookmark:", error);
    }

    setDisableBookmark(false);
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const quizContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: isMaximized ? "fixed" : "relative",
    top: isMaximized ? 0 : "auto",
    left: isMaximized ? 0 : "auto",
    width: isMaximized ? "100vw" : "auto",
    height: isMaximized ? "100vh" : "auto",
    backgroundColor: isMaximized ? "white" : "transparent",
    zIndex: isMaximized ? 1000 : "auto",
    overflowY: isMaximized ? "auto" : "visible",
    padding: isMaximized ? "10px" : "0",
  };

  return (
    <Box sx={quizContainerStyle}>
      {isLoading && <Spinner />}
      {!isSubmitted && (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: 1 }}>
          <Box sx={{ position: "fixed", top: 10, right: 10, zIndex: 1100 }}>
            <IconButton onClick={toggleMaximize}>
              {isMaximized ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        </Box>
      )}
      <Box sx={{ width: "100%", flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <QuizHeader
          userAnswers={userAnswers}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestion={currentQuestion}
          quizData={quizData}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          handleSubmitQuiz={handleSubmitQuiz}
          handleBookmark={handleBookmark}
          disableBookmark={disableBookmark}
          quizType={quizType}
          setQuizType={setQuizType}
        />
        <QuizBody
          isSubmitted={isSubmitted}
          question={question}
          quizData={quizData}
          currentQuestion={currentQuestion}
          shuffledOptions={shuffledOptions}
          selectedOption={selectedOption}
          handleSpeak={handleSpeak}
          handleOptionChange={handleOptionChange}
          isDesktopOrLarger={isDesktopOrLarger}
          score={score}
          course_language={course_language}
          userAnswers={userAnswers}
          handleRestartQuiz={handleRestartQuiz}
          handleUploadScore={handleUploadScore}
          isBookmarkedTab={isBookmarkedTab}
          isLoading={isLoading}
          quizType={quizType}
        />
        <QuizFooter
          isSubmitted={isSubmitted}
          score={score}
          quizData={quizData}
          userAnswers={userAnswers}
          handleRestartQuiz={handleRestartQuiz}
          handleUploadScore={handleUploadScore}
          isBookmarkedTab={isBookmarkedTab}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Quiz;