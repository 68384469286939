import React from 'react';
import { Grid, Paper, Typography, Avatar, Alert, Card, CardContent } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';

const StudentList = ({ students, selectedStudent, handleStudentClick, stars, timeouts }) => {
  const isMobile = window.innerWidth <= 600;

  const renderStars = (count) => {
    const starElements = [];
    for (let i = 0; i < 5; i++) {
      starElements.push(
        i < count ? (
          <Star key={i} style={{ color: 'gold', fontSize: isMobile ? '1rem' : '1.5rem' }} />
        ) : (
          <StarBorder key={i} style={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
        )
      );
    }
    return starElements;
  };

  return (
    <Card style={{ height: '400px', overflowY: 'auto', backgroundColor: '#e0f7fa' }}>
      <CardContent>
        <Typography variant="h5" gutterBottom style={{ fontFamily: 'Times New Roman' }}>Student List</Typography>
        <Grid container spacing={2}>
          {students.map((student, index) => (
            <Grid item xs={4} key={index}>
              <Paper
                style={{
                  padding: isMobile ? '20px' : '10px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: selectedStudent && selectedStudent.name === student.name ? '#f0f0f0' : 'white',
                  border: selectedStudent && selectedStudent.name === student.name ? '2px solid lightgreen' : 'none'
                }}
                onClick={() => handleStudentClick(student)}
              >
                <Avatar src={student.avatar} alt={student.name} style={{ margin: '0 auto' }} />
                <Typography variant="body1" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'Times New Roman' }}>
                  <strong>{student.name}</strong>
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                  {renderStars(stars[student.name] || 0)}
                </div>
                {timeouts[student.name] && <Alert severity="error" style={{ marginTop: '10px' }}>Timeout</Alert>}
              </Paper>
            </Grid>
          ))}
          {Array.from({ length: Math.max(0, 9 - students.length) }).map((_, index) => (
            <Grid item xs={4} key={`empty-${index}`}>
              <Paper style={{ padding: '10px', textAlign: 'center', visibility: 'hidden' }}>
                <Avatar style={{ margin: '0 auto' }} />
                <Typography variant="body1"><strong>Empty</strong></Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentList;