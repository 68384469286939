import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  Box,
  Grid,
  Typography,
  Chip,
  Card,
  CardContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const repeatTypes = ["none", "daily", "weekly", "custom"];
const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const TeacherCalendar = ({ setStep4Complete }) => {
  const [availability, setAvailability] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [newBookingRequest, setNewBookingRequest] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [repeatType, setRepeatType] = useState("daily");
  const [repeatDays, setRepeatDays] = useState([]);
  const userEmail = localStorage.getItem("email") || "";

  useEffect(() => {
    const fetchTeacherAvailability = async () => {
      try {
        const baseUrl = getBaseUrl();
        const dataRequest = { email: userEmail };

        const response = await fetch(
          `${baseUrl}/api/get-teacher-availability`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataRequest),
          }
        );

        if (response.status === 500) {
          console.log("Server error");
          return;
        }

        const data = await response.json();
        if (data.success) {
          const availabilitySlots = data.availability.map((slot) => ({
            ...slot,
            start: new Date(slot.start_time),
            end: new Date(slot.end_time),
            title: "Available",
          }));

          const bookingSlots = data.bookings.map((booking) => ({
            ...booking,
            start: new Date(booking.start_time),
            end: new Date(booking.end_time),
            title: "Booked",
          }));

          setAvailability(availabilitySlots);
          setBookings(bookingSlots);

          const newBookingSlots = availabilitySlots.filter(
            (slot) =>
              !bookingSlots.some(
                (booking) =>
                  booking.start.getTime() === slot.start.getTime() &&
                  booking.end.getTime() === slot.end.getTime()
              )
          );

          setNewBookingRequest(newBookingSlots);
        } else {
          console.log("Failed to fetch availability and bookings");
        }
      } catch (error) {
        console.log("Error fetching availability and bookings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeacherAvailability();
  }, [userEmail]);

  const handleUpdate = async () => {
    try {
      const baseUrl = getBaseUrl();
      const validSlots = [...newBookingRequest].filter(
        (slot) => slot.start && slot.end
      );
      const availabilities = validSlots.map((slot) => ({
        start_time: slot.start.toISOString(),
        end_time: slot.end.toISOString(),
        repeat_type: slot.repeatType || "none",
        repeat_days: slot.repeatDays || [],
      }));

      const dataRequest = { email: userEmail, availabilities };
      const response = await fetch(
        `${baseUrl}/api/update-teacher-availability`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        }
      );

      if (response.ok) {
        toast.success("Availability updated successfully");
        setIsChanged(false);
        setStep4Complete(true);
      } else {
        toast.error("Failed to update availability");
      }
    } catch (error) {
      toast.error("Failed to update availability");
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 0; i < 24 * 2; i++) {
      const hour = Math.floor(i / 2);
      const minutes = i % 2 === 0 ? "00" : "30";
      slots.push(`${hour.toString().padStart(2, "0")}:${minutes}`);
    }
    return slots;
  };

  const filterSlotsByDate = (slots, date) => {
    return slots.filter((slot) => {
      const slotDate = new Date(slot.start);
      return slotDate.toDateString() === date.toDateString();
    });
  };

  const isBooked = (timeSlot) => {
    const selected = new Date(selectedDate);
    selected.setHours(timeSlot.split(":")[0], timeSlot.split(":")[1]);

    return filterSlotsByDate(bookings, selectedDate).some((booking) => {
      const start = new Date(booking.start_time);
      const end = new Date(booking.end_time);
      return selected >= start && selected < end;
    });
  };

  const isAvailable = (timeSlot) => {
    const selected = new Date(selectedDate);
    selected.setHours(timeSlot.split(":")[0], timeSlot.split(":")[1]);

    return filterSlotsByDate(availability, selectedDate).some((slot) => {
      const start = new Date(slot.start_time);
      const end = new Date(slot.end_time);
      return selected >= start && selected < end;
    });
  };

  const isSelected = (timeSlot) => {
    const selected = new Date(selectedDate);
    selected.setHours(timeSlot.split(":")[0], timeSlot.split(":")[1]);

    return newBookingRequest.some((booking) => {
      return (
        booking.start.getDate() === selected.getDate() &&
        booking.start.getMonth() === selected.getMonth() &&
        booking.start.getFullYear() === selected.getFullYear() &&
        booking.start.getHours() === selected.getHours() &&
        booking.start.getMinutes() === selected.getMinutes()
      );
    });
  };

  const handleBooking = (timeSlot) => {
    const selected = new Date(selectedDate);
    selected.setHours(timeSlot.split(":")[0], timeSlot.split(":")[1]);

    if (isSelected(timeSlot)) {
      setNewBookingRequest(
        newBookingRequest.filter(
          (booking) =>
            booking.start.getDate() !== selected.getDate() ||
            booking.start.getMonth() !== selected.getMonth() ||
            booking.start.getFullYear() !== selected.getFullYear() ||
            booking.start.getHours() !== selected.getHours() ||
            booking.start.getMinutes() !== selected.getMinutes()
        )
      );
    } else {
      setNewBookingRequest([
        ...newBookingRequest,
        {
          start: selected,
          end: new Date(selected.getTime() + 30 * 60000),
          repeatType,
          repeatDays,
        },
      ]);
    }
    setIsChanged(true);
  };

  const handleRemoveBooking = (index) => {
    const updatedBookingRequest = [...newBookingRequest];
    updatedBookingRequest.splice(index, 1);
    setNewBookingRequest(updatedBookingRequest);
    setIsChanged(true);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ fontFamily: "Times New Roman" }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Times New Roman",
          color: "black",
        }}
      >
        Put Availability For Your Students To Book 1-on-1 Sessions
      </Typography>
      <Box display="flex" justifyContent="center" width="100%">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Calendar onChange={handleDateChange} value={selectedDate} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4} width="100%">
        <Typography
          variant="h6"
          align="center"
          sx={{ fontFamily: "Times New Roman" }}
        >
          Available Time Slots
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {generateTimeSlots().map((timeSlot) => (
            <Grid item key={timeSlot}>
              <Chip
                label={timeSlot}
                color={
                  isBooked(timeSlot)
                    ? "error"
                    : isSelected(timeSlot)
                    ? "secondary"
                    : isAvailable(timeSlot)
                    ? "primary"
                    : "default"
                }
                onClick={() => !isBooked(timeSlot) && handleBooking(timeSlot)}
                disabled={isBooked(timeSlot)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4} width="100%">
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              align="center"
              sx={{ fontFamily: "Times New Roman" }}
            >
              Selected Time Slots
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {newBookingRequest.map((booking, index) => (
                <Grid item key={index}>
                  <Chip
                    label={`${booking.start.toLocaleDateString()} ${booking.start
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${booking.start
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}`}
                    color="secondary"
                    onDelete={() => handleRemoveBooking(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4} width="100%">
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontFamily: "Times New Roman", marginRight: 1 }}
          >
            Repeat Type
          </Typography>
          <Tooltip title="Select the Repeat Type before choosing a time slot. The selected Repeat Type will apply to the time slot you choose.">
            <IconButton color="primary">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <FormControl fullWidth>
          <InputLabel>Repeat Type</InputLabel>
          <Select
            value={repeatType}
            onChange={(e) => setRepeatType(e.target.value)}
          >
            {repeatTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {repeatType === "custom" && (
          <FormControl fullWidth>
            <InputLabel>Repeat Days</InputLabel>
            <Select
              multiple
              value={repeatDays}
              onChange={(e) => setRepeatDays(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
            >
              {daysOfWeek.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={repeatDays.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        disabled={isLoading || !isChanged}
        sx={{ marginTop: 2 }}
      >
        Update
      </Button>
    </Box>
  );
};

export default TeacherCalendar;
